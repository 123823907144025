import { SPARE_AXLE } from "../../../global";
import { formatDateTime, formatDate, formatTime, removeSelectOptions } from "../../../utilities";
import { getSensorSummaryData, getSensorHistoryData, getUnitSensorHistoryData } from "../../../data/sensordata";

import { stopSensorUpdates, getSensorUpdates } from "../../detailpage";
import { getUnits, getTyreArrayIndex } from "../units";

import { createDiagramView, createSignalStrengthBar } from "./unitdiagrams";
import { exportSensorValues } from "../../excelexports/excelexport";
import { openSensorValuesChartReport } from '../../../reports';

let gTimeUpdatedCheck;
let gSensorIdCheck;
let gTyreIdCheck;
let gTreadDepthCheck;
let gPressureCheck;
let gTemperatureCheck;
let gVoltageCheck;
let gSignalStrengthCheck;
let gRotationCheck;

export function createSensorValuesDiagramButtonsElement(unit) {

    let diagramButtonDivClasses = ['row', 'pane2buttons'];
    let diagramButtonDiv = document.createElement('div');
    diagramButtonDiv.classList.add(...diagramButtonDivClasses);
    let diagramButtonClasses = ['btn', 'btn-primary', 'panebutton', 'flex-child'];

    let diagramPrintButton = document.createElement('button');
    diagramPrintButton.classList.add(...diagramButtonClasses);
    diagramPrintButton.type = 'button';
    diagramPrintButton.title = 'PRINT SENSOR VALUES DIAGRAM (EXCEL EXPORT)';

    //let printImg = document.createElement('img');
    //printImg.src = "images/whiteprinter-24.png";
    ////printImg.src = "/src/components/unitDiagrams/images/whiteprinter-24.png";
    //diagramPrintButton.appendChild(printImg);

    const printIcon = document.createElement('svg');
    printIcon.className = 'buttonicon printbluesvg'; //class="button imgbutton buttontyre-disabled"

    const printButtonText = document.createElement('span');
    printButtonText.innerText = 'Print Diagram';

    diagramPrintButton.appendChild(printIcon);
    diagramPrintButton.appendChild(printButtonText);

    diagramPrintButton.onclick = function () {
        let sensorValuesDiagramOptions = {};
        gTyreIdCheck == null ? sensorValuesDiagramOptions.tyreIdCheck = false : sensorValuesDiagramOptions.tyreIdCheck = gTyreIdCheck;
        gTreadDepthCheck == null ? sensorValuesDiagramOptions.treadDepthCheck = false : sensorValuesDiagramOptions.treadDepthCheck = gTreadDepthCheck;
        //gSensorIdCheck == null ? sensorValuesDiagramOptions.sensorIdCheck = false : sensorValuesDiagramOptions.sensorIdCheck = gSensorIdCheck;
        gPressureCheck == null ? sensorValuesDiagramOptions.pressureCheck = true : sensorValuesDiagramOptions.pressureCheck = gPressureCheck;
        gTemperatureCheck == null ? sensorValuesDiagramOptions.temperatureCheck = false : sensorValuesDiagramOptions.temperatureCheck = gTemperatureCheck;
        gVoltageCheck == null ? sensorValuesDiagramOptions.voltageCheck = false : sensorValuesDiagramOptions.voltageCheck = gVoltageCheck;

        exportSensorValues(unit, sensorValuesDiagramOptions);
    };

    diagramButtonDiv.appendChild(diagramPrintButton);

    let sensorValuesDiagramSelectDateButton = document.createElement('button');
    sensorValuesDiagramSelectDateButton.classList.add(...diagramButtonClasses);
    sensorValuesDiagramSelectDateButton.type = 'button';
    sensorValuesDiagramSelectDateButton.title = 'SELECT HISTORY DATE';

    const calendarIcon = document.createElement('svg');
    calendarIcon.className = 'buttonicon calendarbluesvg'; //class="button imgbutton buttontyre-disabled"

    const selectDateButtonText = document.createElement('span');
    selectDateButtonText.innerText = 'History';

    sensorValuesDiagramSelectDateButton.appendChild(calendarIcon);
    sensorValuesDiagramSelectDateButton.appendChild(selectDateButtonText);

    sensorValuesDiagramSelectDateButton.onclick = function (e) {
        e.stopImmediatePropagation();
        openSelectSensorDiagramDateForm(unit);
    };

    diagramButtonDiv.appendChild(sensorValuesDiagramSelectDateButton);

    let sensorValuesDiagramReportButton = document.createElement('button');
    sensorValuesDiagramReportButton.classList.add(...diagramButtonClasses);
    sensorValuesDiagramReportButton.type = 'button';
    sensorValuesDiagramReportButton.title = 'VIEW CHART REPORTS';

    const chartIcon = document.createElement('svg');
    chartIcon.className = 'buttonicon chartbluesvg'; //class="button imgbutton buttontyre-disabled"

    const reportButtonText = document.createElement('span');
    reportButtonText.innerText = 'Reports';

    sensorValuesDiagramReportButton.appendChild(chartIcon);
    sensorValuesDiagramReportButton.appendChild(reportButtonText);

    sensorValuesDiagramReportButton.onclick = async function (e) {
        e.stopImmediatePropagation();
        //document.body.style.cursor = 'wait';

        document.body.style.cursor = 'none';
        document.getElementById("loadingBoxText").innerText = "Collecting Report Data... ";
        $("#loadingBox").modal({
            backdrop: "static", //remove ability to close modal with click
            keyboard: false, //remove option to close with keyboard
            show: true //Display loader!
        });

        //let sensorValuesData = [];
        //sensorValuesData = await loadSensorValuesChartMessages(unit);
        const date = new Date();
        date.setDate(date.getDate() - 30); //week ago - subtract 7 days from the current date

        createDiagramView(unit, 'REPORTSDIAGRAM', 'chartunit');
        const sensorSummaryData = await getSensorSummaryData(unit, formatDate(date));

        console.log('function openSensorValuesChartReport [' + Date.now() + '] ');
        $("#loadingBox").modal("hide");
        openSensorValuesChartReport(unit, sensorSummaryData);
        return false;
    };

    diagramButtonDiv.appendChild(sensorValuesDiagramReportButton);

    return diagramButtonDiv;
}

export function createSensorValuesDiagramOptionsElement(unit) {

    let diagramOptionsDivClasses = ['form-check', 'form-check-inline'];
    let diagramOptionsInputClasses = ['form-check-input', 'custom-checkbox'];
    let diagramOptionsLabelClasses = ['form-check-label'];

    const diagramOptionsDiv = document.createElement('div');
    diagramOptionsDiv.id = 'sensordiagramoptions';
    diagramOptionsDiv.className = 'sensordiagramoptions';

    const diagramIdsOptionsDiv = document.createElement('div');

    const sensorValuesDiagramTimeUpdatedDiv = createTimeUpdatedOption(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);
    const sensorValuesDiagramSensorIdDiv = createSensorValuesDiagramSensorIdInfo(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);
    const sensorValuesDiagramTyreIdDiv = createSensorValuesDiagramTyreIdInfo(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);
    const sensorValuesDiagramTreadDepthDiv = createSensorValuesDiagramTreadDepthInfo(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);

    diagramIdsOptionsDiv.appendChild(sensorValuesDiagramTimeUpdatedDiv);
    diagramIdsOptionsDiv.appendChild(sensorValuesDiagramSensorIdDiv);
    diagramIdsOptionsDiv.appendChild(sensorValuesDiagramTyreIdDiv);
    diagramIdsOptionsDiv.appendChild(sensorValuesDiagramTreadDepthDiv);    

    const diagramMetricOptionsDiv = document.createElement('div');
    diagramMetricOptionsDiv.className = 'sensordiagrammetricoptions';

    const sensorValuesDiagramPressureMetricDiv = createSensorValuesDiagramPressureMetric(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);
    const sensorValuesDiagramTemperatureMetricDiv = createSensorValuesDiagramTemperatureMetric(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);
    const sensorValuesDiagramVoltageMetricDiv = createSensorValuesDiagramVoltageMetric(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);
    const sensorValuesDiagramSignalStrengthDiv = createSensorValuesDiagramSignalStrength(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);
    const sensorValuesDiagramRotationDiv = createSensorValuesDiagramRotation(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses);

    diagramMetricOptionsDiv.appendChild(sensorValuesDiagramPressureMetricDiv);
    diagramMetricOptionsDiv.appendChild(sensorValuesDiagramTemperatureMetricDiv);
    diagramMetricOptionsDiv.appendChild(sensorValuesDiagramVoltageMetricDiv);
    diagramMetricOptionsDiv.appendChild(sensorValuesDiagramSignalStrengthDiv);
    diagramMetricOptionsDiv.appendChild(sensorValuesDiagramRotationDiv);

    //let diagramTimeUpdatedOptionsDiv = document.createElement('div');    
    //diagramTimeUpdatedOptionsDiv.appendChild(sensorValuesDiagramTimeUpdatedDiv);

    diagramOptionsDiv.appendChild(diagramIdsOptionsDiv);
    diagramOptionsDiv.appendChild(diagramMetricOptionsDiv);
    //diagramOptionsDiv.appendChild(diagramTimeUpdatedOptionsDiv);

    if (global.sensorValuesDiagramDateSelected) {
        const diagramSelectedDateTimeDiv = document.createElement('div');
        diagramSelectedDateTimeDiv.className = 'selecteddatetime';

        const historyIcon = document.createElement('svg');
        historyIcon.className = 'historyicon';

        const sensorValuesDiagramSelectDateTimePar = document.createElement('div');
        sensorValuesDiagramSelectDateTimePar.id = 'sensorvaluesdiagramdate';
        //diagramDateOptionDiv.className = 'sensordiagramdateoption'        
        sensorValuesDiagramSelectDateTimePar.innerText = global.selectedDateTime;

        const resetButtonClasses = ['btn', 'btn-light', 'btn-sm'];

        const resetButton = document.createElement('button');
        resetButton.innerText = "x";
        resetButton.classList.add(...resetButtonClasses);

        resetButton.onclick = function () {
            
            global.sensorValuesDiagramDateSelected = false;
            global.selectedDateTime = "";
            getSensorUpdates(); //wsService
            createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
        };

        diagramSelectedDateTimeDiv.appendChild(historyIcon);
        diagramSelectedDateTimeDiv.appendChild(sensorValuesDiagramSelectDateTimePar);
        diagramSelectedDateTimeDiv.appendChild(resetButton);
        diagramOptionsDiv.appendChild(diagramSelectedDateTimeDiv);
    }

    //else {
    //    const historyIcon = document.createElement('svg');
    //    historyIcon.className = 'historyicon';
    //    const lastUpdatedSpan = document.createElement('span');
    //    lastUpdatedSpan.innerText = "Last updated: ";
    //    lastUpdatedSpan.className = 'boldtext';
    //    sensorValuesDiagramSelectDateTimePar.innerText = unit.timeUpdated;
    //    diagramSelectedDateTimeDiv.appendChild(historyIcon);
    //    diagramSelectedDateTimeDiv.appendChild(lastUpdatedSpan);
    //    diagramSelectedDateTimeDiv.appendChild(sensorValuesDiagramSelectDateTimePar);
    //}

    diagramOptionsDiv.onclick = async function () {
        gTimeUpdatedCheck = sensorValuesDiagramTimeUpdatedDiv.childNodes[0].checked;
        gSensorIdCheck = sensorValuesDiagramSensorIdDiv.childNodes[0].checked;
        gTyreIdCheck = sensorValuesDiagramTyreIdDiv.childNodes[0].checked;
        gTreadDepthCheck = sensorValuesDiagramTreadDepthDiv.childNodes[0].checked;
        if (gTreadDepthCheck) gTyreIdCheck = true;
        gPressureCheck = sensorValuesDiagramPressureMetricDiv.childNodes[0].checked;
        gTemperatureCheck = sensorValuesDiagramTemperatureMetricDiv.childNodes[0].checked;
        gVoltageCheck = sensorValuesDiagramVoltageMetricDiv.childNodes[0].checked;
        gSignalStrengthCheck = sensorValuesDiagramSignalStrengthDiv.childNodes[0].checked;
        gRotationCheck = sensorValuesDiagramRotationDiv.childNodes[0].checked;

        const units = await getUnits();
        unit = units.find(u => u.imei === unit.imei); //NB!! In new version rather get only the selected unit's latest data with /api/unit/{imei}/sensor-historical and current timestamp

        createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
    };
    
    return diagramOptionsDiv;
}

export function createSensorValuesDiagramOptions(tyreDiv, tyreArrayIndex, axleSensors, sensorType) {

    if (tyreArrayIndex !== -1) {
        const timeUpdatedDiv = createTimeUpdatedElement(tyreArrayIndex, axleSensors);
        if (gTimeUpdatedCheck) tyreDiv.appendChild(timeUpdatedDiv);

        const sensorId = axleSensors[tyreArrayIndex].sensorId;
        const sensorIdDiv = createSensorIdElement(sensorId);
        if (gSensorIdCheck) tyreDiv.appendChild(sensorIdDiv);

        const tyreIdDiv = createTyreIdElement(tyreArrayIndex, axleSensors);
        if (gTyreIdCheck) tyreDiv.appendChild(tyreIdDiv);

        const treadDepthDiv = createTreadDepthElement(tyreArrayIndex, axleSensors);
        if (gTreadDepthCheck) tyreDiv.appendChild(treadDepthDiv);

        const sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
        const sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
        const sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);
        const sensorSignalStrengthDiv = createSensorSignalStrengthElement(tyreArrayIndex, axleSensors);
        const sensorRotationDiv = createSensorRotationElement(tyreArrayIndex, axleSensors);

        if (gPressureCheck || gPressureCheck == null) tyreDiv.appendChild(sensorPressureDiv);
        if (gTemperatureCheck) tyreDiv.appendChild(sensorTemperatureDiv);
        if (gVoltageCheck) tyreDiv.appendChild(sensorVoltageDiv);
        if (gSignalStrengthCheck) tyreDiv.appendChild(sensorSignalStrengthDiv);
        if (gRotationCheck) tyreDiv.appendChild(sensorRotationDiv);
    } 

    return tyreDiv;
}

export function createSensorValuesDiagramSpareOptions(tyreDiv, axleSensors, tyre) {

    const tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors);

    const timeUpdatedDiv = createTimeUpdatedElement(tyreArrayIndex, axleSensors);
    if (gTimeUpdatedCheck) tyreDiv.appendChild(timeUpdatedDiv);  
    const sensorId = axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.sensorId || 0;
    const sensorIdDiv = createSensorIdElement(sensorId);
    if (gSensorIdCheck) tyreDiv.appendChild(sensorIdDiv);     

    
    const tyreIdDiv = createTyreIdElement(tyreArrayIndex, axleSensors);
    if (gTyreIdCheck) tyreDiv.appendChild(tyreIdDiv);   
    const treadDepthDiv = createTreadDepthElement(tyreArrayIndex, axleSensors);
    if (gTreadDepthCheck) tyreDiv.appendChild(treadDepthDiv);
    
    const sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
    const sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
    const sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);
    if (gPressureCheck || gPressureCheck == null) tyreDiv.appendChild(sensorPressureDiv);
    if (gTemperatureCheck) tyreDiv.appendChild(sensorTemperatureDiv);
    if (gVoltageCheck) tyreDiv.appendChild(sensorVoltageDiv);

    return tyreDiv;
}

export function createSensorValuesDiagramUnpairedOptions(tyreDiv, axleSensors, tyreArrayIndex) {

    const timeUpdatedDiv = createTimeUpdatedElement(tyreArrayIndex, axleSensors);
    if (gTimeUpdatedCheck) tyreDiv.appendChild(timeUpdatedDiv);
    const sensorId = axleSensors[tyreArrayIndex].sensorId || 0;
    const sensorIdDiv = createSensorIdElement(sensorId);
    if (gSensorIdCheck) tyreDiv.appendChild(sensorIdDiv);


    const tyreIdDiv = createTyreIdElement(tyreArrayIndex, axleSensors);
    if (gTyreIdCheck) tyreDiv.appendChild(tyreIdDiv);
    const treadDepthDiv = createTreadDepthElement(tyreArrayIndex, axleSensors);
    if (gTreadDepthCheck) tyreDiv.appendChild(treadDepthDiv);


    const sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
    const sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
    const sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);
    const sensorSignalStrengthDiv = createSensorSignalStrengthElement(tyreArrayIndex, axleSensors);
    const sensorRotationDiv = createSensorRotationElement(tyreArrayIndex, axleSensors);
    if (gPressureCheck || gPressureCheck == null) tyreDiv.appendChild(sensorPressureDiv);
    if (gTemperatureCheck) tyreDiv.appendChild(sensorTemperatureDiv);
    if (gVoltageCheck) tyreDiv.appendChild(sensorVoltageDiv);
    if (gSignalStrengthCheck) tyreDiv.appendChild(sensorSignalStrengthDiv);
    if (gRotationCheck) tyreDiv.appendChild(sensorRotationDiv);

    return tyreDiv;
}

function createSensorValuesDiagramTyreIdInfo(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    const sensorValuesDiagramTyreIdDiv = document.createElement('div');
    sensorValuesDiagramTyreIdDiv.classList.add(...diagramOptionsDivClasses);

    let sensorValuesDiagramTyreIdInput = document.createElement('input');
    sensorValuesDiagramTyreIdInput.id = 'sdTyreIdCheck';
    sensorValuesDiagramTyreIdInput.type = 'checkbox';

    if (!gTyreIdCheck || gTyreIdCheck == null)
        sensorValuesDiagramTyreIdInput.checked = false;
    else
        sensorValuesDiagramTyreIdInput.checked = true;

    sensorValuesDiagramTyreIdInput.classList.add(...diagramOptionsInputClasses);

    let sensorValuesDiagramTyreIdLabel = document.createElement('label');
    sensorValuesDiagramTyreIdLabel.id = 'sdTyreIdLabel';
    sensorValuesDiagramTyreIdLabel.innerText = 'Tyre ID';
    sensorValuesDiagramTyreIdLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramTyreIdDiv.appendChild(sensorValuesDiagramTyreIdInput);
    sensorValuesDiagramTyreIdDiv.appendChild(sensorValuesDiagramTyreIdLabel);

    return sensorValuesDiagramTyreIdDiv;
}

function createSensorValuesDiagramTreadDepthInfo(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    const sensorValuesDiagramTreadDepthDiv = document.createElement('div');
    sensorValuesDiagramTreadDepthDiv.classList.add(...diagramOptionsDivClasses);

    const sensorValuesDiagramTreadDepthInput = document.createElement('input');
    sensorValuesDiagramTreadDepthInput.id = 'sdTreadDepthCheck';
    sensorValuesDiagramTreadDepthInput.type = 'checkbox';

    if (!gTreadDepthCheck || gTreadDepthCheck == null)
        sensorValuesDiagramTreadDepthInput.checked = false;
    else {
        sensorValuesDiagramTreadDepthInput.checked = true;
    }

    sensorValuesDiagramTreadDepthInput.classList.add(...diagramOptionsInputClasses);

    const sensorValuesDiagramTreadDepthLabel = document.createElement('label');
    sensorValuesDiagramTreadDepthLabel.id = 'sdTreadDepthLabel';
    sensorValuesDiagramTreadDepthLabel.innerText = 'Tread Depth';
    sensorValuesDiagramTreadDepthLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramTreadDepthDiv.appendChild(sensorValuesDiagramTreadDepthInput);
    sensorValuesDiagramTreadDepthDiv.appendChild(sensorValuesDiagramTreadDepthLabel);

    return sensorValuesDiagramTreadDepthDiv;
}

function createSensorValuesDiagramSensorIdInfo(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    let sensorValuesDiagramSensorIdDiv = document.createElement('div');
    sensorValuesDiagramSensorIdDiv.classList.add(...diagramOptionsDivClasses);

    let diagramSensorIdInput = document.createElement('input');
    diagramSensorIdInput.id = 'sdSensorIdCheck';
    diagramSensorIdInput.type = 'checkbox';
    if (!gSensorIdCheck || gSensorIdCheck == null)
        diagramSensorIdInput.checked = false;
    else
        diagramSensorIdInput.checked = true;

    diagramSensorIdInput.classList.add(...diagramOptionsInputClasses);

    let diagramSensorIdLabel = document.createElement('label');
    diagramSensorIdLabel.id = 'sdSensorIdLabel';
    diagramSensorIdLabel.innerText = 'Sensor ID';
    diagramSensorIdLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramSensorIdDiv.appendChild(diagramSensorIdInput);
    sensorValuesDiagramSensorIdDiv.appendChild(diagramSensorIdLabel);

    return sensorValuesDiagramSensorIdDiv;
}

function createSensorValuesDiagramPressureMetric(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    let sensorValuesDiagramPressureMetricDiv = document.createElement('div');
    sensorValuesDiagramPressureMetricDiv.classList.add(...diagramOptionsDivClasses);

    let sensorValuesDiagramPressureMetricInput = document.createElement('input');
    sensorValuesDiagramPressureMetricInput.id = 'sdPressureCheck';
    sensorValuesDiagramPressureMetricInput.type = 'checkbox';

    if (gPressureCheck || gPressureCheck == null)
        sensorValuesDiagramPressureMetricInput.checked = true;
    else
        sensorValuesDiagramPressureMetricInput.checked = false;
    sensorValuesDiagramPressureMetricInput.classList.add(...diagramOptionsInputClasses);

    let sensorValuesDiagramPressureMetricLabel = document.createElement('label');
    sensorValuesDiagramPressureMetricLabel.id = 'sdPressureLabel';
    sensorValuesDiagramPressureMetricLabel.innerHTML = 'Pressure';
    sensorValuesDiagramPressureMetricLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramPressureMetricDiv.appendChild(sensorValuesDiagramPressureMetricInput);
    sensorValuesDiagramPressureMetricDiv.appendChild(sensorValuesDiagramPressureMetricLabel);

    return sensorValuesDiagramPressureMetricDiv;
}

function createSensorValuesDiagramTemperatureMetric(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    let sensorValuesDiagramTemperatureMetricDiv = document.createElement('div');
    sensorValuesDiagramTemperatureMetricDiv.classList.add(...diagramOptionsDivClasses);

    let diagramTemperatureMetricInput = document.createElement('input');
    diagramTemperatureMetricInput.id = 'sdTemperatureCheck';
    diagramTemperatureMetricInput.type = 'checkbox';
    if (!gTemperatureCheck || gTemperatureCheck == null)
        diagramTemperatureMetricInput.checked = false;
    else
        diagramTemperatureMetricInput.checked = true;

    diagramTemperatureMetricInput.classList.add(...diagramOptionsInputClasses);

    let diagramTemperatureMetricLabel = document.createElement('label');
    diagramTemperatureMetricLabel.id = 'sdTemperatureLabel';
    diagramTemperatureMetricLabel.innerHTML = 'Temperature';
    diagramTemperatureMetricLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramTemperatureMetricDiv.appendChild(diagramTemperatureMetricInput);
    sensorValuesDiagramTemperatureMetricDiv.appendChild(diagramTemperatureMetricLabel);

    return sensorValuesDiagramTemperatureMetricDiv;
}

function createSensorValuesDiagramVoltageMetric(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    let sensorValuesDiagramVoltageMetricDiv = document.createElement('div');
    sensorValuesDiagramVoltageMetricDiv.classList.add(...diagramOptionsDivClasses);

    let sensorValuesDiagramVoltageMetricInput = document.createElement('input');
    sensorValuesDiagramVoltageMetricInput.id = 'sdvoltagecheck';
    sensorValuesDiagramVoltageMetricInput.type = 'checkbox';

    if (!gVoltageCheck || gVoltageCheck == null)
        sensorValuesDiagramVoltageMetricInput.checked = false;
    else
        sensorValuesDiagramVoltageMetricInput.checked = true;

    sensorValuesDiagramVoltageMetricInput.classList.add(...diagramOptionsInputClasses);

    let sensorValuesDiagramVoltageMetricLabel = document.createElement('label');
    sensorValuesDiagramVoltageMetricLabel.id = 'sdVoltageLabel';
    sensorValuesDiagramVoltageMetricLabel.innerHTML = 'Voltage';
    sensorValuesDiagramVoltageMetricLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramVoltageMetricDiv.appendChild(sensorValuesDiagramVoltageMetricInput);
    sensorValuesDiagramVoltageMetricDiv.appendChild(sensorValuesDiagramVoltageMetricLabel);

    return sensorValuesDiagramVoltageMetricDiv;
}

function createSensorValuesDiagramSignalStrength(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    let sensorValuesDiagramSignalStrengthDiv = document.createElement('div');
    sensorValuesDiagramSignalStrengthDiv.classList.add(...diagramOptionsDivClasses);

    let sensorValuesDiagramSignalStrengthInput = document.createElement('input');
    sensorValuesDiagramSignalStrengthInput.id = 'sdsignalstrengthcheck';
    sensorValuesDiagramSignalStrengthInput.type = 'checkbox';

    if (!gSignalStrengthCheck || gSignalStrengthCheck == null)
        sensorValuesDiagramSignalStrengthInput.checked = false;
    else
        sensorValuesDiagramSignalStrengthInput.checked = true;

    sensorValuesDiagramSignalStrengthInput.classList.add(...diagramOptionsInputClasses);

    let sensorValuesDiagramSignalStrengthLabel = document.createElement('label');
    sensorValuesDiagramSignalStrengthLabel.id = 'sdsignalstrengthlabel';
    sensorValuesDiagramSignalStrengthLabel.innerText = 'Signal Strength';
    sensorValuesDiagramSignalStrengthLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramSignalStrengthDiv.appendChild(sensorValuesDiagramSignalStrengthInput);
    sensorValuesDiagramSignalStrengthDiv.appendChild(sensorValuesDiagramSignalStrengthLabel);

    return sensorValuesDiagramSignalStrengthDiv;
}

function createSensorValuesDiagramRotation(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    const sensorValuesDiagramRotationDiv = document.createElement('div');
    sensorValuesDiagramRotationDiv.classList.add(...diagramOptionsDivClasses);

    let sensorValuesDiagramRotationInput = document.createElement('input');
    sensorValuesDiagramRotationInput.id = 'sdrotationcheck';
    sensorValuesDiagramRotationInput.type = 'checkbox';

    if (!gRotationCheck || gRotationCheck == null)
        sensorValuesDiagramRotationInput.checked = false;
    else
        sensorValuesDiagramRotationInput.checked = true;

    sensorValuesDiagramRotationInput.classList.add(...diagramOptionsInputClasses);

    let sensorValuesDiagramRotationLabel = document.createElement('label');
    sensorValuesDiagramRotationLabel.id = 'sdrotationlabel';
    sensorValuesDiagramRotationLabel.innerText = 'Rotation';
    sensorValuesDiagramRotationLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramRotationDiv.appendChild(sensorValuesDiagramRotationInput);
    sensorValuesDiagramRotationDiv.appendChild(sensorValuesDiagramRotationLabel);

    return sensorValuesDiagramRotationDiv;
}

function createTimeUpdatedOption(diagramOptionsDivClasses, diagramOptionsInputClasses, diagramOptionsLabelClasses) {

    let sensorValuesDiagramTimeUpdatedDiv = document.createElement('div');
    sensorValuesDiagramTimeUpdatedDiv.classList.add(...diagramOptionsDivClasses);

    let diagramTimeUpdatedInput = document.createElement('input');
    diagramTimeUpdatedInput.id = 'svTimeUpdatedCheck';
    diagramTimeUpdatedInput.type = 'checkbox';
    if (!gTimeUpdatedCheck || gTimeUpdatedCheck == null)
        diagramTimeUpdatedInput.checked = false;
    else
        diagramTimeUpdatedInput.checked = true;

    diagramTimeUpdatedInput.classList.add(...diagramOptionsInputClasses);

    let diagramTimeUpdatedLabel = document.createElement('label');
    diagramTimeUpdatedLabel.id = 'svTimeUpdatedLabel';
    diagramTimeUpdatedLabel.innerText = 'Time Updated';
    diagramTimeUpdatedLabel.classList.add(...diagramOptionsLabelClasses);

    sensorValuesDiagramTimeUpdatedDiv.appendChild(diagramTimeUpdatedInput);
    sensorValuesDiagramTimeUpdatedDiv.appendChild(diagramTimeUpdatedLabel);

    return sensorValuesDiagramTimeUpdatedDiv;
}

function createTimeUpdatedElement(tyreArrayIndex, axleSensors) {

    const timeUpdated = document.createElement('div');
    timeUpdated.id = `sv${axleSensors[tyreArrayIndex].sensorId}timeupdated`;
    timeUpdated.className = 'sensorvalues timeupdated';

    const now = new Date();
    const currentTimeFormatted =
        `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;

    const payloadTS = axleSensors[tyreArrayIndex]?.payloadTS ? new Date(axleSensors[tyreArrayIndex].payloadTS) : null;

    // Format payloadTS in the same way as currentTimeFormatted
    const payloadTimeFormatted = payloadTS
        ? `${payloadTS.getHours().toString().padStart(2, '0')}:${payloadTS.getMinutes().toString().padStart(2, '0')}:${payloadTS.getSeconds().toString().padStart(2, '0')}`
        : currentTimeFormatted;

    // Set the timeUpdated to payloadTimeFormatted or currentTimeFormatted if payloadTS does not exist
    timeUpdated.innerText = payloadTimeFormatted;

    return timeUpdated;
}

function createSensorIdElement(sensorId) {

    let sensorIdDiv = document.createElement('div');
    sensorIdDiv.className = 'sensorvalues sensorid';
    sensorIdDiv.innerText = sensorId;

    return sensorIdDiv;
}

function createTyreIdElement(tyreArrayIndex, axleSensors) {

    const tyreId = document.createElement('div');
    tyreId.id = `sv${axleSensors[tyreArrayIndex].sensorId}tyreid`;
    tyreId.className = 'sensorvalues tyreid';
    tyreId.innerText = axleSensors[tyreArrayIndex]?.externalTyreId || "-";

    return tyreId;
}

function createTreadDepthElement(tyreArrayIndex, axleSensors) {

    const tyreIdTreadDepth = document.createElement('div');
    tyreIdTreadDepth.id = `sv${axleSensors[tyreArrayIndex].sensorId}treaddepth`;
    tyreIdTreadDepth.className = 'sensorvalues';

    tyreIdTreadDepth.innerText = axleSensors[tyreArrayIndex].tyreTreadDepth ? `${axleSensors[tyreArrayIndex].tyreTreadDepth} mm` : "-";

    return tyreIdTreadDepth;
}

function createSensorPressureElement(tyreArrayIndex, axleSensors) {

    const sensorPressureDiv = document.createElement('div');
    if (axleSensors[0].wheelId.slice(1) === '012') {
        const t = 0;
    }
   
    sensorPressureDiv.id = `sv${axleSensors[tyreArrayIndex].sensorId}pressure`;
        let sensorValuesDivClasses = ['sensorvalues'];

        if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].pressure) {
            sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex]?.pressureLabelColour || ''];
            sensorPressureDiv.classList.add(...sensorValuesDivClasses);
            const pressureHistory = parseFloat(axleSensors[tyreArrayIndex].pressure);
            sensorPressureDiv.innerText = `${pressureHistory.toFixed(2)} bar`;
            return sensorPressureDiv;
        }

        if (axleSensors[tyreArrayIndex].signal) {
            if (axleSensors[tyreArrayIndex].pressureLabelColour !== "green")
                sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex]?.pressureLabelColour];
            const pressure = parseFloat(axleSensors[tyreArrayIndex].currentPressure);
            sensorPressureDiv.classList.add(...sensorValuesDivClasses);
            sensorPressureDiv.innerText = `${pressure.toFixed(2)} bar`;
        } else {
            sensorValuesDivClasses = ['sensorvalues-nosignal', 'blue'];

            sensorPressureDiv.classList.add(...sensorValuesDivClasses);
            sensorPressureDiv.innerText = `No Signal`;
        }
  
    return sensorPressureDiv;
}

function createSensorTemperatureElement(tyreArrayIndex, axleSensors) {

    let sensorTemperatureDiv = document.createElement('div');
    sensorTemperatureDiv.id = `sv${axleSensors[tyreArrayIndex].sensorId}temperature`;
    let sensorValuesDivClasses = ['sensorvalues'];

    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].temperature) {
        sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex]?.temperatureLabelColour || ''];
        sensorTemperatureDiv.classList.add(...sensorValuesDivClasses);
        const temperatureHistory = axleSensors[tyreArrayIndex].temperature;
        sensorTemperatureDiv.innerText = `${temperatureHistory} °C`;
        return sensorTemperatureDiv;
    }

    if (axleSensors[tyreArrayIndex].signal) {
        if (axleSensors[tyreArrayIndex].temperatureLabelColour !== "green")
            sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex].temperatureLabelColour];
        const temperature = axleSensors[tyreArrayIndex].currentTemperature;
        sensorTemperatureDiv.classList.add(...sensorValuesDivClasses);
        sensorTemperatureDiv.innerText = temperature + "°C";
    } else {
        sensorTemperatureDiv.innerText = '\u00A0';
    }

    return sensorTemperatureDiv;
}

function createSensorVoltageElement(tyreArrayIndex, axleSensors) {

    let sensorVoltageDiv = document.createElement('div');
    sensorVoltageDiv.id = `sv${axleSensors[tyreArrayIndex].sensorId}voltage`;
    let sensorValuesDivClasses = ['sensorvalues'];

    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].voltage) {
        sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex]?.voltageLabelColour || ''];
        sensorVoltageDiv.classList.add(...sensorValuesDivClasses);
        const voltageHistory = axleSensors[tyreArrayIndex].voltage;
        sensorVoltageDiv.innerText = `${voltageHistory} V`;
        return sensorVoltageDiv;
    }

    if (axleSensors[tyreArrayIndex].signal) {
        if (axleSensors[tyreArrayIndex].voltageLabelColour !== "green")
            sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex].voltageLabelColour];
        const voltage = axleSensors[tyreArrayIndex].currentVoltage;
        sensorVoltageDiv.classList.add(...sensorValuesDivClasses);
        sensorVoltageDiv.innerText = voltage + " V";
    } else {
        sensorVoltageDiv.innerText = '\u00A0';
    }

    return sensorVoltageDiv;
}

function createSensorNoSignalElement() {

    let sensorNoSignalDiv = document.createElement('div');
    let sensorValuesDivClasses = ['sensorvalues', 'blue'];
    if (global.sensorValuesDiagramDateSelected) {
        sensorValuesDivClasses = ['sensorvalues', 'blue'];
    }
    sensorNoSignalDiv.classList.add(...sensorValuesDivClasses);
    sensorNoSignalDiv.innerText = "No Signal";

    return sensorNoSignalDiv;
}

function createSensorSignalStrengthElement(tyreArrayIndex, axleSensors) {

    let sensorSignalStrengthDiv = document.createElement('div');
    sensorSignalStrengthDiv.id = `sv${axleSensors[tyreArrayIndex].sensorId}signalstrength`;

    //if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex]?.repeater?.reliabilityDiff) {
    //    sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateSignalStrengthLabelColour];
    //    const signalStrengthHistory = axleSensors[tyreArrayIndex]?.repeater.reliabilityDiff || '';
    //    sensorSignalStrengthDiv.innerText = `${signalStrengthHistory}`;
    //    return sensorSignalStrengthDiv;
    //}
    if (global.sensorValuesDiagramDateSelected) {
        sensorSignalStrengthDiv.innerText = '\u00A0';
        return sensorSignalStrengthDiv;
    }

    if (axleSensors[tyreArrayIndex].signal) {
        let sensorValuesDivClasses = ['signalstrengthbar'];
        sensorSignalStrengthDiv.classList.add(...sensorValuesDivClasses);
        const signalStrength = axleSensors[tyreArrayIndex].repeater.reliabilityDiff;
        if (signalStrength) {
            sensorSignalStrengthDiv = createSignalStrengthBar(sensorSignalStrengthDiv, signalStrength);
        } else {
            sensorSignalStrengthDiv.innerText = 'n/a';
        }
        //for (let b = 1; b <= 10; b++) {
        //    const block = document.createElement('div');
        //    block.className = 'signalblock';
        //    block.id = 'signalblock' + b;
        //    if (b <= 3 && b < signalStrength) {
        //        block.classList.add('signalred');
        //    }
        //    if (b > 3 && b <= 6 && b < signalStrength) {
        //        block.classList.add('signalyellow');
        //    }
        //    if (b > 6 && b <= signalStrength) {
        //        block.classList.add('signalgreen');
        //    }
        //    sensorSignalStrengthDiv.appendChild(block);
        //}

    } else {
        sensorSignalStrengthDiv.innerText = 'n/a';
    }
    
    return sensorSignalStrengthDiv;
}



function createSensorRotationElement(tyreArrayIndex, axleSensors) {

    let sensorRotationDiv = document.createElement('div');
    sensorRotationDiv.id = `sv${axleSensors[tyreArrayIndex].sensorId}rotation`;
    let sensorValuesDivClasses = ['sensorvalues'];

    //if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex]?.selectedDateRotationValue) {
    //    sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateRotationLabelColour];
    //    const rotationHistory = axleSensors[tyreArrayIndex]?.selectedDateRotationValue || '';
    //    sensorRotationDiv.innerText = `${rotationHistory}`;
    //    return sensorRotationDiv;
    //}

    if (global.sensorValuesDiagramDateSelected) {
        sensorRotationDiv.innerText = '\u00A0'
        return sensorRotationDiv;
    }

    if (axleSensors[tyreArrayIndex].signal) {
        const rotation = axleSensors[tyreArrayIndex].currentRotation;
        if (rotation) {
            if (axleSensors[tyreArrayIndex]?.rotationLabelColour !== "green")
                sensorValuesDivClasses = ['sensorvalues', axleSensors[tyreArrayIndex].rotationLabelColour];

            sensorRotationDiv.classList.add(...sensorValuesDivClasses);
            sensorRotationDiv.innerText = rotation; //rotation;
        } else {
            sensorRotationDiv.innerText = '\u00A0'
        }
    } else {
        sensorRotationDiv.innerText = 'n/a';
    }

    return sensorRotationDiv;
}

function openSelectSensorDiagramDateForm(unit) {

    const selectSensorDiagramDateModal = document.getElementById('selectsensordiagramdatemodal');

    const closeSelectSensorDiagramDateModal = document.getElementById("closeselectsensordiagramdatemodal");
    closeSelectSensorDiagramDateModal.onclick = function () {
        selectSensorDiagramDateModal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == selectSensorDiagramDateModal) {
            selectSensorDiagramDateModal.style.display = "none";
        }
    };

    let selectsensordiagramdateform = document.getElementById('selectsensordiagramdateform');
    selectsensordiagramdateform.onsubmit = (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
    };
    //getUnitSensorHistoryData
    document.getElementById('ssddUnitId').value = unit.imei;    

    //let selectSensorDiagramDate = document.getElementById('ssddsensordiagramdate');

    //let today = getToday();
    //selectSensorDiagramDate.setAttribute("max", today);

    //let selectSensorDiagramTime = document.getElementById('ssddSensorDiagramTime');
    //removeSelectOptions(selectSensorDiagramTime);

    // Initialize Datepicker
    $('#datePicker').datepicker({
        format: 'yyyy-mm-dd',
        autoclose: true,
        endDate: new Date() 
    });

    // Populate hour, minute, and second dropdowns
    for (let i = 0; i < 24; i++) {
        let formattedHour = ('0' + i).slice(-2);
        $('#hourPicker').append(`<option value="${formattedHour}">${formattedHour}</option>`);
    }

    for (let i = 0; i < 60; i++) {
        let formattedMinuteSecond = ('0' + i).slice(-2);
        $('#minutePicker').append(`<option value="${formattedMinuteSecond}">${formattedMinuteSecond}</option>`);
        $('#secondPicker').append(`<option value="${formattedMinuteSecond}">${formattedMinuteSecond}</option>`);
    }

    $('#hourPicker').val('00');
    $('#minutePicker').val('00');
    $('#secondPicker').val('00');

    // Get the selected date and time on button click
    $('#getTime').click(function () {
        const date = $('#datePicker').val();
        const hour = $('#hourPicker').val() || '00';
        const minute = $('#minutePicker').val() || '00';
        const second = $('#secondPicker').val() || '00';

        const dateTime = `${date} ${hour}:${minute}:${second}`;
        console.log("Selected Date and Time:", dateTime);
    });

    selectSensorDiagramDateModal.style.display = "block";


    let selectedDateBtn = document.getElementById("selectedDateBtn");
    selectedDateBtn.onclick = async () => {
        document.body.style.cursor = 'wait';
        stopSensorUpdates();

        const date = $('#datePicker').val();
        const hour = $('#hourPicker').val() || '00';
        const minute = $('#minutePicker').val() || '00';
        const second = $('#secondPicker').val() || '00';

        const selectedDate = `${date} ${hour}:${minute}:${second}`;

        const history = await getUnitSensorHistoryData(unit.imei, selectedDate);
        
        unit.wheelSensorHistory = history.data.filter(item => parseInt(item.wheelId.slice(1, 3)) < SPARE_AXLE);
        unit.spareSensorHistory = history.data.filter(item => parseInt(item.wheelId.slice(1, 3)) === SPARE_AXLE);
        global.sensorValuesDiagramDateSelected = true;
        global.selectedDateTime = selectedDate;
        createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
        
        selectSensorDiagramDateModal.style.display = "none";
        document.body.style.cursor = 'default';
        //return false;
    };
}

//function getAxleSelectedDateTyreProperties(unit) {

//    if (unit.wheelSensors.length > 0) {

//        for (let s = 0; s < unit.wheelSensors.length; s++) {
//            unit.wheelSensors[s].selectedDateSensorType = "";
//            unit.wheelSensors[s].selectedDatePressureLabelColour = "green";
//            unit.wheelSensors[s].selectedDateTemperatureLabelColour = "green";
//            unit.wheelSensors[s].selectedDateVoltageLabelColour = "green";
//            unit.wheelSensors[s].selectedDateNoSignalLabelColour = "blue";

//            if (unit.wheelSensors[s].selectedDatePressureValue == null && unit.wheelSensors[s].selectedDateTemperatureValue == null && unit.wheelSensors[s].selectedDateVoltageValue == null) {
//                unit.wheelSensors[s].selectedDateSensorType = "wheelblue";
//                unit.wheelSensors[s].selectedDateNoSignalLabelColour = "blue";
//            }

//            if (parseFloat(unit.wheelSensors[s].selectedDatePressureValue) > parseFloat(unit.wheelSensors[s].maxPressure)) {
//                //assign flags here!!
//                unit.wheelSensors[s].selectedDateSensorType = "wheelpurple";
//                unit.wheelSensors[s].selectedDatePressureLabelColour = "purple";
//            }

//            if (parseFloat(unit.wheelSensors[s].selectedDatePressureValue) < parseFloat(unit.wheelSensors[s].minPressure)) {
//                unit.wheelSensors[s].selectedDateSensorType = "wheelred";
//                unit.wheelSensors[s].selectedDatePressureLabelColour = "red";
//            }

//            if (parseInt(unit.wheelSensors[s].selectedDateTemperatureValue) > parseInt(unit.wheelSensors[s].maxTemperature)) {
//                if (unit.wheelSensors[s].selectedDateSensorType !== "wheelpurple" && unit.wheelSensors[s].selectedDateSensorType !== "wheelred") {
//                    unit.wheelSensors[s].selectedDateSensorType = "wheelorange";
//                }
//                unit.wheelSensors[s].selectedDateTemperatureLabelColour = "orange";
//            }

//            if (parseFloat(unit.wheelSensors[s].selectedDateVoltageValue) < parseFloat(unit.wheelSensors[s].minVoltageValue)) {
//                if (unit.wheelSensors[s].selectedDateSensorType !== "wheelpurple" && unit.wheelSensors[s].selectedDateSensorType !== "wheelred" && unit.wheelSensors[s].selectedDateSensorType !== "wheelorange") {
//                    unit.wheelSensors[s].selectedDateSensorType = "wheelyellow";
//                }
//                unit.wheelSensors[s].selectedDateVoltageLabelColour = "yellow";
//            }
//        }
//    }

//    return unit;
//}

