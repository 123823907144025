import { SPARE_AXLE, UNPAIRED_AXLE  } from "../../global";

import { logMessage, addLeadingZero, formatZuluTime } from "../../utilities";

import { addTrailer } from "../../data/unitdata";
import { getUnitData } from "../../data/unitdata";
import { fetchWheelSensorData, fetchLocationSensorData } from "../../data/sensordata";
import { main } from "../mainpage";

async function processUnitData(data) {    

    let units = [];
    for (let i = 0; i < data.length; i++) {
        const unit = {};
        unit.timeUpdated = formatZuluTime(data[i].locationData?.recordTimestamp) || "Not available";
        unit.customerId = data[i].customerId;
        unit.imei = data[i].imeiNumber;
        unit.name = data[i].unitName ?? data[i].imeiNumber;
        unit.deviceName = data[i].deviceName;
        unit.deviceType = data[i].unitType;
        unit.gatewayBatteryVoltage = data[i].gateway?.batteryVoltage;
        unit.gatewayPowerSupplyVoltage = data[i].gateway?.externalVoltage;
        unit.sinkId = data[i].sinkId;
        unit.sinkBatteryVoltage = data[i].sinkBatteryVoltage;
        unit.sinkPowerSupplyVoltage = data[i].sinkPowerSupplyVoltage;
        //unit.mobileNo = data[i].ph;
        unit.speed = data[i].speed;
        //unit.position = data[i].pos;
        unit.location = data[i].locationData;
        
        unit.driverName = data[i].driverName;
        unit.driverContactNumber = data[i].driverContactNumber;
        unit.notificationSettings = data[i].notificationSettings;
        unit.axleRanges = data[i].axleRanges || [];
        
        const sensors = await fetchWheelSensorData(data[i].imeiNumber);
        
        // Add properties from axleRanges to wheelSensors
        sensors.forEach(sensor => {
            // Extract the axle number from the wheelId
            const axleNumber = sensor.wheelId.slice(1);

            // Find the corresponding axleRange by axleNumber
            const axleRange = unit.axleRanges.find(range => range.axleNumber === axleNumber);

            // If axleRange is found, add the properties to the wheelSensor
            if (axleRange) {
                sensor.recommendedPressure = axleRange.recommendedPressure;
                sensor.pressureDeviationAllowedLow = axleRange.pressureDeviationAllowedLow;
                sensor.pressureDeviationAllowedHigh = axleRange.pressureDeviationAllowedHigh;
                sensor.minPressure = axleRange.minPressure;
                sensor.maxPressure = axleRange.maxPressure;
                sensor.maxTemperature = axleRange.maxTemperature;
                sensor.minVoltage = 2.5; //axleRange.minVoltage;
            }
            sensor.signal = getSensorSignalStatus(sensor);
        });

        unit.wheelSensors = sensors.filter(item => parseInt(item.wheelId.slice(1, 3)) < SPARE_AXLE);
        unit.spareSensors = sensors.filter(item => parseInt(item.wheelId.slice(1, 3)) === SPARE_AXLE);
        unit.unpairedSensors = sensors.filter(item => parseInt(item.wheelId.slice(1, 3)) === UNPAIRED_AXLE);
        unit.locationSensors = await fetchLocationSensorData(data[i].imeiNumber);        

        calculateUnitFlags(unit);

        units.push(unit);
    }

    units.sort((a, b) => {
        if (b.blinkFlags !== a.blinkFlags) {
            return b.blinkFlags - a.blinkFlags;
        }
        if (b.redFlags !== a.redFlags) {
            return b.redFlags - a.redFlags;
        }
        if (b.orangeFlags !== a.orangeFlags) {
            return b.orangeFlags - a.orangeFlags;
        }
        if (b.purpleFlags !== a.purpleFlags) {
            return b.purpleFlags - a.purpleFlags;
        }
        if (b.yellowFlags !== a.yellowFlags) {
            return b.yellowFlags - a.yellowFlags;
        }
        return b.grayFlags - a.grayFlags; // Fallback to sorting by gray flags last
    });
    return units;
}

export async function getUnits() {

    let unitData = await getUnitData();
    let units = await processUnitData(unitData);  
    units = getUnitConfigs(units);
    global.units = units;
    return units;
}

function getSensorSignalStatus(sensor) {
    // Convert the payloadTS to a Date object
    let payloadTS = new Date(sensor.payloadTS);

    // Get current time in South African Standard Time (SAST), which is UTC+2
    let now = new Date();
    //let nowSAST = new Date(now.getTime() + (2 * 60 * 60 * 1000)); // Adjust to SAST

    // Subtract 40 minutes (40 * 60 * 1000 milliseconds) from the current time
    let thresholdTime = new Date(now.getTime() - (40 * 60 * 1000));

    // Compare the payloadTS with the thresholdTime
    if (payloadTS < thresholdTime) {
        return false;
    } else {
        return true;
    }
}

export function getUnitMetricAttributes(unit) {

    if (unit.axleRanges) {
        for (let s in unit.wheelSensors) {
            const axleUnit = unit.wheelSensors[s].wheelId.slice(1);
            for (let r = 0; r < unit.axleRanges.length; r++) {
                if (axleUnit === unit.axleRanges[r].axleNumber) {
                    unit.wheelSensors[s].recommendedPressure = unit.axleRanges[r].recommendedPressure;
                    unit.wheelSensors[s].pressureDeviationAllowedLow = unit.axleRanges[r].pressureDeviationAllowedLow;
                    unit.wheelSensors[s].pressureDeviationAllowedHigh = unit.axleRanges[r].pressureDeviationAllowedHigh;
                    unit.wheelSensors[s].minPressure = unit.axleRanges[r].minPressure;
                    unit.wheelSensors[s].maxPressure = unit.axleRanges[r].maxPressure;
                    unit.wheelSensors[s].maxTemperature = unit.axleRanges[r].maxTemperature;
                    unit.wheelSensors[s].minVoltageValue = unit.axleRanges[r].minVoltage;
                    break;
                }
            }
        }

        for (let s in unit.spareSensors) {
            const axleUnit = unit.spareSensors[s].wheelId.slice(1);
            for (let r = 0; r < unit.axleRanges.length; r++) {
                if (axleUnit === unit.axleRanges[r].axleNumber) {
                    unit.spareSensors[s].recommendedPressure = unit.axleRanges[r].recommendedPressure;
                    unit.spareSensors[s].pressureDeviationAllowedLow = unit.axleRanges[r].pressureDeviationAllowedLow;
                    unit.spareSensors[s].pressureDeviationAllowedHigh = unit.axleRanges[r].pressureDeviationAllowedHigh;
                    unit.spareSensors[s].minPressure = unit.axleRanges[r].minPressure;
                    unit.spareSensors[s].maxPressure = unit.axleRanges[r].maxPressure;
                    unit.spareSensors[s].maxTemperature = unit.axleRanges[r].maxTemperature;
                    unit.spareSensors[s].minVoltageValue = unit.axleRanges[r].minVoltage;
                    break;
                }
            }
        }
    }
}

export function updateUnitMetrics(unit, data) {

    const sensor = unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
        unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID);

    if (!sensor) {
        console.warn(`Sensor with ID ${data.wheelSensorUID} not found.`);
        return;
    }

    // Update sensor values from WebSocket payload
    sensor.signal = true;
    sensor.currentPressure = parseFloat(data.currentPressure).toFixed(2);
    sensor.currentTemperature = data.currentTemperature;
    sensor.currentVoltage = parseFloat(data.currentVoltage).toFixed(2);
    sensor.currentRotation = data.currentRotation;  

    sensor.sensorType = 'wheelcorrect';
    if (sensor.currentPressure < sensor.minPressure) {
        sensor.pressureLabelColour = 'red';
        sensor.sensorType = 'wheelred';
        if (sensor.wheelId.slice(1, 3) !== SPARE_AXLE && sensor.currentPressure < parseFloat(sensor.recommendedPressure) * 20 / 100) {
            sensor.sensorType = 'wheelblink';
        }
    } else if (sensor.currentPressure > sensor.maxPressure) {
        sensor.pressureLabelColour = 'purple';
        sensor.sensorType = 'wheelpurple';
    } else {
        sensor.pressureLabelColour = 'green';
    }

    if (sensor.currentTemperature > sensor.maxTemperature) {
        sensor.temperatureLabelColour = "orange";
        if (sensor.sensorType !== "wheelred") {
            sensor.sensorType = "wheelorange";
        }
    } else {
        sensor.temperatureLabelColour = "green";
    }

    if (sensor.currentVoltage < sensor.minVoltage) {
        sensor.voltageLabelColour = "yellow";
        if (sensor.sensorType !== "wheelpurple" && sensor.sensorType !== "wheelred" && sensor.sensorType !== "wheelorange") {
            sensor.sensorType = "wheelyellow";
        }
        sensors[s].voltageLabelColour = "yellow";
    } else {
        sensor.voltageLabelColour = "green";
    }

    return unit;
}

function calculateUnitFlags(unit) {

    unit.grayFlags = 0;
    unit.blinkFlags = 0;    
    unit.redFlags = 0;
    unit.purpleFlags = 0;
    unit.orangeFlags = 0;
    unit.yellowFlags = 0;

    const sensors = [...unit.wheelSensors, ...unit.spareSensors];
    for (let s = 0; s < sensors.length; s++) {
        if (sensors[s].signal) {
            if (parseFloat(sensors[s].currentPressure) > parseFloat(sensors[s].maxPressure))
                unit.purpleFlags++;
            if (sensors[s].wheelId.slice(1, 3) !== SPARE_AXLE.toString() && parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].recommendedPressure) * 20 / 100)
                unit.blinkFlags++;
            if (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].minPressure))
                unit.redFlags++;
            if (parseFloat(sensors[s].currentTemperature) > parseFloat(sensors[s].maxTemperature))
                unit.orangeFlags++;
            if (parseFloat(sensors[s].currentVoltage) < parseFloat(sensors[s].minVoltageValue))
                unit.yellowFlags++;
        } else {
            unit.grayFlags++;
        }
    }
    return unit;
}

function getUnitConfigs(units) {
    const t = 0;
    for (let u = 0; u < units.length; u++) {
        if (units[u].wheelSensors.length > 0) {
            const unitConfig = getUnitConfig(units[u]);
            //units[u].sensorCount = countUnitSensors(unitConfig);
            units[u].unitConfig = unitConfig;
        }
    }
    return units;
}

function countUnitSensors(unitConfig) {
    let sensorCount = 0;
    for (let u = 0; u < unitConfig.length; u++) {
        sensorCount = sensorCount + unitConfig[u].sensorCount;
    }
    return sensorCount;
}

function getUnitConfig(unit) {

    let unitConfig = [];

    // Check if wheelSensors is not empty before proceeding
    const wheelSensorUnitNumbers = unit.wheelSensors.length === 0
        ? []
        : [...new Set(unit.wheelSensors.map(item => parseInt(item.wheelId.slice(-1))))];

    let maxUnitNumber = wheelSensorUnitNumbers.length === 0
        ? 0
        : Math.max(...wheelSensorUnitNumbers);

    // Check if locationSensors is not empty before calculating maxLocationSensorUnitNumber
    // const maxLocationSensorUnitNumber = unit.locationSensors.length === 0
    const maxLocationSensorUnitNumber = unit.locationSensors.count === 0
        ? 0
        : Math.max(...[...new Set(unit.locationSensors.map(item => item?.wheelId.slice(-1)))].map(Number));

    // Update maxUnitNumber if maxLocationSensorUnitNumber is greater
    maxUnitNumber = Math.max(maxUnitNumber, maxLocationSensorUnitNumber);

    // Generate unit numbers based on maxUnitNumber
    const unitNumbers = Array.from({ length: maxUnitNumber + 1 }, (_, index) => index.toString()).sort();

    // Populate unitConfig array
    for (let un = 0; un < unitNumbers.length; un++) {
        unitConfig.push(getUnitNumberConfig(unit, unitNumbers[un]));
    }

    return unitConfig;
}

function getUnitNumberConfig(unit, unitNumber) {
    const unitNumberConfig = {};
    unitNumberConfig.unitNumber = unitNumber;
    unitNumberConfig.name = unitNumber === 0 ? `unit ${unitNumber}` : `trailer ${unitNumber}`;
    const axles = getAxles(unit, unitNumber);
    unitNumberConfig.axles = axles;
    
    unitNumberConfig.axleCount = axles.length;
    unitNumberConfig.sensorCount = countAxleSensors(axles);
    return unitNumberConfig;
}

function countAxleSensors(axles) {
    let sensorCount = 0;
    for (let a = 0; a < axles.length; a++) {
        if (axles[a].wheelConfig === 0) sensorCount = sensorCount + 2;
        else sensorCount = sensorCount + 4;
    }
    return sensorCount;
}

function getAxles(unit, unitNumber) {

    // Check if wheelSensors is not empty before proceeding
    const wheelSensorAxles = unit.wheelSensors.length === 0
        ? []
        : [...new Set(unit.wheelSensors
            .filter(item => item.wheelId.slice(-1) === unitNumber.toString())
            .map(item => item.wheelId.slice(1, 3)))];

    let maxAxles = wheelSensorAxles.length === 0
        ? 0
        : Math.max(...wheelSensorAxles.map(Number));

    // Check if locationSensors is not empty before proceeding
    //const locationSensorAxles = unit.locationSensors.length === 0
    const locationSensorAxles = unit.locationSensors.count === 0
        ? []
        : [...new Set(unit.locationSensors
            .filter(item => item.wheelId.slice(-1) === unitNumber.toString())
            .map(item => item.wheelId.slice(1, 3)))];

    const maxLocationSensorAxles = locationSensorAxles.length === 0
        ? 0
        : Math.max(...locationSensorAxles.map(Number));

    // Update maxAxles if maxLocationSensorAxles is greater
    if (maxAxles < maxLocationSensorAxles) maxAxles = maxLocationSensorAxles;

    // Generate axle numbers based on maxAxles
    const axleNumbers = Array.from({ length: maxAxles }, (_, index) => addLeadingZero(index + 1)).sort();

    // Populate axles array
    const axles = [];
    for (let a = 0; a < axleNumbers.length; a++) {
        axles.push(getAxleWheelConfig(unit, unitNumber, axleNumbers[a]));
    }

    return axles;
}

function getAxleWheelConfig(unit, unitNumber, axle) {
    const axleWheelConfig = {};
    axleWheelConfig.axle = axle;
    axleWheelConfig.wheelConfig = getWheelConfig(unit, unitNumber, axle);
    return axleWheelConfig;
}

function getWheelConfig(unit, unitNumber, axle) {
    // Check if locationSensors is not empty before proceeding
    let wheelConfig = unit.locationSensors.count === 0
        ? null
        : unit.locationSensors.filter(s => s.wheelId === `l${axle}${unitNumber}`)[0]?.wheelConfig
        || unit.locationSensors.filter(s => s.wheelId === `r${axle}${unitNumber}`)[0]?.wheelConfig;

    if (wheelConfig == null) {
        wheelConfig = 0;

        // Check if wheelSensors is not empty before proceeding
        const wheels = unit.wheelSensors.length === 0
            ? []
            : [...new Set(unit.wheelSensors
                .filter(item => item.wheelId.slice(-1) === unitNumber && item.wheelId.slice(1, 3) === axle)
                .map(item => item.wheelId.slice(0, 1)))];

        if (wheels.length > 2) {
            wheelConfig = 1;
        } else {
            const wheelsToCheck = ["2", "3"];
            if (wheelsToCheck.some(wheel => wheels.includes(wheel))) {
                wheelConfig = 1;
            }
        }
    }

    return wheelConfig;
}


export function getUnitAxles(unitNumber, wheelSensors) {

    let unitAxles = [];
    for (let ws = 0; ws < wheelSensors.length; ws++) {
        if (wheelSensors[ws].wheelId.slice(3, 4) === unitNumber.toString())
            unitAxles.push(wheelSensors[ws].wheelId.slice(1, 3));
    }
    // && wheelSensors[ws].wheelId.slice(1, 3) !== SPARE_AXLE.toString()
    return [...new Set(unitAxles)].sort();
}


export function getUnitAxleSensors(wheelSensors, axleUnit) {

    return wheelSensors.filter(s => s.wheelId.slice(-3) === axleUnit);    
}


export function getUnitSpareSensors(wheelSensors) {

    return wheelSensors.filter(s => parseInt(s.wheelId.slice(-3)) === "170");
}

export async function getUnitTrailers(resource, unit) {

    const resourceTrailers = resource.getTrailers();
    let trailerCodes = ["011", "012", "013", "014", "015"];
    let newTrailers = [];
    for (let l = 0; l < unit.locationSensors.count; l++) {
        let repeaterFound = false;
        if (!repeaterFound && trailerCodes.includes(unit?.locationSensors[l].wheelId.slice(1))) {
            repeaterFound = Object.keys(resourceTrailers).some(trailer => {
                const unitNameTrailerNo = resourceTrailers[trailer].ds;
                return unitNameTrailerNo === `${unit.name}-${unit.locationSensors[l].wheelId.slice(-1)}`;
            });
            //Add trailer
            if (!repeaterFound) {
                repeaterFound = true;
                let index = trailerCodes.findIndex(code => code.slice(-1) === unit.locationSensors[l].wheelId.slice(-1));
                if (index !== -1) {
                    // Remove the matched trailer code from the array
                    trailerCodes.splice(index, 1);
                }
                const trailer = {};
                trailer.name = `Trailer ${unit.locationSensors[l].wheelId.slice(-1)}`;
                trailer.order = parseInt(unit.locationSensors[l].wheelId.slice(-1));
                trailer.sensorId = unit.locationSensors[l].sensorId;
                trailer.unitLinkedTo = unit.name;
                const addedTrailer = await addTrailer(trailer);
                trailer.id = addedTrailer[0];
                newTrailers.push(trailer);
            }
        }
    }

    const rTrailersUpdated = resource.getTrailers();
    let trailers = [];
    for (let t in rTrailersUpdated) {
        const trl = rTrailersUpdated[t];

        if (unit.name === trl.ds.slice(0, -2)) {
            const trailer = {};
            trailer.id = trl.id;
            trailer.name = trl.n;
            trailer.sensorId = trl.c;
            trailer.unitLinkedTo = unit.name;
            trailer.order = parseInt(trl.ds.slice(-1));
            trailers.push(trailer);
        }
    }

    return trailers;


}

export function getWheelProperties(sensors) {

    try { 
        if (sensors.length > 0) {
            for (let s = 0; s < sensors.length; s++) {
                sensors[s].wheelType = "wheelactive";
                sensors[s].sensorType = "";
                //if (parseInt(sensors[s].wheelId.axle) !== SPARE_AXLE && parseInt(sensors[s].wheelId.axle) !== UNPAIRED_AXLE) {
                //    sensors[s].tyreName = "A" + sensors[s].wheelId.slice(1, 3).replace(/^0+/, "") + "-T" + sensors[s].wheelId.slice(0, 1);
                //} else {
                //    if (parseInt(sensors[s].wheelId.axle) === SPARE_AXLE) {
                //        sensors[s].tyreName = `S-${sensors[s].wheelId.slice(0, 1)}`;
                //    } else {
                //        sensors[s].tyreName = `U-${sensors[s].wheelId.slice(0, 1)}`;
                //    }
                //}
                const { wheelId } = sensors[s]; // Destructure for cleaner access
                const axle = parseInt(wheelId.slice(1,3));

                if (axle !== SPARE_AXLE && axle !== UNPAIRED_AXLE) {
                    // Handle non-spare and non-unpaired axles
                    sensors[s].tyreName = `A${wheelId.slice(1, 3).replace(/^0+/, "")}-T${wheelId.slice(0, 1)}`;
                } else {
                    // Handle spare and unpaired axles
                    const prefix = axle === SPARE_AXLE ? 'S' : 'U';
                    sensors[s].tyreName = `${prefix}-${wheelId.slice(0, 1)}`;
                }
                sensors[s].pressureLabelColour = "green";
                sensors[s].temperatureLabelColour = "green";
                sensors[s].voltageLabelColour = "green";
                sensors[s].noSignalLabelColour = "green";

                if (sensors[s].currentPressure == null && sensors[s].currentTemperature == null && sensors[s].currentVoltage == null) {
                    sensors[s].sensorType = "wheelblue";
                    sensors[s].noSignalLabelColour = "blue";
                }

                if (!sensors[s].signal) {
                    sensors[s].sensorType = "wheelblue";
                    sensors[s].noSignalLabelColour = "blue";
                }

                if (parseFloat(sensors[s].currentPressure) > parseFloat(sensors[s].maxPressure)) {
                    sensors[s].sensorType = "wheelpurple";
                    sensors[s].pressureLabelColour = "purple";
                }

                if (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].minPressure)) {
                    sensors[s].sensorType = "wheelred";
                    if (parseInt(sensors[s].wheelId.slice(1, 3)) !== SPARE_AXLE && (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].recommendedPressure) * 20/100)) {
                        sensors[s].sensorType = "wheelblink"; 
                    }
                    sensors[s].pressureLabelColour = "red";
                }

                if (parseInt(sensors[s].currentTemperature) > parseInt(sensors[s].maxTemperature)) {
                    if (sensors[s].sensorType !== "wheelred") {
                        sensors[s].sensorType = "wheelorange";
                    }
                    sensors[s].temperatureLabelColour = "orange";
                }

                if (parseFloat(sensors[s].currentVoltage) < parseFloat(sensors[s].minVoltage)) {
                    if (sensors[s].sensorType !== "wheelpurple" && sensors[s].sensorType !== "wheelred" && sensors[s].sensorType !== "wheelorange") {
                        sensors[s].sensorType = "wheelyellow";
                    }
                    sensors[s].voltageLabelColour = "yellow";
                }
            }
        }

        return sensors;

    } catch (e) {        
        console.error('unitsJS: getWheelProperties - ERROR: ', e);
    }
}

function getTyreArrayIndex(tyre, axleSensors) {

    let tyreArrayIndex = 0;
    for (let s = 0; s < axleSensors.length; s++) {
        if (parseInt(axleSensors[s].wheelId.slice(0, 1)) === tyre) {
            tyreArrayIndex = s;
            break;
        }
    }
    return tyreArrayIndex;
}

function loadSensorValuesBatch(prms) {

    let remote = wialon.core.Remote.getInstance();

    return new Promise((resolve, reject) => {
        remote.remoteCall('core/batch', prms,
            (error, result) => {
                if (error) {
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return;
                }
                resolve(result);
            },
        );
    });
}


export { getUnitConfigs, getUnitConfig, loadSensorValuesBatch, getTyreArrayIndex };



