import { getWheelProperties } from "../units";

export function displayStatusSummary(units) {

    let unitTotal = units.length;
    if (parseInt(unitTotal) === 0) {
        let totalUnitsSpan = document.getElementById('totalunits');
        totalUnitsSpan.innerText = '-';

        document.getElementById('lowvoltagemetrics').style.display = 'none';
        document.getElementById('underinflatedmetrics').style.display = 'inherit';
        document.getElementById('overinflatedmetrics').style.display = 'inherit';
        document.getElementById('hightemperaturemetrics').style.display = 'none';

        const underInflatedSpan = document.getElementById('underInflated');
        underInflatedSpan.innerText = '-';
        const percUnderInflatedSpan = document.getElementById('percentageUnderInflated');
        percUnderInflatedSpan.innerText = '- ';
        const overInflatedSpan = document.getElementById('overInflated');
        overInflatedSpan.innerText = '-';
        const percOverInflatedSpan = document.getElementById('percentageOverInflated');
        percOverInflatedSpan.innerText = '- ';
        document.getElementById('normalvoltagelabel').style.display = 'none';
        document.getElementById('normalpressurelabel').style.display = 'inherit';
        document.getElementById('normaltemperaturelabel').style.display = 'none';
        const normalSpan = document.getElementById('normal');
        normalSpan.innerText = '-';
        const percNormalSpan = document.getElementById('percentagenormal');
        percNormalSpan.innerText = '- ';

        const noSignalSpan = document.getElementById('noSignal');
        noSignalSpan.innerText = '-';
        //const percNoSignalSpan = document.getElementById('percentagenosignal');
        //percNoSignalSpan.innerText = '- ';
        const totalSensorValuesSpan = document.getElementById('totalSensorValues');
        totalSensorValuesSpan.innerText = '-';
    } else {
        const totalUnitsSpan = document.getElementById('totalunits');
        totalUnitsSpan.innerText = unitTotal;

        let totalSensorValues = 0;
        let yellowStatus = 0;
        let blinkStatus = 0;
        let redStatus = 0;
        let greenStatus = 0;
        let purpleStatus = 0;
        let orangeStatus = 0;
        let grayStatus = 0;

        for (let unit = 0; unit < units.length; unit++) {
            totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].wheelSensors.length) + parseInt(units[unit].spareSensors.length);
            yellowStatus = parseInt(yellowStatus) + parseInt(units[unit].yellowFlags);
            blinkStatus = parseInt(blinkStatus) + parseInt(units[unit].blinkFlags);
            redStatus = parseInt(redStatus) + parseInt(units[unit].redFlags);
            purpleStatus = parseInt(purpleStatus) + parseInt(units[unit].purpleFlags);
            orangeStatus = parseInt(orangeStatus) + parseInt(units[unit].orangeFlags);
            grayStatus = parseInt(grayStatus) + parseInt(units[unit]?.grayFlags || 0);
        }

        if (document.getElementById('rbtnPressure').checked === true) {

            global.selectedMetric = "PRESSURE";

            document.getElementById('lowvoltagemetrics').style.display = 'none';
            document.getElementById('underinflatedmetrics').style.display = 'inherit';
            document.getElementById('overinflatedmetrics').style.display = 'inherit';
            document.getElementById('hightemperaturemetrics').style.display = 'none';

            let underInflatedSpan = document.getElementById('underInflated');
            underInflatedSpan.innerText = redStatus;
            let percUnderInflatedSpan = document.getElementById('percentageUnderInflated');
            percUnderInflatedSpan.innerText = Math.round(redStatus / totalSensorValues * 100);
            let overInflatedSpan = document.getElementById('overInflated');
            overInflatedSpan.innerText = purpleStatus;
            let percOverInflatedSpan = document.getElementById('percentageOverInflated');
            percOverInflatedSpan.innerText = Math.round(purpleStatus / totalSensorValues * 100);
            document.getElementById('normalvoltagelabel').style.display = 'none';
            document.getElementById('normalpressurelabel').style.display = 'inherit';
            document.getElementById('normaltemperaturelabel').style.display = 'none';
            let normalSpan = document.getElementById('normal');
            greenStatus = totalSensorValues - (redStatus + purpleStatus);
            normalSpan.innerText = greenStatus;
            let percNormalSpan = document.getElementById('percentagenormal');
            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
        }

        if (document.getElementById('rbtnTemperature').checked === true) {

            global.selectedMetric = "TEMPERATURE";

            document.getElementById('lowvoltagemetrics').style.display = 'none';
            document.getElementById('underinflatedmetrics').style.display = 'none';
            document.getElementById('overinflatedmetrics').style.display = 'none';
            document.getElementById('hightemperaturemetrics').style.display = 'inherit';

            document.getElementById('normalvoltagelabel').style.display = 'none';
            document.getElementById('normalpressurelabel').style.display = 'none';
            document.getElementById('normaltemperaturelabel').style.display = 'inherit';
            let normalSpan = document.getElementById('normal');
            greenStatus = parseInt(greenStatus) + (parseInt(totalSensorValues) - parseInt((parseInt(orangeStatus) + parseInt(grayStatus))));
            normalSpan.innerText = greenStatus;
            let percNormalSpan = document.getElementById('percentagenormal');
            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
            let highTemperatureSpan = document.getElementById('hightemperature');
            highTemperatureSpan.innerText = orangeStatus;
            let percentageHighTemperatureSpan = document.getElementById('percentagehightemperature');
            percentageHighTemperatureSpan.innerText = Math.round(orangeStatus / totalSensorValues * 100);
        }

        if (document.getElementById('rbtnVoltage').checked === true) {

            global.selectedMetric = "VOLTAGE";

            document.getElementById('lowvoltagemetrics').style.display = 'inherit';
            document.getElementById('underinflatedmetrics').style.display = 'none';
            document.getElementById('overinflatedmetrics').style.display = 'none';
            document.getElementById('hightemperaturemetrics').style.display = 'none';

            let lowVoltageSpan = document.getElementById('lowvoltage');
            lowVoltageSpan.innerText = yellowStatus;
            let percentageLowVoltageSpan = document.getElementById('percentagelowvoltage');
            percentageLowVoltageSpan.innerText = Math.round(yellowStatus / totalSensorValues * 100);
            document.getElementById('normalvoltagelabel').style.display = 'inherit';
            document.getElementById('normalpressurelabel').style.display = 'none';
            document.getElementById('normaltemperaturelabel').style.display = 'none';
            let normalSpan = document.getElementById('normal');
            greenStatus = parseInt(greenStatus) + (parseInt(totalSensorValues) - parseInt((parseInt(yellowStatus) + parseInt(grayStatus))));
            normalSpan.innerText = greenStatus;
            let percNormalSpan = document.getElementById('percentagenormal');
            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
        }

        const noSignalSpan = document.getElementById('noSignal');
        noSignalSpan.innerText = grayStatus;
        //const percNoSignalSpan = document.getElementById('percentagenosignal');
        //percNoSignalSpan.innerText = Math.round(grayStatus / totalSensorValues * 100);
        const totalSensorValuesSpan = document.getElementById('totalSensorValues');
        totalSensorValuesSpan.innerText = totalSensorValues;
    }

    const lastUpdatedMain = document.getElementById('lastupdatedmain');

    // Find the unit with the latest message
    const foundUnitLastMessage = units.reduce((latestUnit, currentUnit) => {
        const latestUnitValid = latestUnit && latestUnit.timeUpdated !== "Invalid Date";
        const currentUnitValid = currentUnit && currentUnit.timeUpdated !== "Invalid Date";

        if (!currentUnitValid) {
            return latestUnitValid ? latestUnit : null;
        }

        if (!latestUnitValid) {
            return currentUnit;
        }

        return latestUnit.timeUpdated > currentUnit.timeUpdated ? latestUnit : currentUnit;
    }, null);

    if (foundUnitLastMessage && foundUnitLastMessage.timeUpdated !== "Invalid Date") {
        lastUpdatedMain.innerText = " " + foundUnitLastMessage.timeUpdated;
    } else {
        lastUpdatedMain.innerText = "Not Available";
    }
}

export function createSubunitContainers(unit) {

    const subunitFragment = document.createDocumentFragment();

    // Example logic for creating trailer containers based on the wheelID
    const sensors = [...unit.wheelSensors, ...unit.spareSensors];

    const subunitSensorsMap = {};
    const sortedSensors = sortSensors(sensors);

    sensors.forEach(sensor => {
        const lastDigit = sensor.wheelId.slice(-1);

        if (!subunitSensorsMap[lastDigit]) {
            subunitSensorsMap[lastDigit] = [];
        }
        subunitSensorsMap[lastDigit].push(sensor);
    });

    const subunitKeys = Object.keys(subunitSensorsMap).sort((a, b) => a - b);

    const subunitMap = {};

    subunitKeys.forEach(lastDigit => {
        // Sort the sensors within each subunit by pressure, temperature, or voltage
        subunitSensorsMap[lastDigit].sort((s1, s2) => {
            if (global.selectedMetric === 'TEMPERATURE') {
                return parseFloat(s2.currentTemperature) - parseFloat(s1.currentTemperature); // Descending for temperature
            } else {
                return parseFloat(s1.currentPressure) - parseFloat(s2.currentPressure); // Ascending for pressure and voltage
            }
        });

        // Create the subunit container if it doesn't exist
        if (!subunitMap[lastDigit]) {
            const subunitDiv = document.createElement('div');
            subunitDiv.id = `u${unit.imei}sub${lastDigit}`;
            subunitDiv.className = 'statusdiagram subunit';

            // Add subunit name as a header element
            const subunitNameDiv = document.createElement('div');
            subunitNameDiv.className = 'subunitname';

            // Set the name based on lastDigit
            if (lastDigit === '0') {
                subunitNameDiv.innerText = unit.name || unit.imei; // Main unit
            } else {
                subunitNameDiv.innerText = subunitSensorsMap[lastDigit][0].repeater?.trailerName || `Trailer ${lastDigit}`; 
            }

            // Append the name div to the subunit div
            //subunitDiv.appendChild(subunitNameDiv);
            subunitMap[lastDigit] = subunitDiv;
            subunitFragment.appendChild(subunitNameDiv);
            subunitFragment.appendChild(subunitDiv);
        }

        // Create subunit container if it doesn't exist
        if (!subunitMap[lastDigit]) {
            const subunitDiv = document.createElement('div');
            subunitDiv.id = `unit${unit.imei}sub${lastDigit}`;
            subunitDiv.className = 'statusdiagram subunit';
            const subunitNameDiv = document.createElement('div');
            subunitNameDiv.className = 'subunitname';
            if (lastDigit === '0') {
                subunitNameDiv.innerText = unit.name || unit.imei; // Main unit
            } else {
                subunitNameDiv.innerText = sensor.repeater?.trailerName || `Trailer ${lastDigit}`; // Subunit name
            }
            //subunitDiv.appendChild(subunitNameDiv);

            subunitMap[lastDigit] = subunitDiv;
            subunitFragment.appendChild(subunitNameDiv);
            subunitFragment.appendChild(subunitDiv);
        }

        // Add sensor status to the subunit container
        let sensorDiv;
        let noSignalDivs = [];  // Array to hold 'no signal' divs

        // First, append sensors with a signal
        subunitSensorsMap[lastDigit].forEach(sensor => {
            if (sensor.signal) {
                sensorDiv = createSensorDivByMetric(unit, sensor);
                if (sensorDiv) subunitMap[lastDigit].appendChild(sensorDiv);
            } else {
                // Create and store the 'no signal' divs for later appending
                sensorDiv = createSensorDivByMetric(unit, sensor);
                if (sensorDiv) noSignalDivs.push(sensorDiv);
            }
        });

        // Now, append 'no signal' divs at the end
        noSignalDivs.forEach(noSignalDiv => {
            subunitMap[lastDigit].appendChild(noSignalDiv);
        });

    });

    return subunitFragment;
}

function sortSensors(sensors) {

    const metric = global.selectedMetric;
    return sensors.sort((s1, s2) => {
        switch (metric) {
            case 'PRESSURE':
                return parseFloat(s1.currentPressure) - parseFloat(s2.currentPressure);
            case 'TEMPERATURE':
                return parseFloat(s2.currentTemperature) - parseFloat(s1.currentTemperature);
            case 'VOLTAGE':
                return parseFloat(s1.currentVoltage) - parseFloat(s2.currentVoltage);
            default:
                return 0;
        }
    });
}

function createSensorDivByMetric(unit, sensor) {
    const metric = global.selectedMetric;
    switch (metric) {
        case 'PRESSURE':
            return sensor.signal ? createSensorPressureElement(unit.imei, sensor) : createSensorNoSignalElement(unit.imei, sensor, 'pressure');
        case 'TEMPERATURE':
            return sensor.signal ? createSensorTemperatureElement(unit.imei, sensor) : createSensorNoSignalElement(unit.imei, sensor, 'temperature');
        case 'VOLTAGE':
            return sensor.signal ? createSensorVoltageElement(unit.imei, sensor) : createSensorNoSignalElement(unit.imei, sensor, 'voltage');
        default:
            return null;
    }
}

export function splitSubunits(unitStatusDiv, unit) {
    // Clear the current content
    unitStatusDiv.textContent = '';

    // Separate sensors into main unit and trailers based on wheelId's last digit
    const mainSensors = unit.wheelSensors.filter(sensor => sensor.wheelId.endsWith('0'));
    const trailerSensors = unit.wheelSensors.filter(sensor => !sensor.wheelId.endsWith('0'));

    // Create main unit status
    const mainUnitDiv = document.createElement('div');
    mainUnitDiv.className = 'statusdiagram';
    mainUnitDiv.innerHTML = '<b>Main Unit</b>';
    displayUnitPressureStatusExpanded(mainUnitDiv, unit, mainSensors); 
    mainSensors.forEach(sensor => {
        const sensorDiv = createSensorPressureElement(unit.imei, sensor);
        mainUnitDiv.appendChild(sensorDiv);
    });
    unitStatusDiv.appendChild(mainUnitDiv);

    // Create trailer statuses ordered by last digit of wheelId
    let trailers = getTrailers(trailerSensors);

    for (let trailer in trailers) {
        const trailerDiv = document.createElement('div');
        trailerDiv.className = 'trailer-container statusdiagram';
        trailerDiv.innerHTML = `<b>Trailer ${trailer}</b>`;
        displayUnitPressureStatusExpanded(trailerDiv, unit, trailers[trailer]); 
        //trailers[trailer].forEach(sensor => {
        //    const sensorDiv = createSensorPressureElement(unit.imei, sensor);
        //    trailerDiv.appendChild(sensorDiv);
        //});
        unitStatusDiv.appendChild(trailerDiv);
    }
}

function getTrailers(sensors) {
    let trailers = {};

    sensors.forEach(sensor => {
        let trailerNo = sensor.wheelId.slice(-1);  // Get the last digit
        if (!trailers[trailerNo]) {
            trailers[trailerNo] = [];
        }
        trailers[trailerNo].push(sensor);
    });

    return trailers;
}

export function loadUnitStatusDiagram(unit) {

    unit.wheelSensors = getWheelProperties(unit.wheelSensors);
    unit.spareSensors = getWheelProperties(unit.spareSensors);

    let unitStatusDiagramDiv = document.getElementById(`unitstatusdiagram${unit.imei}`);
    if (unitStatusDiagramDiv != null) {
        while (unitStatusDiagramDiv.hasChildNodes()) {
            unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
        }
    } else {
        unitStatusDiagramDiv = document.createElement('div');
    }
    unitStatusDiagramDiv.id = `unitstatusdiagram${unit.imei}`;
    unitStatusDiagramDiv.className = 'statusdiagram';

    switch (global.selectedMetric) {
        case 'PRESSURE':
            displayUnitPressureStatus(unitStatusDiagramDiv, unit);
            break;
        case 'TEMPERATURE':
            displayUnitTemperatureStatus(unitStatusDiagramDiv, unit);
            break;
        case 'VOLTAGE':
            displayUnitVoltageStatus(unitStatusDiagramDiv, unit);
            break;
        default:
    }

    const metricStatusOptions = document.querySelectorAll('input[name="optmetricstatus"]');
    for (const metricStatusOption of metricStatusOptions) {
        metricStatusOption.addEventListener('change', () => {

            if (unitStatusDiagramDiv != null) {
                while (unitStatusDiagramDiv.hasChildNodes()) {
                    unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
                }
            }
            switch (metricStatusOption.value) {
                case "Pressure":
                    global.selectedMetric = 'PRESSURE';
                    displayUnitPressureStatus(unitStatusDiagramDiv, unit);
                    break;
                case "Temperature":
                    global.selectedMetric = 'TEMPERATURE';
                    displayUnitTemperatureStatus(unitStatusDiagramDiv, unit);
                    break;
                case "Voltage":
                    global.selectedMetric = 'VOLTAGE';
                    displayUnitVoltageStatus(unitStatusDiagramDiv, unit);
                    break;
                default:
            }
        });
    }

    return unitStatusDiagramDiv;
}

export function loadUnitOnlyStatusDiagram(unit) {

    unit.wheelSensors = getWheelProperties(unit.wheelSensors);

    let unitStatusDiagramDiv = document.getElementById(`unitonlystatusdiagram${unit.imei}`);
    if (unitStatusDiagramDiv != null) {
        while (unitStatusDiagramDiv.hasChildNodes()) {
            unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
        }
    } else {
        unitStatusDiagramDiv = document.createElement('div');
    }
    unitStatusDiagramDiv.id = `unitonlystatusdiagram${unit.imei}`;
    unitStatusDiagramDiv.className = 'statusdiagram';

    switch (global.selectedMetric) {
        case 'PRESSURE':
            displayUnitPressureStatus(unitStatusDiagramDiv, unit);
            break;
        case 'TEMPERATURE':
            displayUnitTemperatureStatus(unitStatusDiagramDiv, unit);
            break;
        case 'VOLTAGE':
            displayUnitVoltageStatus(unitStatusDiagramDiv, unit);
            break;
        default:
    }

    const metricStatusOptions = document.querySelectorAll('input[name="optmetricstatus"]');
    for (const metricStatusOption of metricStatusOptions) {
        metricStatusOption.addEventListener('change', () => {
            if (unitStatusDiagramDiv != null) {
                while (unitStatusDiagramDiv.hasChildNodes()) {
                    unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
                }
            }
            switch (metricStatusOption.value) {
                case "Pressure":
                    global.selectedMetric = 'PRESSURE';
                    displayUnitPressureStatus(unitStatusDiagramDiv, unit);
                    break;
                case "Temperature":
                    global.selectedMetric = 'TEMPERATURE';
                    displayUnitTemperatureStatus(unitStatusDiagramDiv, unit);
                    break;
                case "Voltage":
                    global.selectedMetric = 'VOLTAGE';
                    displayUnitVoltageStatus(unitStatusDiagramDiv, unit);
                    break;
                default:
            }
        });
    }

    return unitStatusDiagramDiv;
}

export function loadTrailerStatusDiagram(trailer) {

    trailer.wheelSensors = getWheelProperties(trailer.wheelSensors);

    let unitStatusDiagramDiv = document.getElementById(`trailerstatusdiagram${trailer.wheelSensors[0].repeater.trailerName}`);
    if (unitStatusDiagramDiv != null) {
        while (unitStatusDiagramDiv.hasChildNodes()) {
            unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
        }
    } else {
        unitStatusDiagramDiv = document.createElement('div');
    }
    unitStatusDiagramDiv.id = `trailerstatusdiagram${trailer.wheelSensors[0].repeater.trailerName}`;
    unitStatusDiagramDiv.className = 'statusdiagram';

    switch (global.selectedMetric) {
        case 'PRESSURE':
            displayUnitPressureStatus(unitStatusDiagramDiv, trailer);
            break;
        case 'TEMPERATURE':
            displayUnitTemperatureStatus(unitStatusDiagramDiv, trailer);
            break;
        case 'VOLTAGE':
            displayUnitVoltageStatus(unitStatusDiagramDiv, trailer);
            break;
        default:
    }

    const metricStatusOptions = document.querySelectorAll('input[name="optmetricstatus"]');
    for (const metricStatusOption of metricStatusOptions) {
        metricStatusOption.addEventListener('change', () => {

            if (unitStatusDiagramDiv != null) {
                while (unitStatusDiagramDiv.hasChildNodes()) {
                    unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
                }
            }
            switch (metricStatusOption.value) {
                case "Pressure":
                    global.selectedMetric = 'PRESSURE';
                    displayUnitPressureStatus(unitStatusDiagramDiv, trailer);
                    break;
                case "Temperature":
                    global.selectedMetric = 'TEMPERATURE';
                    displayUnitTemperatureStatus(unitStatusDiagramDiv, trailer);
                    break;
                case "Voltage":
                    global.selectedMetric = 'VOLTAGE';
                    displayUnitVoltageStatus(unitStatusDiagramDiv, trailer);
                    break;
                default:
            }
        });
    }

    return unitStatusDiagramDiv;
}

function displayUnitPressureStatus(unitStatusDiagramDiv, unit) {

    const sensors = [
        ...unit.wheelSensors,
        ...(unit.spareSensors ? unit.spareSensors : []) // Only spread spareSensors if it exists
    ];

    sensors.sort(function (s1, s2) {
        if (parseFloat(s1.currentPressure) > parseFloat(s2.currentPressure)) return 1;
        if (parseFloat(s1.currentPressure) < parseFloat(s2.currentPressure)) return -1;
        return 0;
    });
  
    for (let s = 0; s < sensors.length; s++) {
        if (sensors[s].signal) {
            const sensorStatusDiv = createSensorPressureElement(unit.imei, sensors[s]);
            unitStatusDiagramDiv.appendChild(sensorStatusDiv);
        }
    }

    for (let s = 0; s < sensors.length; s++) {
        if (!sensors[s].signal) {
            const sensorNoSignalDiv = createSensorNoSignalElement(unit.imei, sensors[s], 'pressure');
            unitStatusDiagramDiv.appendChild(sensorNoSignalDiv);
        }
    }
}

function displayUnitPressureStatusExpanded(unitStatusDiagramDiv, unit, sensors) {

    sensors.sort(function (s1, s2) {
        if (parseFloat(s1.currentPressure) > parseFloat(s2.currentPressure)) return 1;
        if (parseFloat(s1.currentPressure) < parseFloat(s2.currentPressure)) return -1;
        return 0;
    });

    for (let s = 0; s < sensors.length; s++) {
        let sensorStatusDiv = createSensorPressureElement(unit.imei, sensors[s]);

        unitStatusDiagramDiv.appendChild(sensorStatusDiv);
    }

    //(unitStatusDiagramDiv, unit);
}

function createSensorNoSignalElements(unitStatusDiagramDiv, sensors) {

    const noSignalSensorCount = sensors.length < unit.sensorCount
        ? unit.sensorCount - unit.sensors.length
        : 0;

    for (let ns = 0; ns < noSignalSensorCount; ns++) {
        let sensorNoSignalStatusDiv = createSensorNoSignalElement();
        unitStatusDiagramDiv.appendChild(sensorNoSignalStatusDiv);
    }
}

function displayUnitTemperatureStatus(unitStatusDiagramDiv, unit) {

    const sensors = [
        ...unit.wheelSensors,
        ...(unit.spareSensors ? unit.spareSensors : []) 
    ];
    sensors.sort(function (s1, s2) {
        if (parseFloat(s1.currentTemperature) < parseFloat(s2.currentTemperature)) return 1;
        if (parseFloat(s1.currentTemperature) > parseFloat(s2.currentTemperature)) return -1;
        return 0;
    });

    for (let s = 0; s < sensors.length; s++) {
        let sensorStatusDiv = createSensorTemperatureElement(unit.imei, sensors[s]);
        unitStatusDiagramDiv.appendChild(sensorStatusDiv);
    }

    for (let s = 0; s < sensors.length; s++) {
        if (!sensors[s].signal) {
            const sensorNoSignalDiv = createSensorNoSignalElement(unit.imei, sensors[s], 'temperature');
            unitStatusDiagramDiv.appendChild(sensorNoSignalDiv);
        }
    }

}

function displayUnitVoltageStatus(unitStatusDiagramDiv, unit) {

    const sensors = [
        ...unit.wheelSensors,
        ...(unit.spareSensors ? unit.spareSensors : [])
    ];

    sensors.sort(function (s1, s2) {
        if (parseFloat(s1.currentVoltage) > parseFloat(s2.currentVoltage)) return 1;
        if (parseFloat(s1.currentVoltage) < parseFloat(s2.currentVoltage)) return -1;
        return 0;
    });

    for (let s = 0; s < sensors.length; s++) {
        let sensorStatusDiv = createSensorVoltageElement(unit.imei, sensors[s]);
        unitStatusDiagramDiv.appendChild(sensorStatusDiv);
    }

    for (let s = 0; s < sensors.length; s++) {
        if (!sensors[s].signal) {
            const sensorNoSignalDiv = createSensorNoSignalElement(unit.imei, sensors[s], 'voltage');
            unitStatusDiagramDiv.appendChild(sensorNoSignalDiv);
        }
    }
}

function createSensorNoSignalElement(imei, sensor, metric) {

    const sensorNoSignalDiv = document.createElement('div');
    sensorNoSignalDiv.id = `u${imei}${metric}${sensor.sensorId}`;
    let sensorvaluesdivclasses = ['sensorvaluesstatus', 'blue'];
    sensorNoSignalDiv.classList.add(...sensorvaluesdivclasses);
    sensorNoSignalDiv.innerText = "S";
    return sensorNoSignalDiv;
}



function createSensorPressureElement(imei, sensor) {

    let sensorPressureDiv = document.createElement('div');
    sensorPressureDiv.id = `u${imei}pressure${sensor.sensorId}`;
    let sensorvaluesdivclasses = ['sensorvaluesstatus', sensor.pressureLabelColour];
    let pressure = parseFloat(sensor.currentPressure).toFixed(1);  
    sensorPressureDiv.classList.add(...sensorvaluesdivclasses);
    sensorPressureDiv.innerText = pressure;
    return sensorPressureDiv;
}

function createSensorTemperatureElement(imei, sensor) {

    let sensorTemperatureDiv = document.createElement('div');
    sensorTemperatureDiv.id = `u${imei}temperature${sensor.sensorId}`;
    let sensorvaluesdivclasses = [];
    let temperature = parseInt(sensor.currentTemperature);
    if (sensor.sensorType === 'wheelblue') {
        sensorvaluesdivclasses = ['sensorvaluesstatus', 'blue'];
        temperature = "S";
    }
    else
        sensorvaluesdivclasses = ['sensorvaluesstatus', sensor.temperatureLabelColour];

    sensorTemperatureDiv.classList.add(...sensorvaluesdivclasses);
    sensorTemperatureDiv.innerText = temperature;
    return sensorTemperatureDiv;
}

function createSensorVoltageElement(imei, sensor) {

    let sensorVoltageDiv = document.createElement('div');
    sensorVoltageDiv.id = `u${imei}voltage${sensor.sensorId}`
    let sensorvaluesdivclasses = [];
    let voltage = parseFloat(sensor.currentVoltage).toFixed(1);
    if (sensor.sensorType === 'wheelblue') {
        sensorvaluesdivclasses = ['sensorvaluesstatus', 'blue'];
        voltage = "S";
    }
    else
        sensorvaluesdivclasses = ['sensorvaluesstatus', sensor.voltageLabelColour];

    sensorVoltageDiv.classList.add(...sensorvaluesdivclasses);
    sensorVoltageDiv.innerText = voltage;
    return sensorVoltageDiv;
}

