/// <reference path="auth.js" />

import { validateToken } from './auth';
import { formatCoordinate } from "../utilities";

export async function updateAxleRanges(valuesOutOfRangeParams) {
    try {
        const token = localStorage.getItem('token');

        const requestBody = {
            axleNumber: valuesOutOfRangeParams.axleNoUnitNo,
            recommendedPressure: valuesOutOfRangeParams.recommendedPressure,
            pressureDeviationAllowedLow: valuesOutOfRangeParams.pressureDeviationAllowedLow,
            pressureDeviationAllowedHigh: valuesOutOfRangeParams.pressureDeviationAllowedHigh,
            minPressure: valuesOutOfRangeParams.minPressure,
            maxPressure: valuesOutOfRangeParams.maxPressure,
            maxTemperature: valuesOutOfRangeParams.maxTemperature,
            minVoltage: valuesOutOfRangeParams.minVoltage
        };

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${valuesOutOfRangeParams.customerId}/unit/${valuesOutOfRangeParams.imei}/ranges`;

        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error("Error updating axle ranges:", e);
    }
}

export async function updateNotificationSettings(unit) {
    try {
        const token = localStorage.getItem('token');

        const requestBody = { notificationSettings: unit.notificationSettings };

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${unit.customerId}/unit/${unit.imei}`;

        const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating notification settings:", e);
    }
}

export async function updateDriver(unit) {
    try {
        const token = localStorage.getItem('token');

        const requestBody = { driverName: unit.driverName, driverContactNumber: unit.driverContactNumber };

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${unit.customerId}/unit/${unit.imei}`;

        const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating driver info:", e);
    }
}

export async function getCurrentLocation(imei, timestamp) {
    try {
        const results = await getLocationCoordinates(imei, timestamp);

        if (results.data) {
            const data = results.data;
            const currentLocation = {
                speed: data.speed,
                longitude: formatCoordinate(data.longitude),
                latitude: formatCoordinate(data.latitude)
            };
            return currentLocation;
        } else {
            throw new Error('No data available');
        }
    } catch (error) {
        console.log('No location data is available!');
        throw error;
    }
}

export async function getLocationCoordinates(imei, timestamp) {

    let apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/location?timestamp=${timestamp}`;
    try {
        const response = await fetch(apiUrl);
        return await response.json();
    } catch (error) {
        console.error('Error fetching location data:', error);
        throw error;
    }
}

export async function getUnitData() {
    try {

        const token = localStorage.getItem('token');        
        //let token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImN1c3RvbWVySWQiOiI2NmIzZDZjNmFhZWEzZTY0MzgxYTNhZTAiLCJlbWFpbCI6InByZXNzdXJldHJhY2tAYWRtaW4uY29tIn0sImlhdCI6MTcyMzYzMTgxMywiZXhwIjoxNzU1MTY3ODEzLCJhdWQiOiJodHRwczovL2Nsb3VkLWNvbm5lY3Rvci1kYXNoYm9hcmQucmVzdGlvbGFicy5jb20iLCJpc3MiOiJjbG91ZC1jb25uZWN0b3ItZGFzaGJvYXJkLnJlc3Rpb2xhYnMuY29tIn0.6BDWhCUaVS5VgPMQ3I4TDyvHvN0ANrwqvLBOYUz57c4`;

        // Get customerId from localStorage
        let customerId = localStorage.getItem('customer');  
        if (!customerId) {
            const customerToken = validateToken(token);
            customerId = customerToken?.decodedToken?.user?.customerId;
        }        


        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${customerId}/unit?inclChild=true`;
        //const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/66b3d6c6aaea3e64381a3ae0/unit?inclChild=true`;
        //?inclChild=true
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error('getunitdataJS: getUnitData - ERROR ', e);
    }
}

//function getUnitSensors(sensorParameters, unitSensors, sensorValueRanges) {

//    let sensors = [];
//    for (let s in sensorParameters) {
//        if (s.slice(0, 1) === 'p') {
//            const sensor = {};
//            const currentUnixTimestamp = Math.floor(Date.now() / 1000);
//            const timestamp = sensorParameters[s]?.at;
//            const maxDifferenceSeconds = 20 * 60; //20 minutes
//            const timeDifference = currentUnixTimestamp - timestamp;
//            if (timeDifference <= maxDifferenceSeconds) {
//                sensor.wheelId = s.slice(1);
//                sensor.sensorId = sensorParameters[`i${s.slice(1)}`]?.v || "";
//                sensor.unitNumber = s.slice(-1);
//                sensor.axle = s.slice(2, 4);
//                sensor.currentPressure = parseFloat(sensorParameters[s]?.v || 0).toFixed(2);
//                sensor.currentTemperature = parseInt(sensorParameters[`t${s.slice(1)}`]?.v || 0);
//                sensor.currentVoltage = parseFloat(sensorParameters[`v${s.slice(1)}`]?.v || 0).toFixed(2);
//                sensor.rotationValue = parseFloat(sensorParameters[`r${s.slice(1)}`]?.v || 0).toFixed(2);

//                for (let us in unitSensors) {
//                    if (s === unitSensors[us].n.toLowerCase()) {
//                        const tyreIdMatch = unitSensors[us]?.d.match(/Tyre Id: ([\w\d]+);/);
//                        sensor.tyreId = tyreIdMatch ? tyreIdMatch[1] : unitSensors[s]?.d || "undefined";

//                        const treadDepthMatch = unitSensors[us]?.d.match(/Tread Depth: ([\d.]+ mm)/);
//                        sensor.treadDepth = treadDepthMatch ? treadDepthMatch[1] : "undefined";
//                    }
//                }

//                for (let vr in sensorValueRanges) {
//                    if (s.slice(1) === sensorValueRanges[vr].n.slice(-4)) {
//                        const sensorMetric = sensorValueRanges[vr].n.slice(-5).slice(0, 1);
//                        switch (sensorMetric) {
//                            case 'P':
//                                sensor.minPressure = sensorValueRanges[vr].trg_p.lower_bound;
//                                sensor.maxPressure = sensorValueRanges[vr].trg_p.upper_bound;
//                                break;
//                            case 'T':
//                                sensor.maxTemperature = sensorValueRanges[vr].trg_p.upper_bound;
//                                break;
//                            case 'V':
//                                sensor.minVoltageValue = sensorValueRanges[vr].trg_p.lower_bound;
//                                break;
//                        }
//                    }
//                }
//                sensors.push(sensor);
//            }
//        }
//    }
//    return sensors;
//}

function getLocationSensors(sensorParameters) {

    let sensors = [];
    for (let s in sensorParameters) {
        if (s.slice(0, 2) === 'il' || s.slice(0, 2) === 'ir') {
            const currentUnixTimestamp = Math.floor(Date.now() / 1000);
            const timestamp = sensorParameters[s]?.at;
            const maxDifferenceSeconds = 20 * 60; //20 minutes
            const timeDifference = currentUnixTimestamp - timestamp;
            if (timeDifference <= maxDifferenceSeconds) {
                const sensor = {};
                sensor.wheelId = s.slice(1);
                sensor.sensorId = sensorParameters[`i${s.slice(1)}`]?.v || "";
                sensor.unitNumber = s.slice(-1);
                sensor.axle = s.slice(2, 4);
                sensor.axleUnit = s.slice(2);
                sensor.wheelConfig = sensorParameters[`w${s.slice(1)}`]?.v; //|| -1
                sensor.voltage = sensorParameters[`v${s.slice(1)}`]?.v || 0;
                sensors.push(sensor);
            }
        }
    }
    return sensors;
}

