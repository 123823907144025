import { WebSocketService } from "../data/services/websocketservice";
import { logMessage, formatCoordinate, getAddressFromCoordinates, formatTime } from "../utilities.js";
import { getUnitData } from "../data/unitdata";
import { getUnitGroupName } from "./views/unitgroups";
import { createUnitsListContainer } from "./views/unitslist";
import { displayUnitInfo, showUnitOnMap, openUnitConfigForm } from "./views/unitinfo";
import { getUnits, updateUnitMetrics } from "./views/units";
import { createDiagramView, displaySensorUpdates } from "./views/unitdiagrams/unitdiagrams";

const wsService = new WebSocketService();

export async function displayDetailPanes(unit) {
    const units = await getUnits();
    const selectedUnit = units.find(u => u.imei === unit.imei); //unit's fresh data from getUnits
    if (selectedUnit) global.selectedUnit = selectedUnit;
    getSensorUpdates(); //selectedUnit not always available, have to rely on global
    setDetailInterval(selectedUnit);
    displayUnitDetail(units, selectedUnit);   
}

export async function setDetailInterval(unit) {

    if (global.detailIntervalId) {
        clearInterval(global.detailIntervalId);
    }

    global.detailIntervalId = setInterval(async () => {
        const units = await getUnits();
        const selectedUnit = units.find(u => u.imei === unit.imei); //unit's fresh data from getUnits
        updateDisplayedUnit(selectedUnit).catch((error) => {
            //logMessage(`${error} Error detail in console`);
            console.log("detailpageJS: setDetailInterval - ERROR " + error + ")");
            console.error(error);
        });
    }, 300000); //5 minutes: 1 minute = 60,000 milliseconds; 5 minutes = 5 * 60000 = 300000 milliseconds;  
}

export function getSensorUpdates() {
    try {

        stopSensorUpdates();

        wsService.connect('sensorUpdates');

        let lastReceivedMessageId = null;
        wsService.addEventListener('message', (event) => {
            const data = event.detail;
            // Ensuring messages are not duplicated
            if (data && data.imei === global.selectedUnit.imei) {
                const dataId = `${data.imei}${data.wheelSensorUID}${data.payloadTS}`
                if (dataId !== lastReceivedMessageId) { // Assuming 'id' or a unique field is available
                    lastReceivedMessageId = dataId;
                    console.log('Received message for detail page:', data);
                    displaySensorUpdates(global.selectedUnit, data);
                } else {
                    console.log('Duplicate message ignored:', data);
                }
            }
        });

        window.addEventListener('unload', () => {
            wsService.disconnect();
        });
    } catch (e) {
        console.error('detailpageJS (getSensorUpdates): WebSocket - ERROR: ', e);
    }
}

export function stopSensorUpdates() {
    try {

        wsService.removeListeners(); // Remove all event listeners
        wsService.disconnect(); // Ensure any previous connection is closed        

    } catch (e) {
        console.error('detailpageJS (stopSensorUpdates): WebSocket - ERROR: ', e);
    }
}

export async function updateDisplayedUnit(selectedUnit) {
    
    if (document.getElementById(`unitinfotimeupdated${selectedUnit.imei}`)) {        
        document.getElementById(`unitinfotimeupdated${selectedUnit.imei}`).innerText = `Time Updated: ${selectedUnit.timeUpdated}`;
        const address = await getAddressFromCoordinates(selectedUnit.location.latitude, selectedUnit.location.longitude);
        document.getElementById(`unitinfolocation${selectedUnit.imei}`).innerText = `Location: ${address}`;
        document.getElementById(`unitinfospeed${selectedUnit.imei}`).innerText = `Speed: ${selectedUnit.location.speed} km/h`;
        const location = {
            longitude: formatCoordinate(selectedUnit.location.longitude),
            latitude: formatCoordinate(selectedUnit.location.latitude)
        };
        showUnitOnMap(location);
    }

    const sensors = [...selectedUnit.wheelSensors, ...selectedUnit.spareSensors]
    for (let s = 0; s < sensors.length; s++) {

        const now = new Date();
        const currentTimeFormatted =
            `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;

        const payloadTS = sensors[s]?.payloadTS ? new Date(sensors[s].payloadTS) : null;

        // Format payloadTS in the same way as currentTimeFormatted
        const payloadTimeFormatted = payloadTS
            ? `${payloadTS.getHours().toString().padStart(2, '0')}:${payloadTS.getMinutes().toString().padStart(2, '0')}:${payloadTS.getSeconds().toString().padStart(2, '0')}`
            : currentTimeFormatted;

        if (document.getElementById(`sv${sensors[s].sensorId}timeupdated`)) {
            document.getElementById(`sv${sensors[s].sensorId}timeupdated`).innerText = payloadTimeFormatted;
        }
        if (document.getElementById(`sv${sensors[s].sensorId}sensorid`)) {
            document.getElementById(`sv${sensors[s].sensorId}sensorid`).innerText = sensors[s].sensorId;
        }
        if (document.getElementById(`sv${sensors[s].sensorId}tyreid`)) {
            document.getElementById(`sv${sensors[s].sensorId}tyreid`).innerText = `${sensors[s].externalTyreId}`;
        }
        if (document.getElementById(`sv${sensors[s].sensorId}treaddepth`)) {
            document.getElementById(`sv${sensors[s].sensorId}treaddepth`).innerText = `${sensors[s].tyreTreadDepth}`;
        }
        //if (document.getElementById(`sv${sensors[s].sensorId}signalstrength`)) {
        //    document.getElementById(`sv${sensors[s].sensorId}signalstrength`).innerText = `${sensors[s].signalStrength}`;
        //}
        if (document.getElementById(`sv${sensors[s].sensorId}rotation`)) {
            document.getElementById(`sv${sensors[s].sensorId}rotation`).innerText = `${sensors[s].currentRotation}`;
        }
    }
}

function removeMainPane() {

    let unitsListMain = document.getElementById('unitslistmain');
    while (unitsListMain.hasChildNodes()) {
        unitsListMain.removeChild(unitsListMain.lastChild);
    }
    unitsListMain.style.display = "none";

    let mainPane = document.getElementById('mainpane');
    mainPane.style.display = "none";
}

export function displayUnitDetail(units, selectedUnit) {

    document.body.style.cursor = 'wait';
    displayUnitsList(units, selectedUnit);    
    displayDiagramViews(selectedUnit);       
    document.getElementById('detailspane').style.display = 'block';
    global.showMainPane = false;
    removeMainPane();
    document.body.style.cursor = 'default';    
}

function displayUnitsList(units, selectedUnit) {

    try {
        let unitsListDiv = document.getElementById('unitslist');
        while (unitsListDiv.hasChildNodes()) {
            unitsListDiv.removeChild(unitsListDiv.lastChild);
        }

        let unitsListFragment = document.createDocumentFragment();

        let displayUnits = [...units]; 
        if (!global.mainUnitsListSelected) {
            displayUnits = global.selectedGroupUnits;
            let groupNameDiv = document.createElement('div');
            let selectedGroupName = getUnitGroupName();
            groupNameDiv.id = selectedGroupName;
            let groupNameDivClasses = ['unitgroup', 'unitgroupcenter'];
            groupNameDiv.classList.add(...groupNameDivClasses);

            groupNameDiv.innerHTML = selectedGroupName;
            unitsListFragment.appendChild(groupNameDiv);

            groupNameDiv.onclick = (e) => {
                e.stopImmediatePropagation();
                global.mainUnitsListSelected = false;
                displayMainPane(units);
            };
        }

        let unitsListContainerDiv = createUnitsListContainer();
        unitsListFragment.appendChild(unitsListContainerDiv);
        unitsListDiv.appendChild(unitsListFragment);

        let selectedUnitInfoDisplayed = true;
        console.log('Length of displayUnits:', displayUnits.length);
        for (let i = 0; i < displayUnits.length; i++) {

            let u = displayUnits[i];

            //const isGroupUnit = false;
            //let unitDiv = createUnitContainer(u, uwt, isGroupUnit);
            let unitDiv = createUnitContainer(u);
            unitsListContainerDiv.appendChild(unitDiv);
            if (selectedUnit && selectedUnit.imei === u.imei) {
                unitDiv.classList.add('selectedunit');
                unitDiv.selected = true;  
                displayUnitInfo(selectedUnit);
            }

            unitDiv.onclick = async function (e) {
                e.stopImmediatePropagation();
                
                const selectedUnitId = u.imei;


                // Get the div for the currently selected unit's info
                let currentUnitInfoPaneDiv = document.getElementById(`unitinfo${selectedUnitId}`);
                console.log("Selected Unit:", selectedUnitId, "Previous Unit:", global.selectedUnitId);
                // If there is already a selected unit and it's different from the current unit
                if (global.selectedUnitId && global.selectedUnitId !== selectedUnitId) {
                    // Close the previously selected unit's info pane
                    let prevUnitInfoPaneDiv = document.getElementById(`unitinfo${global.selectedUnitId}`);
                    if (prevUnitInfoPaneDiv && prevUnitInfoPaneDiv.hasChildNodes()) {
                        while (prevUnitInfoPaneDiv.hasChildNodes()) {
                            prevUnitInfoPaneDiv.removeChild(prevUnitInfoPaneDiv.lastChild);
                        }
                    }
                }

                // Toggle current unit's info: if open, close it; if closed, open it
                if (currentUnitInfoPaneDiv && currentUnitInfoPaneDiv.hasChildNodes()) {
                    // Close the current unit's info pane
                    while (currentUnitInfoPaneDiv.hasChildNodes()) {
                        currentUnitInfoPaneDiv.removeChild(currentUnitInfoPaneDiv.lastChild);
                    }
                } else {
                    // Open the current unit's info pane by displaying its details
                    displayUnitInfo(u);
                }

                // Update the global state with the currently selected unit's ID
                global.selectedUnitId = selectedUnitId;
                global.selectedUnit = u;

                // Ensure the 'selectedunit' class is only applied to the current selected unit
                let selectedUnitContainers = document.getElementsByClassName('selectedunit');
                if (selectedUnitContainers.length > 0) {
                    selectedUnitContainers[0].classList.remove('selectedunit');
                }

                // Mark the current unit as the selected unit
                unitDiv.classList.add('selectedunit');

                const units = await getUnits();
                selectedUnit = units.find(unit => unit.imei === u.imei); //unit's fresh data from getUnits

                // Additional logic for displaying diagrams or other unit-related data
                displayDiagramViews(selectedUnit);
                updateDisplayedUnit(selectedUnit)
                getSensorUpdates(); //selectedUnit not always available, have to rely on global
                setDetailInterval(selectedUnit);
            };
        }
        
        $("#loadingBox").modal('hide');
        document.body.style.cursor = 'default';
    }
    catch (e) {
        console.error(`detailpageJS - displayUnitsList: ${e}`);
        return;
    }
}

export function displayDiagramViews(unit) {
    createDiagramView(unit, 'WHEELSDIAGRAM', 'rightpane');
    if (!global.sensorValuesDiagramDateSelected) {
        createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
    }
}

function createUnitContainer(unit) {

    try {

        let unitDiv = document.createElement('div');
        //if (isGroupUnit) unitDiv.id = `groupunit${unit.imei}`
        //else unitDiv.id = unit.imei;
        unitDiv.id = unit.imei;
        unitDiv.className = 'unit';
        unitDiv.selected = false;
        //unitDiv.classList.add('selectunit');

        const unitHeaderDiv = document.createElement('div');
        unitHeaderDiv.className = 'unitheader';
        unitDiv.appendChild(unitHeaderDiv);

        const listIcon = document.createElement('svg');
        listIcon.className = 'trucktrailer-listicon';
        unitHeaderDiv.appendChild(listIcon);

        const text = `${unit.name || unit.imei}`;
        let t = document.createTextNode(text);
        unitHeaderDiv.appendChild(t);

        //Metric flags
        const metricFlagsSpan = document.createElement("span");
        metricFlagsSpan.className = "listspanicons";
        unitHeaderDiv.appendChild(metricFlagsSpan);        

        if (unit.yellowFlags > 0) {
            let vspan = document.createElement("span");
            let vtxt = document.createTextNode("V");
            vspan.className += "yellowflag";
            vspan.appendChild(vtxt);
            metricFlagsSpan.appendChild(vspan);
        }
        if (unit.orangeFlags > 0) {
            let tspan = document.createElement("span");
            let ttxt = document.createTextNode("T");
            tspan.className += "orangeflag";
            tspan.appendChild(ttxt);
            metricFlagsSpan.appendChild(tspan);
        }
        if (unit.blinkFlags > 0) {
            let pspan = document.createElement("span");
            let ptxt = document.createTextNode("P");
            pspan.className += "blinkflag";
            pspan.appendChild(ptxt);
            metricFlagsSpan.appendChild(pspan);
        } else {
            if (unit.redFlags > 0) {
                let pspan = document.createElement("span");
                let ptxt = document.createTextNode("P");
                pspan.className += "redflag";
                pspan.appendChild(ptxt);
                metricFlagsSpan.appendChild(pspan);
            }
        }
        if (unit.purpleFlags > 0) {
            let pspan = document.createElement("span");
            let ptxt = document.createTextNode("P");
            pspan.className += "purpleflag";
            pspan.appendChild(ptxt);
            metricFlagsSpan.appendChild(pspan);
        }
        if (unit.grayFlags > 0) {
            let ospan = document.createElement("span");
            let otxt = document.createTextNode("S");
            ospan.className += "blueflag";
            ospan.appendChild(otxt);
            metricFlagsSpan.appendChild(ospan);
        }

        const unitEditBtn = document.createElement("button");
        unitEditBtn.classList.add('btn-primary-inline');

        // Set button text
        unitEditBtn.textContent = 'Edit';
        unitEditBtn.id = `edit${unit.imei}btn`;
        metricFlagsSpan.appendChild(unitEditBtn);
        unitEditBtn.onclick = (e) => {
            e.stopImmediatePropagation();
            openUnitConfigForm(unit.imei);
        };


        //if (unit.unitTrailers.length > 0) {

        //    //unit.unitTrailers.sort(function (trl1, trl2) {
        //    //    if (parseInt(trl1.linkedOrder) > parseInt(trl2.linkedOrder)) return 1;
        //    //    if (parseInt(trl1.linkedOrder) < parseInt(trl2.linkedOrder)) return -1;
        //    //    return 0;
        //    //});

        //    for (let t = 0; t < unit.unitTrailers.length; t++) {

        //        let unitLinkedDiv = document.createElement('div');
        //        unitLinkedDiv.className = 'unitheader';
        //        unitDiv.appendChild(unitLinkedDiv);

        //        let stringlink = '\xa0 \xa0 \xa0 \xa0 -o- \xa0 '; //1f517 \u{156e}\u{156d} \u{2ad8} \u{2d3c}  \u{2ad8}
        //        let link = document.createTextNode(stringlink);
        //        unitLinkedDiv.appendChild(link);

        //        let trailericon = unit.unitTrailers[t].unitIcon; // get unit Icon url
        //        let trailerimg = document.createElement('img');
        //        trailerimg.src = trailericon;
        //        unitLinkedDiv.appendChild(trailerimg);

        //        let trailertext = " " + unit.unitTrailers[t].name;
        //        let trailert = document.createTextNode(trailertext);
        //        unitLinkedDiv.appendChild(trailert);
        //    }
        //}        
        return unitDiv;
    } catch (e) {
        console.log("unitgroupjs - createUnitContainer: " + e.error);
        return;
    }
}
