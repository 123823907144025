export class WebSocketService extends EventTarget {
    constructor() {
        super();
        this.socket = null;
    }

    connect(roomName) {
        //console.log(`connect() ${roomName} Time: ${Date.now()}` );

        this.socket = io('https://cloud-connector-dashboard.restiolabs.com', {
            withCredentials: true,
            extraHeaders: {
                'room-name': roomName
            }
        });

        this.socket.on('connect', () => {
            console.log(`Connected to WebSocket server. Time: ${Date.now()}`);
        });

        this.socket.on('message', (data) => {
            console.log(`Received message: ${data} Time: ${Date.now()}`);
            const event = new CustomEvent('message', { detail: data });
            console.log(`Dispatching event: ${event} Time: ${Date.now()}`);
            this.dispatchEvent(event);
        });

        this.socket.on('disconnect', () => {
            console.log(`Disconnected from WebSocket server. Time: ${Date.now()}`);
            this.socket = null;
        });

        this.socket.on('connect_error', (error) => {
            console.error(`WebSocket error: ${error} Time: ${Date.now()}`);
        });
    }

    removeListeners() {
        if (this.socket) {
            this.socket.off('message');
        }
        this.removeEventListener('message', null);
    }

    disconnect() {
        if (this.socket) {
            console.log(`disconnect() time: ${Date.now()}`);
            this.socket.disconnect();
        }
    }
}