export function createUnitsListContainer() {

    let unitsListContainerDiv = document.createElement('div');
    unitsListContainerDiv.id = 'unitslistcontainer';
    unitsListContainerDiv.className = 'unitslistcontainer';
    if (!global.mainUnitsListSelected) {
        unitsListContainerDiv.classList.add('unitsgrouplistcontainer');
    }

    return unitsListContainerDiv;
}