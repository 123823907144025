/* Psalm 127:1-2
As die HERE die huis nie bou nie, tevergeefs werk die wat daaraan bou;
as die HERE die stad nie bewaar nie, tevergeefs waak die wagter.
Tevergeefs dat julle vroeg opstaan, laat opbly, brood van smarte eet —
net so goed gee Hy dit aan sy beminde in die slaap! */

/*“Walking on water and developing software from a specification are easy if both are frozen”*/

//logsection/window
//log: function (msg) {
//    var $log = $('#log'),
//        $log_cont = $('#log_cont');
//    $log.append('<tr><td>' + msg + '</td></tr>');
//    $log_cont.animate({
//        scrollTop: $log_cont[0].scrollHeight
//    }, 300);
//};
//});

import regeneratorRuntime from "regenerator-runtime"; //webpack

import { SPARE_AXLE } from "./global";
import { sendLoginMail, validateToken } from "./data/auth";
import { logMessage } from "./utilities";
import { setMainInterval } from "./components/mainpage";
import { getUnits } from "./components/views/units";
import { openUsersForm } from "./components/views/users";

global.unitsGroupsEventsRegistered = false

global.showMainPane = true;
global.mainUnitsListSelected = true;

global.selectedUnitId = 0;
global.selectedUnit = {};
global.units = [];
global.unitGroups = [];
global.selectedGroupUnits = [];
global.selectedGroupId = 0;
global.selectedMetric = "PRESSURE";

global.pressureMetricAttributesUpdated = false;

global.sensorValuesChartMessages = [];
global.isProcessingChartMessages = false;

global.sensorValuesDiagramDateSelected = false;
global.selectedDateTime = "";

global.mainIntervalId = null;
global.detailIntervalId = null;

// DOMContentLoaded event listener for both initialization and token verification
document.addEventListener('DOMContentLoaded', async function () {
    const token = localStorage.getItem('token');
    const customerToken = validateToken(token);
    if (customerToken?.valid) {
        const loginForm = document.getElementById('login');
        if (loginForm) loginForm.style.display = 'none';
        const userName = customerToken?.decodedToken?.user?.email
        document.getElementById('usernametoppane').innerText = userName;
        const version = updateApp(); //toppane
        showSplash(userName, version);        
        await loadApp();
    } else {
        hideAppSections();
        const siteType = getSiteType();
        await handleAuthentication(siteType);  // Updated function name
    }
});

const getSiteType = () => {
    const currentUrl = window.location.href;
    const host = window.location.host.toLowerCase();     
    const pathname = window.location.pathname.toLowerCase(); 
    return host.includes("tpm.pressuretrack") ? "tpm-pressuretrack" : "tpm-test";
}

// Utility function to hide app sections when not logged in
function hideAppSections() {
    const sectionsToHide = ['container', 'mainpane', 'detailspane'];
    sectionsToHide.forEach(id => {
        const element = document.getElementById(id);
        if (element) element.style.display = 'none';
    });
}

// Handle both login and verification process
async function handleAuthentication(siteType) {
    const urlParams = new URLSearchParams(window.location.search);
    const verificationToken = urlParams.get('token');

    if (verificationToken) {
        await handleVerification(verificationToken);
    } else {
        toggleVerifySection(false);  // Show login if there's no token
        await login(siteType);  // Login functionality called here if no verification token is present
    }
}

// Function to handle token extraction and app loading
async function handleVerification(verificationToken) {
    toggleVerifySection(true);

    try {
        const response = await fetch('https://cloud-connector-dashboard.restiolabs.com/auth/verify-passwordless', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ verificationToken })
        });

        const data = await response.json();
        const statusElement = document.getElementById('status');

        if (data.jwtToken) {
            const customerToken = validateToken(data.jwtToken);

            if (statusElement) statusElement.textContent = 'Verification successful! Redirecting...';
            setTimeout(() => {
                // Hide the login form
                const loginForm = document.getElementById('login');
                if (loginForm) loginForm.style.display = 'none';

                // Get app version and show splash
                const version = updateApp();
                showSplash(customerToken.decodedToken.user.email, version);

                // Load the app
                loadApp();
            }, 1500);
        } else {
            if (statusElement) statusElement.textContent = 'Verification failed. Please try again.';
        }
    } catch (error) {
        displayError('Error during verification. Please try again.');
    }
}

// Show or hide sections based on verification token
function toggleVerifySection(showVerify) {
    const emailSentSection = document.getElementById('email-sent');
    const verifySection = document.getElementById('verify');

    //if (emailSentSection) emailSentSection.style.display = showVerify ? 'none' : 'block';
    if (verifySection) verifySection.style.display = showVerify ? 'block' : 'none';
}

async function login(siteType) {
    try {
        document.getElementById('login').addEventListener('submit', async function (event) {
            event.preventDefault();

            const emailAddress = document.getElementById('username').value;
            const emailSent = await sendLoginMail(emailAddress, siteType);

            if (emailSent.success) {
                const loginForm = document.getElementById('login');
                if (loginForm) loginForm.style.display = 'none';
                const emailSentSection = document.getElementById('email-sent');
                if (emailSentSection) emailSentSection.style.display = 'block';
            } else {
                alert('Something went wrong. Try again!');
            }
        });
    } catch (e) {
        displayError('Login failed. Please try again.');
    }
}

// Load the app after a successful login or verification
async function loadApp() {
    try {
        const users = document.getElementById('users');
        users.onclick = (e) => {
            e.stopImmediatePropagation();
            openUsersForm();
        };
        global.showMainPane = true;
        const units = await getUnits();
        setMainInterval(units);
        const container = document.getElementById('container');
        if (container) container.style.display = 'block';

    } catch (error) {
        console.error('Error loading app:', error);
        displayError('Failed to load the application. Please try again.');
    }
}

// Generic function to show errors
function displayError(message) {
    const statusElement = document.getElementById('status');
    if (statusElement) {
        statusElement.textContent = message;
    }
}

const showSplash = (userName, version) => {

    const splashContainer = document.getElementById('splashcontainer');
    splashContainer.className = 'container-splash';
    while (splashContainer.hasChildNodes()) {
        splashContainer.removeChild(splashContainer.lastChild);
    }
    const splashMainFragment = document.createDocumentFragment();

    const splash = document.createElement('div');
    splash.id = 'splash';

    const splashIcon = document.createElement('div');
    splashIcon.id = 'splashicon';
    splashIcon.className = 'splashicon';
    splash.appendChild(splashIcon);

    const splashLogo = document.createElement('div');
    splashLogo.id = 'splashlogo';
    splashLogo.className = 'splashlogo';
    splash.appendChild(splashLogo);

    const splashLabel = document.createElement('div');
    splashLabel.id = 'splashlabel';
    splashLabel.className = 'splashlabel';
    splashLabel.innerText = `If you can't measure it, you can't manage it.`;
    splash.appendChild(splashLabel);

    const userNameSplash = document.createElement('div');
    userNameSplash.id = 'username';
    userNameSplash.className = 'username';
    userNameSplash.innerText = userName;
    splash.appendChild(userNameSplash);

    const versionSplash = document.createElement('div');
    versionSplash.id = 'splashversion';
    versionSplash.className = 'version';
    versionSplash.innerText = `version ${version}`;
    splash.appendChild(versionSplash);

    splashMainFragment.appendChild(splash);
    splashContainer.appendChild(splashMainFragment);
}

function updateApp() {

    const currentVersion = "2.0.13"; 
    const previousVersion = localStorage.getItem("version");

    if (previousVersion) {
        if (`PressureTrack v${currentVersion}` !== previousVersion) {
            localStorage.setItem("version", `PressureTrack v${currentVersion}`);
            location.reload(true);
        }
    } else {
        localStorage.setItem("version", `PressureTrack v${currentVersion}`);
            location.reload(true);
    }

    document.getElementById('version').innerText = `version ${currentVersion}`;

    return currentVersion;
}

function logout() {
    var sess = wialon.core.Session.getInstance();
    if (sess && sess.getId()) {
        sess.logout(function () {

        });
    }
    //logMessage("");
    $(".leftpane").empty();
    $(".middlepane").empty(); //rather deselect
    $('.rightpane').empty()
}

$.fn.overlayMask = function (action) {
    var mask = this.find('.overlay-mask');
    // Create the required mask
    if (!mask.length) {
        this.css({
            position: 'relative'
        });
        mask = $('<div class="overlay-mask"></div>');
        mask.css({
            position: 'absolute',
            display: 'inline-block',
            width: '100%',
            height: '120%',
            top: '0px',
            left: '0px',
            background: 'rgb(0, 0, 0)',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100
        }).appendTo(this);
    }

    // Act based on params
    if (!action || action === 'show') {
        mask.show();
    } else if (action === 'hide') {
        mask.hide();
    }
    return this;
}

