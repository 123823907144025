
import { cloneObject } from "../../../utilities";
import { updateAxleRanges, updateNotificationSettings } from "../../../data/unitdata";
import { getWheelProperties, getTyreArrayIndex } from "../units";

import { deepCopy } from "../../../utilities";
import { displayUpdatedData } from "../../detailpage";
import { createRepeaterDiagram } from "./repeaterdiagram";
import { getResourceNotifications, getNotificationData, updateUnitNotifications } from "../../../notifications";

import { SPARE_AXLE } from "../../../global";

var gNotificationCounterCheck = 0;
var gNotificationsIds = [];

export function createWheelsDiagramButtonsElement(unit) {

    let schematicDiagramButtonDivClasses = ['row', 'pane2buttons'];
    let schematicDiagramButtonDiv = document.createElement('div');
    schematicDiagramButtonDiv.classList.add(...schematicDiagramButtonDivClasses);

    let schematicDiagramButtonClasses = ['btn', 'btn-primary', 'panebutton', 'flex-child'];

    let notificationsButton = document.createElement('button');
    notificationsButton.classList.add(...schematicDiagramButtonClasses);
    notificationsButton.type = 'button';
    notificationsButton.title = 'SET NOTIFICATIONS';

    const notificationsIcon = document.createElement('svg');
    notificationsIcon.className = 'buttonicon notificationbluesvg';

    const notificationsButtonText = document.createElement('span');
    //wheelsDiagramButtonText.className = 'buttontext'; //class="buttontext buttontext-disabled"
    notificationsButtonText.innerText = 'Notifications'; //'\xa0Unit'

    //let notificationsImg = document.createElement('img');
    //notificationsImg.src = "images/icons8-notification-24.png";
    //notificationsImg.src = "/src/components/unitDiagrams/images/icons8-notification-24.png";

    notificationsButton.appendChild(notificationsIcon);
    notificationsButton.appendChild(notificationsButtonText);

    notificationsButton.onclick = function (e) {
        openNotificationsForm(unit);
        e.stopImmediatePropagation();
    };

    schematicDiagramButtonDiv.appendChild(notificationsButton);

    let wheelsDiagramButton = document.createElement('button');
    wheelsDiagramButton.classList.add(...schematicDiagramButtonClasses);
    wheelsDiagramButton.type = 'button';
    wheelsDiagramButton.title = 'VIEW REPEATER DIAGRAM';

    const sensorIcon = document.createElement('svg');
    sensorIcon.className = 'buttonicon sensorbluesvg';

    const wheelsDiagramButtonText = document.createElement('span');
    wheelsDiagramButtonText.innerText = 'Location Sensors';
    wheelsDiagramButton.appendChild(sensorIcon);
    wheelsDiagramButton.appendChild(wheelsDiagramButtonText);

    wheelsDiagramButton.onclick = function (e) {
        e.stopImmediatePropagation();

        createRepeaterDiagram(unit);
    };

    //Marius
    //wheelsDiagramButton.onclick = async function () {
    //    await loadAllSensorValuesMessages(unit);
    //};

    schematicDiagramButtonDiv.appendChild(wheelsDiagramButton);

    return schematicDiagramButtonDiv;
}

function createUnitElement() {

    let trailerDiv = document.createElement('div');
    trailerDiv.className = 'trailer';

    return trailerDiv;
}

//function createSpareTyreAxleElements(unit, axleSensors) {

//    let isAxleActive = getAxleActiveStatus(unit.axleRanges, `17${axleSensors[0].slice(-1)}`);

//    let tyreSpareAxleDiv = document.createElement('div');
//    tyreSpareAxleDiv.className = 'diagramaxle';

//    let tyredivclasses = ['chartsparewheel', 'wheelinactive'];
//    let sensorType1 = 'wheelinactive';
//    let sensorType2 = 'wheelinactive';

//    if (axleSensors.length > 0) {

//        for (let st = 0; st < axleSensors.length; st++) {
//            let spare = axleSensors[st].wheelId.slice(1, 2);

//            switch (spare) {
//                case '1':
//                    //gSnipImage ? sensorType1 = axleSensors.filter(as => parseInt(as.wheelId.slice(1, 2)) === 1)[0].sensorSnipType : sensorType1 = axleSensors.filter(as => parseInt(as.wheelId.slice(1, 2)) === 1)[0].sensorType;
//                    if (gSnipImage)
//                        sensorType1 = axleSensors.filter(as => parseInt(as.wheelId.slice(1, 2)) === 1)[0].sensorSnipType;
//                    else
//                        sensorType1 = axleSensors.filter(as => parseInt(as.wheelId.slice(1, 2)) === 1)[0].sensorType;

//                    if (sensorType1 === "") {
//                        gSnipImage ? sensorType1 = 'wheelsnipactive' : sensorType1 = 'wheelactive';
//                        //if (gSnipImage)
//                        //    ;
//                        //else
//                        //    sensorType1 = 'wheelactive';
//                    }
//                    break;
//                case '2':
//                case '3':
//                case '4':
//                    //gSnipImage ? sensorType2 = axleSensors.filter(as => parseInt(as.wheelId.slice(1, 2)) === parseInt(spare))[0].sensorSnipType : sensorType2 = axleSensors.filter(as => parseInt(as.wheelId.slice(1, 2)) === parseInt(spare))[0].sensorType;
//                    if (gSnipImage)
//                        sensorType2 = axleSensors.filter(as => parseInt(as.wheelId.slice(1, 2)) === parseInt(spare))[0].sensorSnipType;
//                    else
//                        sensorType2 = axleSensors.filter(as => parseInt(as.wheelId.slice(1, 2)) === parseInt(spare))[0].sensorType;
                        
//                    if (sensorType2 === "") {
//                        /*if (gSnipImage)*/
//                        gSnipImage ? sensorType2 = 'wheelsnipactive' : sensorType2 = 'wheelactive';
//                        //else
//                        //    sensorType2 = 'wheelactive';
//                    }
//                    break;
//                default:
//                    sensorType1 = 'wheelinactive';
//                    sensorType2 = 'wheelinactive';
//            }
//        }

//        //Spare 1
//        let tyredivclasses1 = ['chartsparewheel', sensorType1];
//        let tyreDiv1 = createTyreElement(unit, 1, axleSensors, tyredivclasses1);
//        tyreSpareAxleDiv.appendChild(tyreDiv1);

//        if (sensorType1 !== 'wheelinactive') {
//            let tyreSensorValuesDiv1 = createTyreInfoElement("S", 1, axleSensors);
//            tyreSpareAxleDiv.appendChild(tyreSensorValuesDiv1);
//        }

//        //Spare Axle
//        let axledivclasses = [];
//        let axleStatusClass = '';
//        isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
//        axledivclasses = ['spareaxle', 'axleinfo', axleStatusClass]; //'spareaxle'
//        let spareAxle = createAxleElement(unit, "S", axleSensors, axledivclasses); //'spareaxle');
//        tyreSpareAxleDiv.appendChild(spareAxle);

//        let axleInfoDiv = createAxleInfoElement(unit, 9);
//        tyreSpareAxleDiv.appendChild(axleInfoDiv);

//        //Spare 2
//        let tyredivclasses2 = ['chartsparewheel', sensorType2];
//        let tyreDiv2 = createTyreElement(unit, 2, axleSensors, tyredivclasses2);
//        tyreSpareAxleDiv.appendChild(tyreDiv2);

//        if (sensorType2 !== 'wheelinactive') {
//            let tyreSensorValuesDiv2 = createTyreInfoElement("S", 2, axleSensors);
//            tyreSpareAxleDiv.appendChild(tyreSensorValuesDiv2);
//        }

//    } else {

//        //Spare 1 Inactive
//        let tyreDiv1 = createTyreElement(unit, 1, axleSensors, tyredivclasses);
//        tyreSpareAxleDiv.appendChild(tyreDiv1);

//        //Spare 2 Inactive
//        let tyreDiv2 = createTyreElement(unit, 2, axleSensors, tyredivclasses);
//        tyreSpareAxleDiv.appendChild(tyreDiv2);
//    }

//    return tyreSpareAxleDiv;
//}

//function createAxleElement(unit, axle, axleSensors, axledivclasses) {

//    let axleDiv = document.createElement('div');
//    axleDiv.classList.add(...axledivclasses);

//    //let imei = unit.imei;
//    //let trailerId = 0;
//    //if (unit.unitTrailers.length > 0) trailerId = unit.unitTrailers[0].id;
//    //+ "<div class='axle axleactive axle1' onclick='openValuesOutOfRangeForm(" + imei + ", " + trailerId + ", 1, " + JSON.stringify(selectedUnitSensorIds) + ")'></div >"
//    axleDiv.onclick = function (e) {
//        e.stopImmediatePropagation();
//        if (axle >= 4) {
//            if (unit.hasOwnProperty('trailers')) {
//                if (unit.unitTrailers[0] != null) unit = unit.unitTrailers[0];
//            }
//        } //set trailer's axle properties
//        openValuesOutOfRangeForm(unit, axleSensors);
//    };

//    return axleDiv;
//}

//function createTyreElement(unit, tyre, axleSensors, tyredivclasses) {

//    let tyreDiv = document.createElement('div');

//    if (axleSensors.length > 0)
//        tyreDiv.id = 'tyre' + axleSensors[0].wheelId.slice(0, 1) + tyre;

//    tyreDiv.classList.add(...tyredivclasses);

//    let tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors);

//    if (tyreDiv.classList.contains('wheelinactive')) {
//        tyreDiv.onclick = function (e) {
//            e.stopImmediatePropagation();
//            openNewSensorForm(unit, "9" + tyre);
//        };
//    } else {
//        tyreDiv.onclick = function (e) {
//            e.stopImmediatePropagation();
//            openTyreContextMenu(unit, axleSensors[tyreArrayIndex]);
//        };
//    }

//    return tyreDiv;
//}

export function createAxleInfoElement(axleRange, axleNoUnitNo) {
    
    const axleInfoDiv = document.createElement('div');      
    const axleinfodivclasses = ['axleinfo', 'axleinfohide'];
    axleInfoDiv.classList.add(...axleinfodivclasses);

    const mrpDiv = createManufacturersRecommendedPressureElement(axleNoUnitNo, axleRange?.recommendedPressure || null);
    const pdaLowDiv = createPressureDeviationAllowedLowElement(axleNoUnitNo, axleRange?.pressureDeviationAllowedLow || null);
    const minPressureDiv = createMinPressureValueElement(axleNoUnitNo, axleRange?.minPressure || null);
    const pdaHighDiv = createPressureDeviationAllowedHighElement(axleNoUnitNo, axleRange?.pressureDeviationAllowedHigh || null);
    const maxPressureDiv = createMaxPressureValueElement(axleNoUnitNo, axleRange?.maxPressure || null);
    const maxTempDiv = createMaxTemperatureValueElement(axleNoUnitNo, axleRange?.maxTemperature || null);
    const minVoltageDiv = createMinVoltageValueElement(axleNoUnitNo, axleRange?.minVoltage || null);

    axleInfoDiv.appendChild(mrpDiv);    
    axleInfoDiv.appendChild(pdaLowDiv);    
    axleInfoDiv.appendChild(minPressureDiv);
    axleInfoDiv.appendChild(pdaHighDiv);
    axleInfoDiv.appendChild(maxPressureDiv);
    axleInfoDiv.appendChild(maxTempDiv);
    axleInfoDiv.appendChild(minVoltageDiv);

    return axleInfoDiv;
}

function createManufacturersRecommendedPressureElement(axleNoUnitNo, mrpValue) {

    if (mrpValue == null || mrpValue == "null" || mrpValue == "") mrpValue = "--";

    let mrpDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Rec Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.id = `a${axleNoUnitNo}mrp`; // Assign an id here
    valueSpan.textContent = mrpValue; // Use textContent here

    let mrpSpan = document.createElement('span');
    mrpSpan.appendChild(labelSpan);
    mrpSpan.appendChild(valueSpan);

    mrpDiv.appendChild(mrpSpan);

    return mrpDiv;
}

function createPressureDeviationAllowedHighElement(axleNoUnitNo, pdaValue) {

    if (pdaValue == null || pdaValue == "null" || pdaValue == "") pdaValue = "--";

    let pdaHighDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Deviation - Max (%): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.id = `a${axleNoUnitNo}highppd`; // Assign an id here
    valueSpan.textContent = pdaValue; // Use textContent here

    let pdaSpan = document.createElement('span');
    pdaSpan.appendChild(labelSpan);
    pdaSpan.appendChild(valueSpan);

    pdaHighDiv.appendChild(pdaSpan);

    return pdaHighDiv;
}

function createPressureDeviationAllowedLowElement(axleNoUnitNo, pdaValue) {

    if (pdaValue == null || pdaValue == "null" || pdaValue == "") pdaValue = "--";

    let pdaLowDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Deviation - Min (%): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.id = `a${axleNoUnitNo}lowppd`; // Assign an id here
    valueSpan.textContent = pdaValue; // Use textContent here

    let pdaSpan = document.createElement('span');
    pdaSpan.appendChild(labelSpan);
    pdaSpan.appendChild(valueSpan);

    pdaLowDiv.appendChild(pdaSpan);

    return pdaLowDiv;
}

function createMinPressureValueElement(axleNoUnitNo, minPressurevalue) {

    if (minPressurevalue == null || minPressurevalue == "null" || minPressurevalue == "") minPressurevalue = "--";

    let minPressureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Min Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue pressurered';
    valueSpan.id = `a${axleNoUnitNo}minpressure`;
    valueSpan.textContent = minPressurevalue;

    let minPressureSpan = document.createElement('span');
    minPressureSpan.appendChild(labelSpan);
    minPressureSpan.appendChild(valueSpan);

    minPressureDiv.appendChild(minPressureSpan);

    return minPressureDiv;
}

function createMaxPressureValueElement(axleNoUnitNo, maxPressure) {

    if (maxPressure == null || maxPressure == "null" || maxPressure == "") maxPressure = "--";

    let maxPressureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Max Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue pressurepurple';
    valueSpan.id = `a${axleNoUnitNo}maxpressure`;
    valueSpan.textContent = maxPressure;

    let maxPressureSpan = document.createElement('span');
    maxPressureSpan.appendChild(labelSpan);
    maxPressureSpan.appendChild(valueSpan);

    maxPressureDiv.appendChild(maxPressureSpan);

    return maxPressureDiv;
}

function createMaxTemperatureValueElement(axleNoUnitNo, maxTemperature) {

    if (maxTemperature == null || maxTemperature == "null" || maxTemperature == "") maxTemperature = "--";

    let maxTemperatureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Max Temp (°C): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue temperatureorange';
    valueSpan.id = `a${axleNoUnitNo}maxtemperature`;
    valueSpan.textContent = maxTemperature;

    let maxTemperatureSpan = document.createElement('span');
    maxTemperatureSpan.appendChild(labelSpan);
    maxTemperatureSpan.appendChild(valueSpan);

    maxTemperatureDiv.appendChild(maxTemperatureSpan);

    return maxTemperatureDiv;
}

function createMinVoltageValueElement(axleNoUnitNo, minVoltageValue) {

    if (minVoltageValue == null || minVoltageValue == "null" || minVoltageValue == "") minVoltageValue = "--";

    let minVoltageDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Min Voltage (V): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue voltageyellow';
    valueSpan.id = `a${axleNoUnitNo}minvoltage`;
    valueSpan.textContent = minVoltageValue;

    let minVoltageSpan = document.createElement('span');
    minVoltageSpan.appendChild(labelSpan);
    minVoltageSpan.appendChild(valueSpan);

    minVoltageDiv.appendChild(minVoltageSpan);

    return minVoltageDiv;
}

function openNotificationsForm(unit) {

    const { notificationSettings = {} } = unit;

    document.body.style.cursor = 'wait';

    const notificationsmodal = document.getElementById('notificationsmodal');
    const closeModal = document.getElementById("closenotificationsmodal");
    const notificationsform = document.getElementById('notificationsform');

    closeModal.onclick = (e) => {
        e.stopImmediatePropagation();
        notificationsmodal.style.display = "none";
    };

    //window.onclick = function (event) {
    //    event.stopImmediatePropagation();
    //    if (event.target == notificationsmodal) {
    //        notificationsmodal.style.display = "none";
    //    }
    //};
    
    setCheckboxValue('nfPressureCheck', notificationSettings.pressure);
    setCheckboxValue('nfTemperatureCheck', notificationSettings.temperature);
    setCheckboxValue('nfVoltageCheck', notificationSettings.voltage);
    setInputValue('nfEmailAddress1', notificationSettings.email1);
    setInputValue('nfEmailAddress2', notificationSettings.email2);
    setInputValue('nfEmailAddress3', notificationSettings.email3);

    notificationsform.onsubmit = function (e) {
        e.stopImmediatePropagation();  
        e.preventDefault();
        submitNotificationsForm(unit);
    };

    document.body.style.cursor = 'default';
    document.getElementById("notificationsmodal").style.display = 'block';
}

function setCheckboxValue(elementId, value) {
    document.getElementById(elementId).checked = !!value;
}

function setInputValue(elementId, value) {
    document.getElementById(elementId).value = value || "";
}

function submitNotificationsForm(unit) {

    document.getElementById("loadingBoxText").innerText = "Updating notification data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });   

    if (!unit.notificationSettings) {
        unit.notificationSettings = {};
    }

    unit.notificationSettings.pressure = document.getElementById('nfPressureCheck').checked;
    unit.notificationSettings.temperature = document.getElementById('nfTemperatureCheck').checked;
    unit.notificationSettings.voltage = document.getElementById('nfVoltageCheck').checked;
    //unit.notificationSettings.telegramChannel = document.getElementById('nfTelegramChannelId')?.value.trim() || "";
    unit.notificationSettings.email1 = document.getElementById('nfEmailAddress1')?.value.trim() || "user@example.com";
    unit.notificationSettings.email2 = document.getElementById('nfEmailAddress2')?.value.trim() || "user@example.com";
    unit.notificationSettings.email3 = document.getElementById('nfEmailAddress3')?.value.trim() || "user@example.com";
    unit.notificationSettings.minutesBetweenNotifications = 60;

    updateNotificationSettings(unit);
    document.getElementById("notificationsmodal").style.display = 'none';
    $("#loadingBox").modal("hide");
    return false;
}

function onClickCloseModal(modalToClose, modalCloseButton) {

        //const span = document.getElementById("closevaluesoutofrangemodal");
    //span.onclick = function (e) {
    //    e.stopImmediatePropagation();
    //    valuesoutofrangemodal.style.display = "none";
    //};

    const closeButton = document.getElementById(modalCloseButton);
    closeButton.onclick = function (e) {
        e.stopImmediatePropagation();
        document.getElementById(modalToClose).style.display = "none";        
    }
}

function showCustomWait(info) {
    document.getElementById("loadingBoxText").innerText = info;
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });
}

function calculateMinMaxPressure() {

    const recommendedPressure = parseFloat(document.getElementById('recommendedPressure').value);
    const pressureDeviationAllowedLow = parseFloat(document.getElementById('pressuredeviationallowedlow').value);
    if (recommendedPressure && pressureDeviationAllowedLow) {
        document.getElementById("minPressure").value = calculateMinPressure(parseFloat(recommendedPressure), parseFloat(pressureDeviationAllowedLow));       
    }

    const pressureDeviationAllowedHigh = parseFloat(document.getElementById('pressuredeviationallowedhigh').value);
    if (recommendedPressure && pressureDeviationAllowedLow) {
        document.getElementById("maxPressure").value = calculateMaxPressure(parseFloat(recommendedPressure), parseFloat(pressureDeviationAllowedHigh));
    }

    return true;
}

function calculateMinPressure(recommendedPressure, percentageDeviationAllowed) {

    let minPressure = (recommendedPressure - (percentageDeviationAllowed / 100 * recommendedPressure));
    return Math.round(minPressure * 100) / 100;
}

function calculateMaxPressure(recommendedPressure, percentageDeviationAllowed) {

    let maxPressure = (recommendedPressure + (percentageDeviationAllowed / 100 * recommendedPressure));
    return Math.round(maxPressure * 100) / 100;
}

async function openValuesOutOfRangeForm(unit, axleSensors) {

    clearModalFields();

    const axleSensorsRef = cloneObject(axleSensors);

    onClickCloseModal('valuesoutofrangemodal', 'closevaluesoutofrangemodal');

    const valuesOutOfRangeForm = document.getElementById('valuesoutofrangeform');    

    valuesOutOfRangeForm.onsubmit = function (e) {
        e.stopImmediatePropagation();  // Prevents other listeners of the same event from being called
        e.preventDefault();  // Prevents the default form submission behavior
        submitValuesOutOfRangeForm(unit, axleSensorsRef);
    };

    document.getElementById("vormUnitName").value = unit.name;
    const axle = axleSensors[0].wheelId.slice(1, 3);
    if (axle === SPARE_AXLE.toString())
        document.getElementById('axle').value = "Spare Axle";
    else
        document.getElementById('axle').value = "Axle " + axle;

    if (unit.axleRanges) {
        const axleRange = unit.axleRanges.find(ar => parseInt(ar.axleNumber) === parseInt(axleSensors[0].wheelId.slice(1)));

        const fields = {
            'recommendedPressure': axleRange?.recommendedPressure,
            'pressuredeviationallowedlow': axleRange?.pressureDeviationAllowedLow ?? '',
            'pressuredeviationallowedhigh': axleRange?.pressureDeviationAllowedHigh ?? '',
            'minPressure': axleRange?.minPressure,
            'maxPressure': axleRange?.maxPressure,
            'maxTemperature': axleRange?.maxTemperature,
            'minVoltage': 2.5
        };

        for (const [id, value] of Object.entries(fields)) {
            const element = document.getElementById(id);
            if (element && value !== undefined) {
                element.value = value;  // Assign the value, or "" if it's undefined
            }
        }
    }

    const recommendedPressure = document.getElementById('recommendedPressure');
    recommendedPressure.removeEventListener('input', calculateMinMaxPressure);
    recommendedPressure.addEventListener('input', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        calculateMinMaxPressure();
    });

    const pressureDeviationAllowedLow = document.getElementById('pressuredeviationallowedlow');
    pressureDeviationAllowedLow.removeEventListener('input', calculateMinMaxPressure);
    pressureDeviationAllowedLow.addEventListener('input', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        calculateMinMaxPressure();
    });

    const pressureDeviationAllowedHigh = document.getElementById('pressuredeviationallowedhigh');
    pressureDeviationAllowedHigh.removeEventListener('input', calculateMinMaxPressure);
    pressureDeviationAllowedHigh.addEventListener('input', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        calculateMinMaxPressure();
    });

    document.getElementById("valuesoutofrangemodal").style.display = "block";
}

function clearModalFields() {
    const fields = [
        'recommendedPressure',
        'pressuredeviationallowedlow',
        'pressuredeviationallowedhigh',
        'minPressure',
        'maxPressure',
        'maxTemperature',
        'minVoltage'
    ];

    fields.forEach(fieldId => {
        const element = document.getElementById(fieldId);
        if (element) {
            element.value = '';  // Clear the value
        }
    });
}

function submitValuesOutOfRangeForm(unit, axleSensorsRef) {

    manufacturersRecommendedPressureValidation();
    //percentageDeviationAllowedValidation();

    showCustomWait("Updating 'sensor values out of range' data... ");

    const valuesOutOfRangeParams = {};
    valuesOutOfRangeParams.customerId = unit.customerId;
    valuesOutOfRangeParams.imei = unit.imei;
    const axleNoUnitNo = axleSensorsRef[0].wheelId.slice(1);
    valuesOutOfRangeParams.axleNoUnitNo = axleNoUnitNo;
    const recommendedPressure = parseFloat(document.getElementById("recommendedPressure").value);
    valuesOutOfRangeParams.recommendedPressure = recommendedPressure;
    const pressureDeviationAllowedLow = parseFloat(document.getElementById("pressuredeviationallowedlow").value);
    valuesOutOfRangeParams.pressureDeviationAllowedLow = pressureDeviationAllowedLow;
    const pressureDeviationAllowedHigh = parseFloat(document.getElementById("pressuredeviationallowedhigh").value);
    valuesOutOfRangeParams.pressureDeviationAllowedHigh = pressureDeviationAllowedHigh;
    const minPressure = parseFloat(document.getElementById("minPressure").value);
    valuesOutOfRangeParams.minPressure = minPressure;
    const maxPressure = parseFloat(document.getElementById("maxPressure").value);
    valuesOutOfRangeParams.maxPressure = maxPressure;
    const maxTemperature = parseInt(document.getElementById('maxTemperature').value) || 65;
    valuesOutOfRangeParams.maxTemperature = maxTemperature;
    const minVoltage = parseFloat(document.getElementById('minVoltage').value) || 2.5;
    valuesOutOfRangeParams.minVoltage = minVoltage;

    updateAxleRanges(valuesOutOfRangeParams);

    let axleRange = unit.axleRanges.find(ar => ar.axleNumber === axleNoUnitNo);
    if (!axleRange) {
        if (!unit.axleRanges) {
            unit.axleRanges = [];
        }        
        axleRange = { axleNumber: axleNoUnitNo };
        unit.axleRanges.push(axleRange);                           
    }
    axleRange.recommendedPressure = recommendedPressure;
    axleRange.pressureDeviationAllowedLow = pressureDeviationAllowedLow;
    axleRange.pressureDeviationAllowedHigh = pressureDeviationAllowedHigh;
    axleRange.minPressure = minPressure;
    axleRange.maxPressure = maxPressure;
    axleRange.maxTemperature = maxTemperature;
    axleRange.minVoltage = minVoltage;

    if (axle === SPARE_AXLE.toString()) {
        unit.spareSensors.forEach(s => {
            const matchingSensor = axleSensorsRef.find(sensor => sensor.sensorId === s.sensorId);
            if (matchingSensor) {
                s.recommendedPressure = recommendedPressure;
                s.pressureDeviationAllowedLow = pressureDeviationAllowedLow;
                s.pressureDeviationAllowedHigh = pressureDeviationAllowedHigh;
                s.minPressureValue = minPressure;
                s.maxPressureValue = maxPressure;
                s.maxTemperatureValue = maxTemperature;
                s.minVoltageValue = minVoltage;
            }
        });
    } else {
        unit.wheelSensors.forEach(s => {
            const matchingSensor = axleSensorsRef.find(sensor => sensor.sensorId === s.sensorId);
            if (matchingSensor) {
                s.recommendedPressure = recommendedPressure;
                s.pressureDeviationAllowedLow = pressureDeviationAllowedLow;
                s.pressureDeviationAllowedHigh = pressureDeviationAllowedHigh;
                s.minPressureValue = minPressure;
                s.maxPressureValue = maxPressure;
                s.maxTemperatureValue = maxTemperature;
                s.minVoltageValue = minVoltage;
            }
        });
    }

    axleSensorsRef.forEach(function (s) {
        s.recommendedPressure = recommendedPressure;
        s.pressureDeviationAllowedLow = pressureDeviationAllowedLow;
        s.pressureDeviationAllowedHigh = pressureDeviationAllowedHigh;
        s.minPressure = minPressure;
        s.maxPressure = maxPressure;
        s.maxTemperature = maxTemperature;
        s.minVoltageValue = minVoltage;
    });
    updateDiagramColours(axleSensorsRef);
    updateValuesOutOfRangeValue(`a${axleNoUnitNo}mrp`, recommendedPressure);
    updateValuesOutOfRangeValue(`a${axleNoUnitNo}lowppd`, pressureDeviationAllowedLow);
    updateValuesOutOfRangeValue(`a${axleNoUnitNo}highppd`, pressureDeviationAllowedHigh);
    updateValuesOutOfRangeValue(`a${axleNoUnitNo}minpressure`, minPressure);
    updateValuesOutOfRangeValue(`a${axleNoUnitNo}maxpressure`, maxPressure);
    updateValuesOutOfRangeValue(`a${axleNoUnitNo}maxtemperature`, maxTemperature);
    updateValuesOutOfRangeValue(`a${axleNoUnitNo}minvoltage`, minVoltage);

    document.getElementById("valuesoutofrangemodal").style.display = "none";

     $("#loadingBox").modal("hide");    
}

function updateDiagramColours(sensors) {
       
    if (document.getElementById(`axle${sensors[0].wheelId.slice(1)}`)) {
        if (sensors[0].wheelId.slice(1, 3) === SPARE_AXLE.toString()) {
            document.getElementById(`axle${sensors[0].wheelId.slice(1)}`).className = `spareaxle spareaxleactive`;
        } else {
            document.getElementById(`axle${sensors[0].wheelId.slice(1)}`).className = `shortaxle axleactive`;
        }        
    }

    const axleSensors = getWheelProperties(sensors)

    for (let s = 0; s < axleSensors.length; s++) {
        if (document.getElementById(`wheel${axleSensors[s].sensorId}`)) {
            if (axleSensors[s].sensorType === '') axleSensors[s].sensorType = 'wheelcorrect';
            document.getElementById(`wheel${axleSensors[s].sensorId}`).className = `wheeldefault ${axleSensors[s].sensorType}`;
        }

        if (document.getElementById(`sv${axleSensors[s].sensorId}pressure`)) { 
            document.getElementById(`sv${axleSensors[s].sensorId}pressure`).innerText = `${parseFloat(axleSensors[s].currentPressure).toFixed(2)} bar`;
            document.getElementById(`sv${axleSensors[s].sensorId}pressure`).className = `sensorvalues ${axleSensors[s].pressureLabelColour}`;
        }
        if (document.getElementById(`sv${axleSensors[s].sensorId}temperature`)) {
            document.getElementById(`sv${axleSensors[s].sensorId}temperature`).innerText = `${axleSensors[s].currentTemperature} C`;
            document.getElementById(`sv${axleSensors[s].sensorId}temperature`).className = `sensorvalues ${axleSensors[s].temperatureLabelColour}`;
        }                               
        if (document.getElementById(`sv${axleSensors[s].sensorId}voltage`)) {
            document.getElementById(`sv${axleSensors[s].sensorId}voltage`).innerText = `${axleSensors[s].currentVoltage} V`;
            document.getElementById(`sv${axleSensors[s].sensorId}voltage`).className = `sensorvalues ${axleSensors[s].voltageLabelColour}`;
        }
    }
}

function updateValuesOutOfRangeValue(valueSpanId, newValue) {
    let valueSpan = document.getElementById(valueSpanId);
    if (valueSpan) {
        valueSpan.textContent = newValue;
    }
}

function manufacturersRecommendedPressureValidation() {

    if (parseFloat(document.getElementById('recommendedPressure').value) < 1 || parseFloat(document.getElementById('recommendedPressure').value) > 9)
        document.getElementById('recommendedPressure').setCustomValidity("Please enter a value between 1.0 and 12.0 bar");

    return;
}

function percentageDeviationAllowedValidation() {
    if (parseFloat(document.getElementById('pressuredeviationallowedlow').value) < 10 || parseFloat(document.getElementById('pressuredeviationallowedlow').value) > 35)
        document.getElementById('pressuredeviationallowedlow').setCustomValidity("Please enter a value not less than 10%");

    return;
}

export { openValuesOutOfRangeForm, openNotificationsForm, createUnitElement };

