import { getCurrentLocation } from "./data/unitdata";
import { convertUnixTime, formatDate } from "./utilities";
import { loadIntervalAsync } from "./pressuretrack";
import { createUnitMapContainerElement } from "./components/views/unitinfo";
import { loadSensorValuesBatch } from "./components/views/units";
import { DialogBox } from "../thirdparty/customdialog/draggable-resizable-dialog.js";

import { PRIMARY_COLOUR, ACCENT_COLOUR, LOW_PRESSURE_COLOUR, HIGH_PRESSURE_COLOUR, HIGH_TEMPERATURE_COLOUR, LOW_VOLTAGE_COLOUR, SPARE_AXLE } from "./global";

let map = null, marker = null, mapBox = null; // global variables for maps (leaflet)

var gSelectedUnit = {};
let chartPointClicked = 0;

var gPressureChartCanvas = document.getElementById('sensorreportpressurechartcanvas');
var gPressureCtx = gPressureChartCanvas.getContext('2d');
var gTemperatureChartCanvas = document.getElementById('sensorreporttemperaturechartcanvas');
var gTemperatureCtx = gTemperatureChartCanvas.getContext('2d');
var gVoltageChartCanvas = document.getElementById('sensorreportvoltagechartcanvas');
var gVoltageCtx = gVoltageChartCanvas.getContext('2d');
var gPressureChartCounter = 0;
var gTemperatureChartCounter = 0;
var gVoltageChartCounter = 0;
var gPressureChart, gTemperatureChart, gVoltageChart;
var gPressureChartMaxY = 0;
var gTemperatureChartMaxY = 0;
var gVoltageChartMaxY = 0;

//var gSensorValuesDateRangeRadio = document.getElementById('sensorreportdaterange');
var gSensorValuesTodayRadio = document.getElementById('sensorreporttoday');
var gSensorValuesLast7DaysRadio = document.getElementById('sensorreport7days');
//var gSensorValuesLast30DaysRadio = document.getElementById('sensorreport30days');

var gSensorValuesPressureCheck = document.getElementById('sensorreportpressure');
var gSensorValuesTemperatureCheck = document.getElementById('sensorreporttemperature');
var gSensorValuesVoltageCheck = document.getElementById('sensorreportvoltage');

var gSensorValuesDataToday = [];
var gSensorValuesData7Days = [];
var gPrevMinPressureValue, gPrevMaxTemperatureValue, gPrevMinVoltageValue;
var gPressureAnnotation = [];
var gTemperatureAnnotation = [];
var gVoltageAnnotation = [];

function removePressureChart(wheelAxle) {

    let pressureAnnotation = gPressureAnnotation.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.minPressure === value.minPressure
        ))
    );

    if (gPressureChart && gPressureChart.data.datasets.length > 0) {
        if (gPressureChart.config.options.plugins.annotation.annotations.length) {
            if (pressureAnnotation.length === 1) {
                gPressureChart.config.options.plugins.annotation.annotations[0].yMax = pressureAnnotation[0].minPressure;
                gPressureChart.config.options.plugins.annotation.annotations[0].yMin = pressureAnnotation[0].minPressure;
                gPressureChart.config.options.plugins.annotation.annotations[0].label.content = "Min Press: " + pressureAnnotation[0].minPressure + " bar";
                gPressureChart.config.options.plugins.annotation.annotations[1].yMax = pressureAnnotation[0].maxPressure;
                gPressureChart.config.options.plugins.annotation.annotations[1].yMin = pressureAnnotation[0].maxPressure;
                gPressureChart.config.options.plugins.annotation.annotations[1].label.content = "Max Press: " + pressureAnnotation[0].maxPressure + " bar";
                gPressureChart.config.options.plugins.annotation.annotations[2].yMax = pressureAnnotation[0].recommendedPressure;
                gPressureChart.config.options.plugins.annotation.annotations[2].yMin = pressureAnnotation[0].recommendedPressure;
                gPressureChart.config.options.plugins.annotation.annotations[2].label.content = "Rec Press: " + pressureAnnotation[0].recommendedPressure + " bar";

                gPrevMinPressureValue = pressureAnnotation[0].minPressure;

                if (gPressureChartMaxY < pressureAnnotation[0].maxPressure) {
                    gPressureChartMaxY = parseFloat(pressureAnnotation[0].maxPressure) + 1;
                    gPressureChart.config.options.scales.y.max = gPressureChartMaxY;
                }
            }
        }

        for (let p = 0; p < gPressureChart.data.datasets.length; p++) {
            if (gPressureChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gPressureChart.data.datasets.splice(p, 1);
                gPressureChart.update();
            }
        }
    }
}

function removeTemperatureChart(wheelAxle) {

    let temperatureAnnotation = [...new Set(gTemperatureAnnotation)];

    if (gTemperatureChart && gTemperatureChart.data.datasets.length > 0) {
        if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
            if (temperatureAnnotation.length === 1) {
                gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax = temperatureAnnotation[0];
                gTemperatureChart.config.options.plugins.annotation.annotations[0].yMin = temperatureAnnotation[0];
                gTemperatureChart.config.options.plugins.annotation.annotations[0].label.content = "Max Temp: " + temperatureAnnotation[0] + "\u2103";

                gPrevMaxTemperatureValue = temperatureAnnotation[0];

                if (gTemperatureChartMaxY < temperatureAnnotation[0]) {
                    gTemperatureChartMaxY = parseInt(temperatureAnnotation[0]) + 10;
                    gTemperatureChart.config.options.scales.y.max = gTemperatureChartMaxY;
                }
            }
        }

        for (let p = 0; p < gTemperatureChart.data.datasets.length; p++) {
            if (gTemperatureChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gTemperatureChart.data.datasets.splice(p, 1);
                gTemperatureChart.update();
            }
        }
    }
}

function removeVoltageChart(wheelAxle) {

    let voltageAnnotation = [...new Set(gVoltageAnnotation)];

    if (gVoltageChart && gVoltageChart.data.datasets.length > 0) {
        if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
            if (voltageAnnotation.length === 1) {
                gVoltageChart.config.options.plugins.annotation.annotations[0].yMax = voltageAnnotation[0];
                gVoltageChart.config.options.plugins.annotation.annotations[0].yMin = voltageAnnotation[0];
                gVoltageChart.config.options.plugins.annotation.annotations[0].label.content = "Min Volt: " + voltageAnnotation[0] + "V";

                gPrevMinVoltageValue = voltageAnnotation[0];

                if (gVoltageChartMaxY < voltageAnnotation[0]) {
                    gVoltageChartMaxY = parseFloat(voltageAnnotation[0]) + 1;
                    gVoltageChart.config.options.scales.y.max = gVoltageChartMaxY;
                }
            }
        }

        for (let p = 0; p < gVoltageChart.data.datasets.length; p++) {
            if (gVoltageChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gVoltageChart.data.datasets.splice(p, 1);
                gVoltageChart.update();
            }
        }
    }
}

function updatePressureChart(chartData, tyreInput, wheelAxle) {

    if (gPressureChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMinPressureValue) !== parseFloat(tyreInput.minPressure)) {
            gPressureChart.config.options.plugins.annotation.annotations[0].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[0].yMin = '99';
            gPressureChart.config.options.plugins.annotation.annotations[1].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[1].yMin = '99';
            gPressureChart.config.options.plugins.annotation.annotations[2].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[2].yMin = '99';
        }
    }

    let chartAxesData = [];

    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].pressure;
        xyData.x = chartData[i].time;
        xyData.temperature = chartData[i].temperature;
        xyData.voltage = chartData[i].voltage;
        //xyData.address = sensorValuesLocations[i];
        chartAxesData.push(xyData);
    }

    let obj = {
        label: 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1),
        backgroundColor: tyreInput.chartColour,
        fill: false,
        borderColor: tyreInput.chartColour,
        data: chartAxesData, //yAxisLabels,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gPressureChart.data.datasets.push(obj);

    if (gPressureChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.map(c => c.y));
        if (gPressureChart.config.options.plugins.annotation.annotations[0].yMax === '99') {
            if (gPressureChartMaxY <= maxY) gPressureChartMaxY = Math.round(maxY + 1);
        } else {
            const tempMaxY = Math.max(maxY, tyreInput.maxPressure);
            gPressureChartMaxY = Math.round(tempMaxY + 2);
        }
        gPressureChart.options.scales.y.max = gPressureChartMaxY;
    }

    gPressureChart.update();
}

function updateTemperatureChart(chartData, sensorValuesLocations, tyreInput, wheelAxle) {

    if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMaxTemperatureValue) !== parseFloat(tyreInput.maxTemperature)) {
            gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax = '999';
            gTemperatureChart.config.options.plugins.annotation.annotations[0].yMin = '999';
        }
    }

    let chartAxesData = [];

    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].temperature;
        xyData.x = chartData[i].time;
        xyData.pressure = chartData[i].pressure;
        xyData.voltage = chartData[i].voltage;
        xyData.address = sensorValuesLocations[i];
        chartAxesData.push(xyData);
    }

    let obj = {
        label: 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1),
        backgroundColor: tyreInput.chartColour,
        fill: false,
        borderColor: tyreInput.chartColour,
        data: chartAxesData, //yAxisLabels,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gTemperatureChart.data.datasets.push(obj);

    if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.map(c => c.y));
        if (gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax === '999') {
            if (gTemperatureChartMaxY <= maxY) gTemperatureChartMaxY = Math.round(maxY + 5);
        } else {
            const tempMaxY = Math.max(maxY, tyreInput.maxTemperature);
            gTemperatureChartMaxY = Math.round(tempMaxY + 5);
        }
        gTemperatureChart.options.scales.y.max = gTemperatureChartMaxY;
    }

    gTemperatureChart.update();
}

function updateVoltageChart(chartData, sensorValuesLocations, tyreInput, wheelAxle) {

    if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMinVoltageValue) !== parseFloat(tyreInput.minVoltageValue)) {
            gVoltageChart.config.options.plugins.annotation.annotations[0].yMax = '99';
            gVoltageChart.config.options.plugins.annotation.annotations[0].yMin = '99';
        }
    }

    let chartAxesData = [];
    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].voltage;
        xyData.x = chartData[i].time;
        xyData.pressure = chartData[i].pressure;
        xyData.temperature = chartData[i].temperature;
        xyData.address = sensorValuesLocations[i];
        chartAxesData.push(xyData);
    }

    let obj = {
        label: 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1),
        backgroundColor: tyreInput.chartColour,
        fill: false,
        borderColor: tyreInput.chartColour,
        data: chartAxesData, //yAxisLabels,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gVoltageChart.data.datasets.push(obj);

    if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.map(c => c.y));
        if (gVoltageChart.config.options.plugins.annotation.annotations[0].yMax === '99') {
            if (gVoltageChartMaxY <= maxY) gVoltageChartMaxY = Math.round(maxY + 1);
        } else {
            const tempMaxY = Math.max(maxY, tyreInput.minVoltageValue);
            gVoltageChartMaxY = Math.round(tempMaxY + 2);
        }
        gVoltageChart.options.scales.y.max = gVoltageChartMaxY;
    }

    gVoltageChart.update();
}

function createCharts(imei, sensorValuesData, timeSpan) {
    
    document.body.style.cursor = 'wait';

    clearCharts();

    createPressureChart(imei, sensorValuesData, timeSpan);
    createTemperatureChart(imei, sensorValuesData, timeSpan);
    createVoltageChart(imei, sensorValuesData, timeSpan);

    document.body.style.cursor = 'default';
}

function clearChartGlobals() {

    clearCharts();

    gSensorValuesDataToday = [];
    gSensorValuesData7Days = [];

    //gSensorValuesDateRangeRadio.checked = false;
    gSensorValuesTodayRadio.checked = false;
    gSensorValuesLast7DaysRadio.checked = false;
    //gSensorValuesLast30DaysRadio.checked = false;

    gSensorValuesPressureCheck.checked = false;
    gSensorValuesTemperatureCheck.checked = false;
    gSensorValuesVoltageCheck.checked = false;
}

function updateChartDisplays() {

    if (gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        const pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        const pressureChartClasses = ['threecharts', 'twoofthreecharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        const temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        const temperatureChartClasses = ['threecharts', 'twoofthreecharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        const voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        const voltageChartClasses = ['threecharts', 'oneofthreecharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'chart'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        //let temperatureChartClasses = ['threecharts', 'twocharts'];
        //temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        //let voltageChartClasses = ['threecharts', 'twocharts'];
        //voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'none';
    }

    if (gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        //let outOfRangeDataToday = [...gOutOfRangeDataTodayPressure, ...gOutOfRangeDataTodayTemperature];
        //outOfRangeDataToday = outOfRangeDataToday.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.wheelId === o.wheelId)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        //let outOfRangeDataWeek = [...gOutOfRangeDataWeekPressure, ...gOutOfRangeDataWeekTemperature];
        //outOfRangeDataWeek = outOfRangeDataWeek.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.wheelId === o.wheelId)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'twocharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'twocharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        //let voltageChartClasses = ['threecharts', 'twocharts'];
        //voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'none';
    }

    if (gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        //let outOfRangeDataToday = [...gOutOfRangeDataTodayPressure, ...gOutOfRangeDataTodayVoltage];
        //outOfRangeDataToday = outOfRangeDataToday.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.wheelId === o.wheelId)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        //let outOfRangeDataWeek = [...gOutOfRangeDataWeekPressure, ...gOutOfRangeDataWeekVoltage];
        //outOfRangeDataWeek = outOfRangeDataWeek.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.wheelId === o.wheelId)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'twocharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'twocharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (!gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'chart'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        voltageChart.style.display = 'none';
    }

    if (!gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        //let outOfRangeDataToday = [...gOutOfRangeDataTodayTemperature, ...gOutOfRangeDataTodayVoltage];
        //outOfRangeDataToday = outOfRangeDataToday.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.wheelId === o.wheelId)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        //let outOfRangeDataWeek = [...gOutOfRangeDataWeekTemperature, ...gOutOfRangeDataWeekVoltage];
        //outOfRangeDataWeek = outOfRangeDataWeek.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.wheelId === o.wheelId)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'twocharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'twocharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (!gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'chart'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }
}

function openSensorReport(unit, sensorData) {

    gSelectedUnit = unit;

    let chartsModal = document.getElementById('sensorchartreportmodal');

    document.getElementById('scrmUnitId').value = unit.imei;
    //document.getElementById('scrmUnitImg').src = wUnit.getIconUrl(64);
    document.getElementById('scrmUnitName').value = unit.name;

    let chartsClasses = ['threecharts', 'chart'];
    let pressureChart = document.getElementById('sensorreportpressurechart');
    pressureChart.classList.remove(...pressureChart.classList);
    pressureChart.classList.add(...chartsClasses);
    pressureChart.style.display = 'block';

    let temperatureChart = document.getElementById('sensorreporttemperaturechart');
    temperatureChart.classList.remove(...temperatureChart.classList);
    temperatureChart.style.display = 'none';

    let voltageChart = document.getElementById('sensorreportvoltagechart');
    voltageChart.classList.remove(...voltageChart.classList);
    voltageChart.style.display = 'none';

    gSensorValuesLast7DaysRadio.checked = true;
    gSensorValuesPressureCheck.checked = true;

    chartsModal.style.display = 'block';
    $("#loadingBox").modal("hide");
    if (chartsModal.style.display === 'block' && document.getElementById("loadingBox").style.display === 'block') {
        //TODO: might also need to load chart messages here...
        document.getElementById('loadingBox').style.display = 'none';
        $("#loadingBox").modal('hide');
        //alert("Something went wrong...");
    }

    const timespanOptions = document.getElementById('sensorreporttimespanoptions');
    timespanOptions.onclick = (e) => {

        if (gSensorValuesTodayRadio.checked) {
            createCharts(unit.imei, gSensorValuesDataToday, "TODAY");
        }

        if (gSensorValuesLast7DaysRadio.checked) {
            createCharts(unit.imei, gSensorValuesData7Days, "SEVEN DAYS");
        }

        updateChartDisplays();

        e.stopImmediatePropagation();
    };

    gSensorValuesPressureCheck.onclick = (e) => {
        updateChartDisplays();
        e.stopImmediatePropagation();
    };

    gSensorValuesTemperatureCheck.onclick = (e) => {
        updateChartDisplays();
        e.stopImmediatePropagation();
    };

    gSensorValuesVoltageCheck.onclick = (e) => {
        updateChartDisplays();
        e.stopImmediatePropagation();
    };

    const span = document.getElementById("closesensorchartreportmodal");
    span.onclick = function (e) {
        e.stopImmediatePropagation;
        document.getElementById('mapbox').style.display = 'none';
        chartsModal.style.display = 'none';
        clearChartGlobals();
        return false;
    };

    chartsModal.onclick = function (e) {
        if (e.target == chartsModal) {
            e.stopImmediatePropagation();
            document.getElementById('mapbox').style.display = 'none';
            chartsModal.style.display = 'none';
            clearChartGlobals();
            return false;
        }
    };

    const mapBoxContent = document.getElementById('mapboxcontent');
    let unitMapContainerDiv = createUnitMapContainerElement('sensorreportmapcontainer', 'sensorreportchartmap', 'chartmap');
    mapBoxContent.appendChild(unitMapContainerDiv);
    initialiseChartMap('sensorreportchartmap');

    const d = new Date();
    const ts = d.getTime();
    let today = Math.round((ts - (24 * 60 * 60 * 1000)) / 1000);

    gSensorValuesDataToday = sensorData.filter((sv) => {
        return sv.unixTime >= today;
    });

    gSensorValuesData7Days = sensorData;

    createCharts(unit.imei, gSensorValuesData7Days, "SEVEN DAYS")

    document.body.style.cursor = 'default';

    return false;
}

function clearCharts() {     

    if (gPressureChart)
        gPressureChart.destroy();
    if (gTemperatureChart)
        gTemperatureChart.destroy();
    if (gVoltageChart)
        gVoltageChart.destroy();
}

function sensorValuesChartOptionsChange(sensorValuesChartType, todayData, last7DaysData, sensorValuesChartMessages) {

    let minPressure = document.getElementById('rmMinPressureValue').value;
    let maxPressure = document.getElementById('rmMaxPressureValue').value;
    let recommendedPressure = document.getElementById('rmManufacturersRecommendedPressure').value;
    let maxTemperature = document.getElementById('rmMaxTemperatureValue').value;
    let minVoltageValue = document.getElementById('rmMinVoltageValue').value;

    let pressureValueRange = [];
    pressureValueRange.push(minPressure);
    pressureValueRange.push(maxPressure);
    pressureValueRange.push(recommendedPressure);

    if (document.getElementById("sensorValuesToday").checked === true) {
        document.getElementById("sensorValuesToday").checked = true;
        document.body.style.cursor = 'wait';
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(todayData, "TODAY", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(todayData, "TODAY", maxTemperature);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(todayData, "TODAY", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }

    if (document.getElementById("sensorValuesLast7Days").checked === true) {
        document.body.style.cursor = 'wait';
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(last7DaysData, "SEVEN DAYS", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(last7DaysData, "SEVEN DAYS", maxTemperature);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(last7DaysData, "SEVEN DAYS", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }
    if (document.getElementById("sensorValuesLast30Days").checked === true) {
        document.getElementById("sensorValuesLast30Days").checked = true;
        document.body.style.cursor = 'wait';
        let last30DaysData = sensorValuesChartMessages;
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(last30DaysData, "THIRTY DAYS", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(last30DaysData, "THIRTY DAYS", maxTemperature);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(last30DaysData, "THIRTY DAYS", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }
}

function getxAxesTimeLabelFormat(timespan) {

    let timeLabel = {};
    switch (timespan) {
        case 'TODAY':
            timeLabel.unit = "hour";
            timeLabel.timeFormat = "HH:mm";
            break;
        case 'SEVEN DAYS':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
        case 'THIRTY DAYS':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
        case 'CUSTOM DATES':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
    }
    return timeLabel;
}

//function initialiseChartMap(mapDiv) {

//    if (!map) {
//        // create a map in the "map" div, set the view to a given place and zoom
//        map = L.map(mapDiv, {
//            fullscreenControl: true,
//            fullscreenControlOptions: {
//                position: 'topleft'
//            }
//        }).setView([-33.97823, 22.45808], 10);

//        // add an OpenStreetMap tile layer
//        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
//            attribution: '&copy; <a href="http://gurtam.com">Gurtam</a>'
//        }).addTo(map);
//    }
//}

export function initialiseChartMap(mapDiv) {

    if (map) {
        map.remove(); // This removes the existing map and cleans up event listeners
        map = null; // Reset the map variable so it can be re-initialized
    }

    // create a new map instance
    map = L.map(mapDiv, {
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: 'topleft'
        }
    }).setView([-33.97823, 22.45808], 10);

    // add an OpenStreetMap tile layer
    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    setTimeout(() => {
        map.invalidateSize();
    }, 100);
}

function showMap(posx, posy) {
    if (map) { // check if map created
        const customIcon = L.divIcon({
            className: 'map-icon'
        });
        if (marker != null) {
            map.removeLayer(marker);
        }

        marker = L.marker({ lat: posy, lng: posx }, { icon: customIcon }).addTo(map);
        marker.setLatLng({ lat: posy, lng: posx });
        marker.setIcon(customIcon);
        map.invalidateSize();
        map.setView({ lat: posy, lng: posx });
    }
}

function tooltipLines(imei, strokeStyle) {
    const tooltipLine = {
        id: 'tooltipLine',
        beforeDraw: async chart => {
            if (chart.tooltip != null) {
                if (chart.tooltip._active && chart.tooltip._active.length) {
                    const ctx = chart.ctx;
                    if (ctx) {
                        ctx.save();
                        ctx.beginPath();
                        const activePoint = chart.tooltip._active[0];
                        if (activePoint) {
                            ctx.moveTo(activePoint.element.x, chart.chartArea.top);
                            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
                        }
                        ctx.lineWidth = 0.85;
                        ctx.strokeStyle = strokeStyle; // Keep the style
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        }
    };
    return tooltipLine;
}

function setClickablePoints(clicked, imei) {
    
    const clickablePoints = {
        id: 'clickablePoints',
        afterEvent: async (chart, args, pluginOptions) => {
            // Check for a 'click' event
            if (args.event.type === 'click') {
                const xCursor = args.event.x;
                const yCursor = args.event.y;

                // Loop through all data points to find the one that was clicked
                for (let i = 0; i < chart.data.datasets.length; i++) {
                    for (let d = 0; d < chart._metasets[i].data.length; d++) {
                        const point = chart._metasets[i].data[d];
                        const xMin = point.x - 2.5;
                        const xMax = point.x + 2.5;
                        const yMin = point.y - 2.5;
                        const yMax = point.y + 2.5;

                        // Check if the click is within the bounds of the point
                        if (xCursor >= xMin && xCursor <= xMax && yCursor >= yMin && yCursor <= yMax) {
                            if (!mapBox) mapBox = new DialogBox("mapbox");
                            mapBox.showDialog();
                            document.getElementById('mapboxtitlebar').innerText = `Loading...`;
                            // Only update the map on click, with current location details
                            if (clicked[d] === 0) {
                                clicked[d] = 1;   

                                try {
                                    // Fetch and display the map with the clicked point's location
                                    const location = await getCurrentLocation(imei, chart.data.datasets[i].data[d].x);

                                    showMap(location.longitude, location.latitude);
                                    document.getElementById('mapboxtitlebar').innerText = `Speed: ${location.speed} km/h`;
                                } catch (e) {
                                    document.getElementById('mapboxtitlebar').innerText = 'Failed to load location';
                                }
                            } else {
                                clicked[d] = 0;
                                
                            }
                        }
                    }
                }
            }
        }
    };
    return clickablePoints;
}
function hexToRgb(hex) {
    const hexInt = parseInt(hex.slice(1), 16);
    const r = (hexInt >> 16) & 255;
    const g = (hexInt >> 8) & 255;
    const b = hexInt & 255;

    return { r, g, b };
}

function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function tintColour(hex, percent) {
    let { r, g, b } = hexToRgb(hex);
    r = Math.min(255, Math.floor(r + (255 - r) * percent / 35));
    g = Math.min(255, Math.floor(g + (255 - g) * percent / 85));
    b = Math.min(255, Math.floor(b + (255 - b) * percent / 85));

    return rgbToHex(r, g, b);
}

function hexToHsl(hex) {
    let { r, g, b } = hexToRgb(hex);
    r /= 255;
    g /= 255;
    b /= 255;

    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return { h, s, l };
}

function hslToHex(h, s, l) {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }

        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return rgbToHex(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255));
}

function adjustHue(hex, degree) {
    let { h, s, l } = hexToHsl(hex);
    h = (h + degree / 360) % 1;
    return hslToHex(h, s, l);
}

function complementaryColor(hex) {
    let hexInt = parseInt(hex.slice(1), 16);
    let r = (hexInt >> 16) & 255;
    let g = (hexInt >> 8) & 255;
    let b = hexInt & 255;

    // Calculate complementary color
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;

    return rgbToHex(r, g, b);
}

function createPressureChart(imei, chartData, timeSpan) {

   try { 

        document.body.style.cursor = 'wait';
        gPressureChart = new Chart(gPressureCtx, getPressureChartData(imei, chartData, timeSpan));
        document.body.style.cursor = 'default';
    } catch (err) {

        const canvas = document.getElementById('sensorreportpressurechartcanvas');

        if (canvas) {
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

export function getPressureChartData(imei, chartData, timeSpan) {

    try {

        const pressureValueRange = [chartData[0].minPressure, chartData[0].maxPressure, chartData[0].recommendedPressure];
        const description = ["Min Press: ", "Max Press: ", "Rec Press: "];
        let annotations = [];
        if (chartData[0].minPressure != null) {
            annotations = pressureValueRange.map(function (outOfRangeValue, index) {
                let colour = PRIMARY_COLOUR;
                let position = 'center';
                let backgroundColour = '#eee';
                if (index === 0) {
                    colour = LOW_PRESSURE_COLOUR; //'#ad0505'
                    position = 'start';
                }
                if (index === 1) {
                    colour = HIGH_PRESSURE_COLOUR;
                    position = 'start';
                }
                if (index === 2) {
                    colour = ACCENT_COLOUR;
                    position = 'start';
                }
                return {
                    type: 'line',
                    id: 'hline' + index,
                    mode: 'horizontal',
                    drawTime: 'beforeDatasetsDraw',
                    yMin: outOfRangeValue,
                    yMax: outOfRangeValue,
                    borderColor: colour,
                    borderWidth: 1,
                    label: {
                        display: true,
                        padding: {
                            left: 2,
                            right: 2,
                        },
                        width: 50,
                        position: position,
                        color: colour,
                        backgroundColor: backgroundColour,
                        content: description[index] + outOfRangeValue + ' bar'
                    }
                };
            });
        }

        const label = chartData[0].axle === SPARE_AXLE ? `S-${chartData[0].wheelId.slice(0, 1) }` : 'A' + parseInt(chartData[0].axle) + '-T' + chartData[0].wheelId.slice(0, 1)
        const chartAxesData = getPressureChartAxesData(chartData, PRIMARY_COLOUR);

        const maxY = Math.max(...chartAxesData.dataPoints.map(c => c?.y || 0));
        gPressureChartMaxY = Math.round(Math.max(maxY, chartData[0].maxPressure) + 1);

        const timeLabel = getxAxesTimeLabelFormat(timeSpan);

        const tooltipLine = tooltipLines(imei, ACCENT_COLOUR);
        const pressureClickablePoints = setClickablePoints(chartAxesData.clicked, imei);

        const sensorValuesChartData = {
            type: 'line',
            data: {
                datasets: [{
                    label: label,
                    backgroundColor: chartAxesData.pointBackgroundColours,
                    borderColor: chartAxesData.borderColours,                    
                    pointBackgroundColor: chartAxesData.pointBackgroundColours, // Use the stored color
                    pointBorderColor: chartAxesData.pointBorderColours, // Use the stored color
                    segment: chartAxesData.segmentColourConfig,
                    data: chartAxesData.dataPoints,
                    borderWidth: 0.5,
                    radius: 0.85,
                    hitRadius: 4.5,
                    hoverRaius: 4.5,
                    tension: 0.5,
                    pointHoverRadius: 4.5,                   
                }]
            },
            plugins: [tooltipLine, pressureClickablePoints],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        max: gPressureChartMaxY,
                        ticks: {
                            stepSize: 0.5,
                        }
                    },
                    x: {
                        scaleLabel: {
                            display: true
                        },
                        type: 'time',
                        time: {
                            unit: timeLabel.unit,
                            displayFormats: {
                                hour: timeLabel.timeFormat,
                                day: timeLabel.timeFormat
                            },
                            tooltipFormat: "DD MMM HH:mm:ss"
                        },
                        position: "bottom"
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: () => {
                                return `Scroll & click data point to view map`;
                            },
                            label: (context) => {
                                const date = context.label;
                                return [`${date}`, `${context.dataset.label}`];
                            },
                            afterBody: (context) => {
                                const xtraData = [];
                                const sensorId = context[0].raw.sensorId.toString();
                                xtraData.push(`Sensor Id: ${sensorId}`);
                                const temperature = context[0].raw.temperature.toString();
                                const voltage = context[0].raw.voltage.toString();
                                xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} bar  ${temperature} \u2103  ${voltage} V`);
                                return xtraData;
                            }
                        },
                    },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            mode: "xy",
                        }
                    },
                    annotation: {
                        annotations: annotations
                    },
                },
                layout: {
                    padding: {
                        bottom: 25,
                    },
                },

            },
        };

        return sensorValuesChartData;

        //gPressureChart = new Chart(gPressureCtx, sensorValuesChartData);

        //document.body.style.cursor = 'default';
    } catch (err) {

    }
}

export function getPressureChartAxesData(chartData, currentColour) {

    const chartAxesData = {};

    let clicked = [];
    let pointBackgroundColours = [];
    let pointBorderColours = [];
    let borderColours = [];
    let dataPoints = [];

    //let currentColour = PRIMARY_COLOUR;
    let previousSensorId = null;

    for (let i = 0; i < chartData.length; i++) {
        clicked.push(0);

        let xyData = {};
        xyData.y = chartData[i].pressure;
        xyData.temperature = chartData[i].temperature;
        xyData.voltage = chartData[i].voltage;
        xyData.x = chartData[i].time;
        xyData.sensorId = chartData[i].sensorId;

        if (previousSensorId !== null && chartData[i].sensorId !== previousSensorId) {
            //currentColour = tintColour(currentColour, 25); // Adjust the percentage as needed
            currentColour = adjustHue(currentColour, 180); // Adjust the percentage as needed
            //currentColour = complementaryColor(currentColour); 
        }

        pointBackgroundColours.push(currentColour); // Store the colour with the data point
        pointBorderColours.push(currentColour);
        borderColours.push(currentColour);

        dataPoints.push(xyData);

        previousSensorId = chartData[i].sensorId;
    }

    // Segment color configuration based on sensor ID changes
    chartAxesData.segmentColourConfig = {
        borderColor: (ctx) => {
            const index = ctx.p1DataIndex;
            return pointBorderColours[index];
        }
    };
    
    chartAxesData.pointBackgroundColours = pointBackgroundColours;
    chartAxesData.pointBorderColours = pointBorderColours;
    chartAxesData.borderColours = borderColours;
    chartAxesData.clicked = clicked;
    chartAxesData.dataPoints = dataPoints;
    return chartAxesData;

}

function createTemperatureChart(imei, chartData, timeSpan) {

    try {

        document.body.style.cursor = 'wait';
        gTemperatureChart = new Chart(gTemperatureCtx, getTemperatureChartData(imei, chartData, timeSpan));
        document.body.style.cursor = 'default';
    } catch (err) {

        const canvas = document.getElementById('sensorreporttemperaturechartcanvas');

        if (canvas) {
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

export function getTemperatureChartData(imei, chartData, timeSpan) {

    try {
        document.body.style.cursor = 'wait';               

        let annotations = [];
        if (chartData[0].maxTemperature != null) {
            annotations = [{
                type: 'line',
                id: 'hline',
                mode: 'horizontal',
                drawTime: 'beforeDatasetsDraw',
                /*scaleID: 'y-axis-0',*/
                yMin: chartData[0].maxTemperature,
                yMax: chartData[0].maxTemperature,
                borderColor: HIGH_TEMPERATURE_COLOUR,
                borderWidth: 1,
                label: {
                    display: true,
                    padding: {
                        left: 2,
                        right: 2,
                    },
                    position: 'start',
                    backgroundColor: '#eeeeee',
                    color: HIGH_TEMPERATURE_COLOUR,
                    content: 'Max Temp: ' + chartData[0].maxTemperature + '\u2103'
                }
            }];
        }

        const label = chartData[0].axle === SPARE_AXLE ? `S-${chartData[0].wheelId.slice(0, 1)}` : 'A' + parseInt(chartData[0].axle) + '-T' + chartData[0].wheelId.slice(0, 1);

        const chartAxesData = getTemperatureChartAxesData(chartData, PRIMARY_COLOUR);

        const maxY = Math.max(...chartAxesData.dataPoints.map(c => c?.y || 0));
        gTemperatureChartMaxY = Math.round(Math.max(maxY, chartData[0].maxTemperature) + 5);

        const timeLabel = getxAxesTimeLabelFormat(timeSpan);

        const tooltipLine = tooltipLines(imei, HIGH_TEMPERATURE_COLOUR);
        const temperatureClickablePoints = setClickablePoints(chartAxesData.clicked, imei);

    const sensorValuesChartData = {
        type: 'line',
        data: {
            datasets: [{
                label: label,
                afterLabel: + ' \u2103',
                //backgroundColor: PRIMARY_COLOUR,
                backgroundColor: chartAxesData.pointBackgroundColours,
                borderColor: chartAxesData.borderColours,
                pointBackgroundColor: chartAxesData.pointBackgroundColours, // Use the stored color
                pointBorderColor: chartAxesData.pointBorderColours, // Use the stored color
                segment: chartAxesData.segmentColourConfig,
                fill: false,
                //borderColor: PRIMARY_COLOUR,
                data: chartAxesData.dataPoints,
                borderWidth: 0.5,
                radius: 0.85,
                hitRadius: 4.5,
                hoverRaius: 4.5,
                tension: 0.5,
                pointHoverRadius: 4.5
            },]
        },
        plugins: [tooltipLine, temperatureClickablePoints],
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    max: gTemperatureChartMaxY,
                    ticks: {
                        stepSize: 5,
                    }
                },
                x: {
                    scaleLabel: {
                        display: true
                    },
                    type: "time",
                    time: {
                        unit: timeLabel.unit,
                        displayFormats: {
                            hour: timeLabel.timeFormat,
                            day: timeLabel.timeFormat
                        },
                        tooltipFormat: "DD MMM HH:mm:ss"
                    },
                    position: "bottom"
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => {
                            return `Scroll & click data point to view map`;
                        },
                        label: (context) => {
                            const date = context.label;
                            return [`${date}`, `${context.dataset.label}`];
                        },
                        afterBody: (context) => {
                            const xtraData = [];
                            const sensorId = context[0].raw.sensorId.toString();
                            xtraData.push(`Sensor Id: ${sensorId}`);
                            const pressure = context[0].raw.pressure.toString();
                            const voltage = context[0].raw.voltage.toString();
                            //xtraData.push(context[0].label); //date and time
                            xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} \u2103  ${pressure} bar  ${voltage} V`);
                            return xtraData;
                        }
                    },
                },
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true,

                        },
                        mode: "xy",
                    }
                },
                annotation: {
                    annotations: annotations
                },
            },
            layout: {
                padding: {
                    bottom: 25,
                },
            },
            registry: {
            }
        }
        };

        return sensorValuesChartData;
    } catch (err) {
    }
}

export function getTemperatureChartAxesData(chartData, currentColour) {

    const chartAxesData = {};

    let clicked = [];
    let pointBackgroundColours = [];
    let pointBorderColours = [];
    let borderColours = [];
    let dataPoints = [];

    //let currentColour = PRIMARY_COLOUR;
    let previousSensorId = null;

    for (let i = 0; i < chartData.length; i++) {
        clicked.push(0);

        let xyData = {};
        xyData.y = chartData[i].temperature;
        xyData.x = chartData[i].time;
        xyData.sensorId = chartData[i].sensorId;
        xyData.pressure = chartData[i].pressure;
        xyData.voltage = chartData[i].voltage;

        if (previousSensorId !== null && chartData[i].sensorId !== previousSensorId) {
            //currentColour = tintColour(currentColour, 25); // Adjust the percentage as needed
            currentColour = adjustHue(currentColour, 180); // Adjust the percentage as needed
            //currentColour = complementaryColor(currentColour); 
        }

        pointBackgroundColours.push(currentColour); // Store the colour with the data point
        pointBorderColours.push(currentColour);
        borderColours.push(currentColour);

        dataPoints.push(xyData);

        previousSensorId = chartData[i].sensorId;
    }

    // Segment color configuration based on sensor ID changes
    chartAxesData.segmentColourConfig = {
        borderColor: (ctx) => {
            const index = ctx.p1DataIndex;
            return pointBorderColours[index];
        }
    };

    chartAxesData.pointBackgroundColours = pointBackgroundColours;
    chartAxesData.pointBorderColours = pointBorderColours;
    chartAxesData.borderColours = borderColours;
    chartAxesData.clicked = clicked;
    chartAxesData.dataPoints = dataPoints;
    return chartAxesData;
}

function createVoltageChart(imei, chartData, timeSpan) {

    try {
        document.body.style.cursor = 'wait';
        gVoltageChart = new Chart(gVoltageCtx, getVoltageChartData(imei, chartData, timeSpan));
        document.body.style.cursor = 'default';
    } catch (err) {
        const canvas = document.getElementById('sensorreportvoltagechartcanvas');
        if (canvas) {
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

export function getVoltageChartData(imei, chartData, timeSpan) {

    try {
        document.body.style.cursor = 'wait';

        let annotations = [];
        if (chartData[0].minVoltageValue != null) {
            annotations = [{
                type: 'line',
                id: 'hline',
                mode: 'horizontal',
                drawTime: 'beforeDatasetsDraw',
                /*scaleID: 'y-axis-0',*/
                yMin: chartData[0].minVoltageValue,
                yMax: chartData[0].minVoltageValue,
                borderColor: LOW_VOLTAGE_COLOUR,
                borderWidth: 1,
                label: {
                    display: true,
                    padding: {
                        left: 2,
                        right: 2,
                    },
                    position: 'start',
                    backgroundColor: '#eeeeee',
                    color: LOW_VOLTAGE_COLOUR,
                    content: 'Min Volt: ' + chartData[0].minVoltageValue + 'V'
                }
            }];
        }        

        const label = chartData[0].axle === SPARE_AXLE ? `S-${chartData[0].wheelId.slice(0, 1)}` : 'A' + parseInt(chartData[0].axle) + '-T' + chartData[0].wheelId.slice(0, 1);

        const chartAxesData = getVoltageChartAxesData(chartData, PRIMARY_COLOUR);

        const maxY = Math.max(...chartAxesData.dataPoints.map(c => c?.y || 0));
        gVoltageChartMaxY = Math.round(Math.max(maxY, chartData[0].minVoltageValue) + 1);

        const timeLabel = getxAxesTimeLabelFormat(timeSpan);

        const tooltipLine = tooltipLines(imei, LOW_VOLTAGE_COLOUR);
        const voltageClickablePoints = setClickablePoints(chartAxesData.clicked, imei);

        const sensorValuesChartData = {
            type: 'line',
            data: {
                datasets: [{
                    label: label,
                    afterLabel: + ' V',
                    //backgroundColor: PRIMARY_COLOUR,
                    backgroundColor: chartAxesData.pointBackgroundColours,
                    borderColor: chartAxesData.borderColours,
                    pointBackgroundColor: chartAxesData.pointBackgroundColours, // Use the stored color
                    pointBorderColor: chartAxesData.pointBorderColours, // Use the stored color
                    segment: chartAxesData.segmentColourConfig,
                    fill: false,
                    //borderColor: PRIMARY_COLOUR,
                    data: chartAxesData.dataPoints,
                    borderWidth: 0.5,
                    radius: 0.85,
                    hitRadius: 4.5,
                    hoverRaius: 4.5,
                    tension: 0.5,
                    pointHoverRadius: 4.5
                },]
            },
            plugins: [tooltipLine, voltageClickablePoints],
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        max: gVoltageChartMaxY,
                        ticks: {
                            stepSize: 0.5,
                        }
                    },
                    x: {
                        scaleLabel: {
                            display: true
                        },
                        type: "time",
                        time: {
                            unit: timeLabel.unit,
                            displayFormats: {
                                hour: timeLabel.timeFormat,
                                day: timeLabel.timeFormat
                            },
                            tooltipFormat: "DD MMM HH:mm:ss"
                        },
                        position: "bottom"
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: () => {
                                return `Scroll & click data point to view map`;
                            },
                            label: (context) => {
                                const date = context.label;
                                return [`${date}`, `${context.dataset.label}`];
                            },
                            afterBody: (context) => {
                                const xtraData = [];
                                const sensorId = context[0].raw.sensorId.toString();
                                xtraData.push(`Sensor Id: ${sensorId}`);
                                const pressure = context[0].raw.pressure.toString();
                                const temperature = context[0].raw.temperature.toString();
                                //xtraData.push(context[0].label); //date and time
                                xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} V  ${pressure} bar  ${temperature} \u2103`);
                                return xtraData;
                            }
                        },
                    },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,

                            },
                            mode: "xy",
                        }
                    },
                    annotation: {
                        annotations: annotations
                    },
                },
                layout: {
                    padding: {
                        bottom: 25,
                    },
                },
                registry: {
                }
            }
        };

        return sensorValuesChartData;
    } catch (err) {
    }
}

export function getVoltageChartAxesData(chartData, currentColour) {

    const chartAxesData = {};

    let clicked = [];
    let pointBackgroundColours = [];
    let pointBorderColours = [];
    let borderColours = [];
    let dataPoints = [];

    //let currentColour = PRIMARY_COLOUR;
    let previousSensorId = null;

    for (let i = 0; i < chartData.length; i++) {
        clicked.push(0);

        let xyData = {};
        xyData.y = chartData[i].voltage;
        xyData.x = chartData[i].time;
        xyData.sensorId = chartData[i].sensorId;
        xyData.pressure = chartData[i].pressure;
        xyData.temperature = chartData[i].temperature;

        if (previousSensorId !== null && chartData[i].sensorId !== previousSensorId) {
            //currentColour = tintColour(currentColour, 25); // Adjust the percentage as needed
            currentColour = adjustHue(currentColour, 180); // Adjust the percentage as needed
            //currentColour = complementaryColor(currentColour); 
        }

        pointBackgroundColours.push(currentColour); // Store the colour with the data point
        pointBorderColours.push(currentColour);
        borderColours.push(currentColour);

        dataPoints.push(xyData);

        previousSensorId = chartData[i].sensorId;
    }

    // Segment color configuration based on sensor ID changes
    chartAxesData.segmentColourConfig = {
        borderColor: (ctx) => {
            const index = ctx.p1DataIndex;
            return pointBorderColours[index];
        }
    };

    chartAxesData.pointBackgroundColours = pointBackgroundColours;
    chartAxesData.pointBorderColours = pointBorderColours;
    chartAxesData.borderColours = borderColours;
    chartAxesData.clicked = clicked;
    chartAxesData.dataPoints = dataPoints;
    return chartAxesData;

}

function createVoltageChart1(imei, chartData, timeSpan) {

    try {
    document.body.style.cursor = 'wait';

    let chartAxesData = [];
 
    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].voltage;
        xyData.x = chartData[i].time;
        xyData.sensorId = chartData[i].sensorId;
        xyData.pressure = chartData[i].pressure;
        xyData.temperature = chartData[i].temperature;
        chartAxesData.push(xyData);
    }

    let timeLabel = getxAxesTimeLabelFormat(timeSpan);

    let maxY = Math.max(...chartAxesData.map(c => c?.y || 0));
    gVoltageChartMaxY = Math.round(Math.max(maxY, chartData[0].minVoltageValue) + 1);

    let annotations = [];
    if (chartData[0].minVoltageValue != null) {
        annotations = [{
            type: 'line',
            id: 'hline',
            mode: 'horizontal',
            drawTime: 'beforeDatasetsDraw',
            /*scaleID: 'y-axis-0',*/
            yMin: chartData[0].minVoltageValue,
            yMax: chartData[0].minVoltageValue,
            borderColor: LOW_VOLTAGE_COLOUR,
            borderWidth: 1,
            label: {
                display: true,
                padding: {
                    left: 2,
                    right: 2,
                },
                position: 'start',
                backgroundColor: '#eeeeee',
                color: LOW_VOLTAGE_COLOUR,
                content: 'Min Volt: ' + chartData[0].minVoltageValue + 'V'
            }
        }];
    }

        const tooltipLine = tooltipLines(imei, LOW_VOLTAGE_COLOUR);
        const clickablePoints = setClickablePoints(chartAxesData.clicked, imei);

    const sensorValuesChartData = {
        type: 'line',
        data: {
            datasets: [{
                label: 'A' + chartData[0].axle + '-T' + chartData[0].wheelId.slice(0, 1),
                backgroundColor: PRIMARY_COLOUR,
                fill: false,
                borderColor: PRIMARY_COLOUR,
                data: chartAxesData,
                borderWidth: 0.5,
                radius: 0.85,
                hitRadius: 4.5,
                hoverRaius: 4.5,
                tension: 0.5,
                pointHoverRadius: 4.5
            }],
        },
        plugins: [tooltipLine, clickablePoints],
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    max: gVoltageChartMaxY,
                    ticks: {
                        stepSize: 0.5,
                    }
                },
                x: {
                    scaleLabel: {
                        display: true
                    },
                    type: "time",
                    time: {
                        unit: timeLabel.unit,
                        displayFormats: {
                            hour: timeLabel.timeFormat,
                            day: timeLabel.timeFormat
                        },
                        tooltipFormat: "DD MMM HH:mm:ss"
                    },
                    position: "bottom"
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => {
                            return `Scroll & click data point to view map`;
                        },
                        label: (context) => {
                            const date = context.label;
                            return [`${date}`, `${context.dataset.label}`];
                        },
                        afterBody: (context) => {
                            const xtraData = [];
                            const sensorId = context[0].raw.sensorId;
                            xtraData.push(`Sensor Id: ${sensorId}`);
                            const pressure = context[0].raw.pressure.toString();
                            const temperature = context[0].raw.temperature.toString();
                            //xtraData.push(context[0].label); //date and time
                            xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} V  ${pressure} bar  ${temperature} \u2103`);
                            return xtraData;
                        }
                    },
                },
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        mode: "xy",
                    }
                },
                annotation: {
                    annotations: annotations
                },
            },
            layout: {
                padding: {
                    bottom: 25,
                },
            },
            registry: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.yLabel + ' V';
                        },
                    }
                },
            }
        }
    };

    gVoltageChart = new Chart(gVoltageCtx, sensorValuesChartData);

        document.body.style.cursor = 'default';
    } catch (err) {
        const canvas = document.getElementById('sensorreportvoltagechartcanvas');

        if (canvas) {
            // Remove the canvas element
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

function getxAxisLabels(chartData, timespan) {

    let xAxisLabels = [];
    switch (timespan) {
        case 'TODAY':
            for (let i = 0; i < chartData.length; i++) {
                xAxisLabels.push(chartData[i].time.toString().slice(16, 24));
            }
            //xAxisLabels = getLast24Hours()
            break;
        case 'SEVEN DAYS':
            for (let i = 0; i < chartData.length; i++) {
                //xAxisLabels.push(chartData[i].time.toString().slice(0, 15));
                xAxisLabels.push(chartData[i].time);
            }
            break;
        case 'THIRTY DAYS':
            for (let i = 0; i < chartData.length; i++) {
                xAxisLabels.push(chartData[i].time.toString().slice(4, 15));
            }
            break;
        case 'NINETY DAYS':
            for (let i = 0; i < chartData.length; i++) {
                xAxisLabels.push(chartData[i].time.toString().slice(4, 15));
            }
            break;
    }
    return xAxisLabels;
}

export { openSensorReport };