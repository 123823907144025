import { getUnits } from "./pressuretrack";

async function getResourceNotifications() {

    try {
        let flags = window.wialon.item.Item.dataFlag.base | window.wialon.item.Resource.dataFlag.base | window.wialon.item.Item.dataFlag.messages | window.wialon.item.Resource.dataFlag.notifications;
        session.loadLibrary("resourceNotifications");
        //let result = [];
        return await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_resource", flags: flags, mode: 1 }],
                (error) => {
                    if (error) {
                        console.log("notificationsJS: getResourceNotifications - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + "))";
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }
                    //let notifications = [];

                    let resourceNotifications = { resourceId: resourceId, resourceName: resource.getName(), notifications: resource.getNotifications() };
                    //notifications.push(resourceNotifications);
                    //console.log("notificationsJS: getResourceNotifications - SUCCESS");
                    resolve(resourceNotifications);
                },
            );
        });
    } catch (e) {
        console.error('notificationsJS: getResourceNotifications - ERROR: ', e);
    }
}

async function getNotificationData(notificationIds) {

    let temp = 0;
    try {
        return await new Promise((resolve, reject) => {
            global.resource.getNotificationsData(
                notificationIds, 1,
                (error, data) => {
                    if (error) {
                        console.log("notificationsJS: getNotificationData - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }
                    //console.log("notificationsJS: getNotificationData - SUCCESS");
                    resolve(data);
                },
            );
        });
    } catch (e) {
        console.error('notificationsJS: getNotificationData - ERROR: ', e);
    }
}

async function updateReplacedSensorNotifications(replacedSensor) {

    let metricCounter = 0;
    let notificationCounterCheck = 0;
    let notifications = await getResourceNotifications();

    for (let n = 0; n < notifications.length; n++) {
        let allNotifications = notifications[n].notifications;

        for (let key in allNotifications) {
            // skip loop if the property is from prototype
            if (!allNotifications.hasOwnProperty(key)) continue;

            let obj = allNotifications[key];
            //if (valuesOutOfRangeParams.notificationId === key) {}
            //let sensorAxe = obj.n.substring(obj.n.length - 10).substring(0, 1);
            //sensorMetric = obj.n.substring(obj.n.length - 11).substring(0, 1);
            if (replacedSensor.currentSensorId === obj.n.slice(obj.n.length - 8)) {
                let sensorMetric = obj.n.substring(obj.n.length - 11).substring(0, 1);
                metricCounter++;
                let updatedReplacedSensorNotification = await updateReplacedSensorNotification(obj, sensorMetric, replacedSensor.newSensorId);
                if (updatedReplacedSensorNotification) {
                    notificationCounterCheck++;
                    console.log("notificationsJS: updateReplacedSensorNotifications (updated notification " + obj.n + " for replaced sensor) - SUCCESS");
                }
            }
            if (metricCounter === 3) break;
        }
    }

    if (metricCounter === notificationCounterCheck) {
        global.selectedUnitId = replacedSensor.id;
        $("#loadingBox").modal("hide");
        //getUnits();
    }
}

async function updateReplacedSensorNotification(notification, metric, newSensorId) { //create notification

    let resource = global.resource;
    let resourceId = resource.getId();

    //var from = window.wialon.core.Session.getInstance().getServerTime(); // get server time (activation time)
    //var to = from + 3600 * 24 * 7; // calculate deactivation time (activation + 1 week)

    const notificationId = notification.id;
    const notificationTriggerflag = notification.fl;

    let actionTypes = [];
    actionTypes = await getNotificationActionTypes(notificationId);

    const notificationName = notification.n.slice(0, notification.n.length - 8) + newSensorId;
    const wheelId = notificationName.slice(notificationName.length - 4);
    const metricSensorName = notificationName.slice(notificationName.length - 5);
    const axle = parseInt(metricSensorName.slice(2, 4));
    const tyre = metricSensorName.slice(1, 2);
    const axleTyre = "A" + axle + "-T" + tyre;

    let imei = parseInt(notification.un[0]);
    let minValue = parseFloat(notification.trg_p.lower_bound);
    let maxValue = parseFloat(notification.trg_p.upper_bound);
    let alarmType = "Pressure";
    switch (metric) {
        case 'P':
            alarmType = "Pressure";
            break;
        case 'T':
            alarmType = "Temperature";
            break;
        case 'V':
            alarmType = "Voltage";
            break;
    }

    let obj = {
        n: notificationName,
        ta: 1602201600, td: 0, tz: 134224928, la: "en", ma: 0,
        sch: { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
        "ctrl_sch": { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
        "un": [imei],
        "trg": {
            "t": "sensor_value",
            "p": {
                "sensor_type": "custom", "sensor_name_mask": metricSensorName, "lower_bound": minValue, "upper_bound": maxValue,
                "prev_msg_diff": 0, "merge": 0, "type": 1
            }
        }, "d": "",
        "act": actionTypes,
        "txt": "%UNIT%-" + wheelId + ": " + alarmType + " alarm triggered with value of %SENSOR_VALUE% on Axle " + axle + " Tyre " + tyre + " (" + axleTyre + "), at %POS_TIME%.  The unit moved at a speed of %SPEED% near %LOCATION%.",
        "fl": 0, "mast": 0, "mpst": 2520, "cdt": 0, "mmtd": 0, "cp": 0, "id": notificationId, "itemId": resourceId
    };

    let updatedReplacedSensorNotification = await updateNotification(obj);
    return updatedReplacedSensorNotification;
}

async function getNotificationActionTypes(notificationId) {

    let actionTypes = [];
    let actionType = {};
    let actionTypeString = '';
    let notificationData = await getNotificationData(notificationId);
    if (notificationData.length === 1) {
        let notificationMethods = notificationData[0].act;
        for (let m = 0; m < notificationMethods.length; m++) {
            if (notificationMethods[m].t === "email") {
                actionTypeString = '{"t": "email", "p": {"email_to": "'
                    + notificationMethods[m].p.email_to + '", "subj": "' + notificationMethods[m].p.subj
                    + '", "html": 0, "img_attach": 0 }}';
            }
            if (notificationMethods[m].t === "messenger_messages") {
                actionTypeString = '{ "t": "messenger_messages", "p": {"token": "6509137951:AAFJF55C9Ly4bZzO9yB1u26RSdwn6pqOYaU", "chat_id": '
                    + notificationMethods[m].p.chat_id + '}}';
            }
            if (notificationMethods[m].t === "event") {
                actionTypeString = '{ "t": "event", "p": {"flags": 0}}';
            }
            if (actionTypeString.length > 0) {
                actionType = JSON.parse(actionTypeString);
                actionTypes.push(actionType);
            }
        }
    }
    return actionTypes;
}

async function updateNotification(notification) {

    let resource = global.resource;
    let temp = 0;

    return await new Promise((resolve, reject) => {
        resource.updateNotification(notification, (error) => {
            if (error) {
                console.log("notificationsJS: updateNotification (" + notification.n + ") - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                resolve(false);
            } else {
                console.log("notificationsJS: updateNotification (" + notification.n + ") - SUCCESS");
                resolve(true);
            }
        });
    });
}

async function updateUnitNameChangeNotification(notificationParams) { //create notification

    let resourceNotifications = await getResourceNotifications();

    let notificationCounter = 0;
    let notificationCounterCheck = 0;
    //for (let n = 0; n < notifications.length; n++) {
        //if (notifications[n].resourceName === 'TyreTrackDemo' || notifications[n].resourceName === '10001') {
    notificationParams.resourceId = resourceNotifications.resourceId;
    let allNotifications = resourceNotifications.notifications;

        for (let key in allNotifications) {
            if (!allNotifications.hasOwnProperty(key)) continue;

            let obj = allNotifications[key];

            if (obj.un[0] === parseInt(notificationParams.id)) {

                notificationCounter++;

                notificationParams.id = obj.id;
                notificationParams.unitToEnable = obj.un[0];
                notificationParams.n = notificationParams.wheelId + "-" + obj.n.substring(obj.n.length - 11);
                notificationParams.fl = obj.fl;

                let sensorMetric = obj.n.substring(obj.n.length - 11).substring(0, 1);

                switch (sensorMetric) {
                    case 'P':
                        notificationParams.minPressure = parseFloat(obj.trg_p.lower_bound);
                        notificationParams.maxPressure = parseFloat(obj.trg_p.upper_bound);
                        notificationParams.alarmType = "Pressure";
                        break;
                    case 'T':
                        notificationParams.minTemperature = parseFloat(obj.trg_p.lower_bound);
                        notificationParams.maxTemperature = parseFloat(obj.trg_p.upper_bound);
                        notificationParams.alarmType = "Temperature";
                        break;
                    case 'V':
                        notificationParams.minVoltage = parseFloat(obj.trg_p.lower_bound);
                        notificationParams.maxVoltage = parseFloat(obj.trg_p.upper_bound);
                        notificationParams.alarmType = "Voltage";
                        break;
                }

                let updatedUnitNotification = await updateUnitAxleNameChangeNotification(obj, notificationParams, sensorMetric);
                if (updatedUnitNotification) {
                    notificationCounterCheck++;
                    console.log("notificationsJS: updateUnitNameChangeNotification (" + notificationParams.wheelId + ") - SUCCESS");
                }
            }
        }
    //}

    if (notificationCounter === notificationCounterCheck) {
        global.selectedUnitId = notificationParams.id;
        $("#loadingBox").modal("hide");
        //getUnits();
    }
}

async function createUnitAxleNotifications(valuesOutOfRangeParams, notifications) {


    let notificationsId = [];
    let sensorPressureMetricFound = false;
    let sensorTemperatureMetricFound = false;
    let sensorVoltageMetricFound = false;
    valuesOutOfRangeParams.pressureActions = [{ "t": "event", "p": { "flags": 0 } }];
    valuesOutOfRangeParams.temperatureActions = [{ "t": "event", "p": { "flags": 0 } }];
    valuesOutOfRangeParams.voltageActions = [{ "t": "event", "p": { "flags": 0 } }];
    for (let key in notifications) {
        if (!notifications.hasOwnProperty(key)) continue;
        let obj = notifications[key];
        if (parseInt(obj.un[0]) === parseInt(valuesOutOfRangeParams.id)) {
            if (obj.act.length > 1) { //actions on other axles not only "register event" but might also be "e-mail' or "telegram"
                notificationsId.push(obj.id); //need only one for other actions
                let sensorMetric = obj.n.substring(obj.n.length - 5).slice(0, 1);
                if (sensorMetric === "p" && !sensorPressureMetricFound) {
                    sensorPressureMetricFound = true;                    
                }
                if (sensorMetric === "t" && !sensorTemperatureMetricFound) {
                    sensorTemperatureMetricFound = true;
                }
                if (sensorMetric === "v" && !sensorVoltageMetricFound) {
                    sensorVoltageMetricFound = true;
                }
                if (sensorPressureMetricFound && sensorTemperatureMetricFound && sensorVoltageMetricFound)
                    break; 
            }
        }
    } 
    let notification = await getNotificationData(notificationsId);
    if (sensorPressureMetricFound)
        valuesOutOfRangeParams.pressureActions = notification[0].act;
    if (sensorTemperatureMetricFound)
        valuesOutOfRangeParams.temperatureActions = notification[0].act;
    if (sensorVoltageMetricFound)
        valuesOutOfRangeParams.voltageActions = notification[0].act;

    let createdNotification = false;
    let notificationCounter = 0;
    let notificationCounterCheck = 0;

    for (let w = 0; w < valuesOutOfRangeParams.wheels.length; w++) {

        //let wheel = "wheel" + w;           

        //if (valuesOutOfRangeParams.hasOwnProperty(wheel)) {

            notificationCounter++;
        createdNotification = await setNotificationOutOfRangeValues("P", valuesOutOfRangeParams, valuesOutOfRangeParams.wheels[w]);
            if (createdNotification) {
                notificationCounterCheck++;
                console.log(valuesOutOfRangeParams.name + "-P" + valuesOutOfRangeParams.wheels[w] + " created successfully.");
            }

            notificationCounter++;
        createdNotification = await setNotificationOutOfRangeValues("T", valuesOutOfRangeParams, valuesOutOfRangeParams.wheels[w]);
            if (createdNotification) {
                notificationCounterCheck++;
                console.log(valuesOutOfRangeParams.name + "-T" + valuesOutOfRangeParams.wheels[w] + " created successfully.");
            }

            notificationCounter++;
        createdNotification = await setNotificationOutOfRangeValues("V", valuesOutOfRangeParams, valuesOutOfRangeParams.wheels[w]);
            if (createdNotification) {
                notificationCounterCheck++;
                console.log(valuesOutOfRangeParams.name + "-V" + valuesOutOfRangeParams.wheels[w] + " created successfully.");
            }
        //}
    }

    if (notificationCounter === notificationCounterCheck) {
        global.selectedUnitId = valuesOutOfRangeParams.id;
        if (valuesOutOfRangeParams.unitLinkedToId)
            global.selectedUnitId = valuesOutOfRangeParams.unitLinkedToId;
        $("#loadingBox").modal("hide");
        //getUnits();
    }
}

async function setNotificationOutOfRangeValues(metric, valuesOutOfRangeParams, wheelId) { 

    let resource = global.resource;

    let imei = parseInt(valuesOutOfRangeParams.id);
    let unitName = valuesOutOfRangeParams.name;
    let minValue = 0;
    let maxValue = 0;

    //switch (wheelNo) {
    //    case 1:
    //        sensorId = valuesOutOfRangeParams.wheel1;
    //        break;
    //    case 2:
    //        sensorId = valuesOutOfRangeParams.wheel2;
    //        break;
    //    case 3:
    //        sensorId = valuesOutOfRangeParams.wheel3;
    //        break;
    //    case 4:
    //        sensorId = valuesOutOfRangeParams.wheel4;
    //        break;
    //}
    let notification = '';
    let alarmType = "Pressure";
    let actions = [];
    switch (metric) {
        case 'P':
            minValue = valuesOutOfRangeParams.minPressure;
            maxValue = valuesOutOfRangeParams.maxPressure;
            notification = unitName + '-P' + wheelId;
            alarmType = "Pressure";
            actions = valuesOutOfRangeParams.pressureActions;
            break;
        case 'T':
            minValue = valuesOutOfRangeParams.minTemperature;
            maxValue = valuesOutOfRangeParams.maxTemperature;
            notification = unitName + '-T' + wheelId;
            alarmType = "Temperature";
            actions = valuesOutOfRangeParams.temperatureActions;
            break;
        case 'V':
            minValue = valuesOutOfRangeParams.minVoltage;
            maxValue = valuesOutOfRangeParams.maxVoltage;
            notification = unitName + '-V' + wheelId;
            alarmType = "Voltage";
            actions = valuesOutOfRangeParams.voltageActions;
            break;
    }

    let axle = parseInt(wheelId.slice(1, 3));
    let tyre = wheelId.slice(0, 1);
    let axleTyre = "A" + axle + "-T" + tyre;
    const metricSensorName = metric + wheelId;
    var obj = {
        n: notification, ta: 1602201600, td: 0, tz: 134224928, la: "en", ma: 0,
        sch: { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
        "ctrl_sch": { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
        "un": [imei],
        "trg": {
            "t": "sensor_value",
            "p": {
                "sensor_type": "custom", "sensor_name_mask": metricSensorName, "lower_bound": minValue, "upper_bound": maxValue,
                "prev_msg_diff": 0, "merge": 0, "type": 1
            }
        }, "d": "",
        "act": actions,
        "txt": "%UNIT%-" + wheelId + ": " + alarmType + " alarm triggered with value of %SENSOR_VALUE% on Axle " + axle + " Tyre " + tyre + " (" + axleTyre + "), at %POS_TIME%.  The unit moved at a speed of %SPEED% near %LOCATION%.",
        "fl": 0, "mast": 0, "mpst": 2520, "cdt": 0, "mmtd": 0, "cp": 0, "id": 0
    };

    return await new Promise((resolve, reject) => {
        resource.createNotification(obj,
            (code) => {
                if (code) {
                    console.log("Create Notification (" + obj.n + ") " + wialon.core.Errors.getErrorText(code));
                    //reject({ type: 'API_ERROR', error: code });
                    resolve(false);
                } else {
                    resolve(true);
                }
            });
    });
}

async function updateUnitAxleNotifications(valuesOutOfRangeParams, notifications) {

    try {
        let imei = parseInt(valuesOutOfRangeParams.id);

        let notificationCounter = 0;
        let notificationCounterCheck = 0;
        let sensorNotificationsIds = [];
        let prms = [];

        for (let key in notifications) {
            if (!notifications.hasOwnProperty(key)) continue;
            if (notificationCounter === 12) break; //max number of notifications per axle
            let obj = notifications[key];
            if (parseInt(obj.un[0]) === imei) {
                const axleUnit = obj.n.slice( -3);
                if (axleUnit === valuesOutOfRangeParams.axle + valuesOutOfRangeParams.unitNumber) {
                    notificationCounter++;
                    sensorNotificationsIds.push(obj.id);
                }
            }
        }
        
        let notificationData = await getNotificationData(sensorNotificationsIds);        
        for (let n = 0; n < notificationData.length; n++) {
            let updatedUnitAxleNotification = await updateUnitAxleNotification(notificationData[n], valuesOutOfRangeParams);
            notificationCounterCheck++;
            if (updatedUnitAxleNotification) {
                let prmsObj = {
                    "svc": "resource/update_notification",
                    "params": updatedUnitAxleNotification
                };
                prms.push(prmsObj);   
            }
        }               

        if (notificationCounter === notificationCounterCheck) {
            await updateNotificationsBatch(prms);
            global.selectedUnitId = valuesOutOfRangeParams.id;
            if (valuesOutOfRangeParams.unitLinkedToId)
                global.selectedUnitId = valuesOutOfRangeParams.unitLinkedToId;
            $("#loadingBox").modal("hide");
            //getUnits();
        }

    } catch (e) {
        console.error('notificationsJS: updateUnitAxleNotifications - ERROR: ', e);
    }
}

async function updateUnitAxleNotification(notification, valuesOutOfRangeParams) { //create notification

    let resource = global.resource;
    let resourceId = resource.getId();

    let notificationId = notification.id;

    //let actionTypes = [];
    //let actionType = {};
    //let actionTypeString = '';
    //let notificationData = await getNotificationData(3??);
    //    let notificationMethods = notificationData[0].act;
    //    for (let m = 0; m < notificationMethods.length; m++) {
    //        if (notificationMethods[m].t === "email") {
    //            actionTypeString = '{"t": "email", "p": {"email_to": "'
    //                + notificationMethods[m].p.email_to + '", "subj": "' + notificationMethods[m].p.subj
    //                + '", "html": 0, "img_attach": 0 }}';
    //        }
    //        if (notificationMethods[m].t === "messenger_messages") {
    //            actionTypeString = '{ "t": "messenger_messages", "p": {"token": "1356769821:AAGAqjEUFsS9_qYmWD2R18wgNoPGwgOXiL4", "chat_id": "' + notificationMethods[m].p.chat_id + '"}}';
    //        }
    //        if (notificationMethods[m].t === "event") {
    //            actionTypeString = '{ "t": "event", "p": {"flags": 0}}';
    //        }
    //        if (actionTypeString.length > 0) {
    //            actionType = JSON.parse(actionTypeString);
    //            actionTypes.push(actionType);
    //        }
    //    }

    const notificationName = notification.n;
    const wheelId = notificationName.slice(-4);
    const metric = notificationName.slice(-5, -4);
    const axle = parseInt(notificationName.slice(-3, -1));
    const tyre = notificationName.slice(-4, -3);
    const axleTyre = "A" + axle + "-T" + tyre;
    const metricSensorName = metric + wheelId;
    const imei = parseInt(valuesOutOfRangeParams.id);
    //if (parseInt(axle) > 3 && parseInt(valuesOutOfRangeParams.trailerId) > 0)
    //    imei = parseInt(valuesOutOfRangeParams.trailerId);
    let minValue = 0;
    let maxValue = 0;
    switch (metric) {
        case 'P':
            if (valuesOutOfRangeParams.minPressure !== parseFloat(notification.trg.p.lower_bound)) {
                minValue = valuesOutOfRangeParams.minPressure;
                maxValue = valuesOutOfRangeParams.maxPressure;
                break;
    } else { return; }
    
        case 'T':
            if (valuesOutOfRangeParams.maxTemperature !== parseInt(notification.trg.p.upper_bound)) {
                minValue = valuesOutOfRangeParams.minTemperature;
                maxValue = valuesOutOfRangeParams.maxTemperature;
                break;
            } else { return; }  
        case 'V':
            if (valuesOutOfRangeParams.minVoltage !== parseFloat(notification.trg.p.lower_bound)) {
                minValue = valuesOutOfRangeParams.minVoltage;
                maxValue = valuesOutOfRangeParams.maxVoltage;
                break;
            } else { return; }

    }

    //let obj = {
    //    n: notificationName,
    //    ta: 1602201600, td: 0, tz: 134224928, la: "en", ma: 0,
    //    sch: { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
    //    "ctrl_sch": { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
    //    "un": [imei],
    //    "trg": {
    //        "t": "sensor_value",
    //        "p": {
    //            "sensor_type": "custom", "sensor_name_mask": wheelId, "lower_bound": minValue, "upper_bound": maxValue,
    //            "prev_msg_diff": 0, "merge": 0, "type": 1
    //        }
    //    }, "d": "",
    //    "act": actionTypes,
    //    "txt": "%UNIT%: " + alarmType + " alarm triggered at %POS_TIME% with a value of %SENSOR_VALUE% on " + axleTyre + " (Sensor Id: %SENSOR_NAME%). The unit moved with a speed of %SPEED% near %LOCATION%.",
    //    "fl": notificationTriggerflag, "mast": 0, "mpst": 2520, "cdt": 0, "mmtd": 0, "cp": 0, "id": notificationId, "itemId": resourceId
    //};

    let objBatch = {
        "id": notificationId, "itemId": resourceId,
        "n": notificationName,
        "ta": 1602201600, "td": 0, "tz": 134224928, "la": "en", "ma": 0,
        "sch": { "f1": 0, "f2": 0, "t1": 0, "t2": 0, "m": 0, "y": 0, "w": 0, "fl": 0 },
        "ctrl_sch": { "f1": 0, "f2": 0, "t1": 0, "t2": 0, "m": 0, "y": 0, "w": 0, "fl": 0 },
        "un": [imei],
        "trg": {
            "t": "sensor_value",
            "p": {
                "sensor_type": "custom", "sensor_name_mask": metricSensorName, "lower_bound": minValue, "upper_bound": maxValue,
                "prev_msg_diff": 0, "merge": 0, "type": 1
            }
        }, "d": "",
        "act": notification.act,
        "txt": notification.txt,
        "fl": 0, "mast": 0, "mpst": 2520, "cdt": 0, "mmtd": 0, "cp": 0,
        "callMode": "update"
    };

    return objBatch;
}

async function updateUnitAxleNameChangeNotification(notification, valuesOutOfRangeParams, metric) { //create notification

    //let session = global.session;
    let resource = global.resource;
    let resourceId = resource.getId();

    //var from = session.getServerTime(); // get server time (activation time)
    //var to = from + 3600 * 24 * 7; // calculate deactivation time (activation + 1 week)

    let notificationId = notification.id;
    let notificationTriggerflag = notification.fl;

    let actionTypes = [];
    let actionType = {};
    let actionTypeString = '';
    let notificationIds = [3,4,5,6,7,9];
    let notificationData = await getNotificationData(notificationIds);
    if (notificationData.length === 1) {
        let notificationMethods = notificationData[0].act;
        for (let m = 0; m < notificationMethods.length; m++) {
            if (notificationMethods[m].t === "email") {
                actionTypeString = '{"t": "email", "p": {"email_to": "'
                    + notificationMethods[m].p.email_to + '", "subj": "' + notificationMethods[m].p.subj
                    + '", "html": 0, "img_attach": 0 }}';
            }
            if (notificationMethods[m].t === "messenger_messages") {
                actionTypeString = '{ "t": "messenger_messages", "p": {"token": "6509137951:AAFJF55C9Ly4bZzO9yB1u26RSdwn6pqOYaU", "chat_id": "' + notificationMethods[m].p.chat_id + '"}}';
                   
            }
            if (notificationMethods[m].t === "event") {
                actionTypeString = '{ "t": "event", "p": {"flags": 0}}';
            }
            if (actionTypeString.length > 0) {
                actionType = JSON.parse(actionTypeString);
                actionTypes.push(actionType);
            }
        }
    }

    const notificationName = notification.n;
    const wheelId = notificationName.slice(notificationName.length - 4);
    const metricSensorName = notificationName.slice(notificationName.length - 5);
    const axle = parseInt(metricSensorName.slice(2, 4));
    const tyre = metricSensorName.slice(1, 2);
    const axleTyre = "A" + axle + "-T" + tyre;

    let imei = parseInt(valuesOutOfRangeParams.id);
    if (parseInt(axle) > 3 && parseInt(valuesOutOfRangeParams.trailerId) > 0)
        imei = parseInt(valuesOutOfRangeParams.trailerId);
    let minValue = 0;
    let maxValue = 0;
    let alarmType = "Pressure";
    switch (metric) {
        case 'P':
            minValue = valuesOutOfRangeParams.minPressure;
            maxValue = valuesOutOfRangeParams.maxPressure;
            alarmType = "Pressure";
            break;
        case 'T':
            minValue = valuesOutOfRangeParams.minTemperature;
            maxValue = valuesOutOfRangeParams.maxTemperature;
            alarmType = "Temperature";
            break;
        case 'V':
            minValue = valuesOutOfRangeParams.minVoltage;
            maxValue = valuesOutOfRangeParams.maxVoltage;
            alarmType = "Voltage";
            break;
    }

    let obj = {
        n: notificationName,
        ta: 1602201600, td: 0, tz: 134224928, la: "en", ma: 0,
        sch: { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
        "ctrl_sch": { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
        "un": [imei],
        "trg": {
            "t": "sensor_value",
            "p": {
                "sensor_type": "custom", "sensor_name_mask": metricSensorName, "lower_bound": minValue, "upper_bound": maxValue,
                "prev_msg_diff": 0, "merge": 0, "type": 1
            }
        }, "d": "",
        "act": actionTypes,
        "txt": "%UNIT%-" + wheelId + ": " + alarmType + " alarm triggered with value of %SENSOR_VALUE% on Axle " + axle + " Tyre " + tyre + " (" + axleTyre + "), at %POS_TIME%.  The unit moved at a speed of %SPEED% near %LOCATION%.",
        "fl": 0, "mast": 0, "mpst": 2520, "cdt": 0, "mmtd": 0, "cp": 0, "id": notificationId, "itemId": resourceId
    };

    //let objBatch = {
    //    "id": notificationId, "itemId": resourceId,
    //    "n": notificationName,
    //    "ta": 1602201600, "td": 0, "tz": 134224928, "la": "en", "ma": 0,
    //    "sch": { "f1": 0, "f2": 0, "t1": 0, "t2": 0, "m": 0, "y": 0, "w": 0, "fl": 0 },
    //    "ctrl_sch": { "f1": 0, "f2": 0, "t1": 0, "t2": 0, "m": 0, "y": 0, "w": 0, "fl": 0 },
    //    "un": [imei],
    //    "trg": {
    //        "t": "sensor_value",
    //        "p": {
    //            "sensor_type": "custom", "sensor_name_mask": wheelId, "lower_bound": minValue, "upper_bound": maxValue,
    //            "prev_msg_diff": 0, "merge": 0, "type": 1
    //        }
    //    }, "d": "",
    //    "act": actionTypes,
    //    "txt": alarmType + " alarm triggered at  with a value of  on " + axleTyre + " (Sensor Id: ). The unit moved with a speed of near .",
    //    "fl": 0, "mast": 0, "mpst": 2520, "cdt": 0, "mmtd": 0, "cp": 0,
    //    "callMode": "update"
    //};

    //return objBatch;


    return await updateNotification(obj);

}

function updateNotificationsBatch(prms) {

    let remote = wialon.core.Remote.getInstance();

    return new Promise((resolve, reject) => {
        remote.remoteCall('core/batch', prms,
            (error, result) => {
                if (error) {
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return;
                }
                resolve(result);
            },
        );
    });
}

function getUnitLinkedTrailers(notificationParams) {

    let units = global.session.getItems("avl_unit");

    if (!units || !units.length) {
        console.log("pressuretrackJS: getUnits - no units found");
        return;
    }

    //Get all trailers that are linked
    let unitLinkedTrailers = [];
    let allTrailers = resource.getTrailers();
    for (let trl in allTrailers) {
        let trailer = allTrailers[trl];
        if (parseInt(notificationParams.id) === parseInt(trailer.bu)) {
            let unitTrailer = {};
            unitTrailer.resourceId = notificationParams.resourceId;
            unitTrailer.unitLinkedToId = imei;
            unitTrailer.trailerId = trailer.id;
            unitTrailer.name = trailer.n;
            unitTrailer.pressureCheck = notificationParams.pressureCheck;
            unitTrailer.temperatureCheck = notificationParams.temperatureCheck;
            unitTrailer.voltageCheck = notificationParams.voltageCheck;
            unitTrailer.emailAddress = notificationParams.emailAddress;
            unitTrailer.telegramChannelId = notificationParams.telegramChannelId;
            unitTrailer.notificationTriggerflag = notificationParams.notificationTriggerflag;

            unitLinkedTrailers.push(unitTrailer);
        }
    }

    for (let i = 0; i < units.length; i++) {
        let u = units[i];
        let imei = u.getId();
        for (let t = 0; t < unitLinkedTrailers.length; t++) {
            if (unitLinkedTrailers[t].name.trim() === u.getName().trim()) {
                unitLinkedTrailers[t].id = imei;
            }
        }
    }

    return unitLinkedTrailers;
}

async function enableUnitNotification(notificationParams) { ///await promise

    //var resource = window.wialon.core.Session.getInstance().getItem(notificationParams.resourceId); //get resource by id
    //if (!resource) { console.log("Unknown resource"); return; }; // check if resource found
    let session = global.session;
    let resource = global.resource;
    let resourceId = resource.getId();

    var from = session.getServerTime(); // get server time (activation time)
    var to = from + 3600 * 24 * 7; // calculate deactivation time (activation + 1 week)

    const notificationId = notificationParams.notificationId;

    const imei = parseInt(notificationParams.id);

    const emailSubject = notificationParams.wheelId + " - Sensor value out of range!";
    const metricSensorName = notificationParams.wheelId.slice(notificationParams.wheelId.length - 5);
    const axle = parseInt(metricSensorName.slice(2, 4));
    const tyre = metricSensorName.slice(1, 2);
    const axleTyre = "A" + axle + "-T" + tyre;

    if (parseInt(notificationParams.telegramChannelId) === 0) notificationParams.telegramChannelId = 9999999999;
    let notificationTriggerflag = notificationParams.notificationTriggerflag;
    //BotToken: 1356769821:AAGAqjEUFsS9_qYmWD2R18wgNoPGwgOXiL4
    //New BotToken 20240327: 6509137951:AAFJF55C9Ly4bZzO9yB1u26RSdwn6pqOYaU
    var obj = {
        n: notificationParams.wheelId,
        ta: 1602201600, td: 0, tz: 134224928, la: "en", ma: 0,
        sch: { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
        "ctrl_sch": { f1: 0, f2: 0, t1: 0, t2: 0, m: 0, y: 0, w: 0, fl: 0 },
        "un": [imei],
        "trg": {
            "t": "sensor_value",
            "p": {
                "sensor_type": "custom", "sensor_name_mask": metricSensorName, "lower_bound": notificationParams.minValue, "upper_bound": notificationParams.maxValue,
                "prev_msg_diff": 0, "merge": 0, "type": 1
            }
        }, "d": "",
        "act": [{
            "t": "email",
            "p": { "email_to": notificationParams.emailAddress, "subj": emailSubject, "html": 0, "img_attach": 0 }
        },
        {
            "t": "messenger_messages",
            "p": { "token": "6509137951:AAFJF55C9Ly4bZzO9yB1u26RSdwn6pqOYaU", "chat_id": notificationParams.telegramChannelId }
        },
        {
            "t": "event",
            "p": { "flags": 0 }
            }],
        "txt": "%UNIT%-" + wheelId + ": " + alarmType + " alarm triggered with value of %SENSOR_VALUE% on Axle " + axle + " Tyre " + tyre + " (" + axleTyre + "), at %POS_TIME%.  The unit moved at a speed of %SPEED% near %LOCATION%.",
        "fl": 0, "mast": 0, "mpst": 2520, "cdt": 0, "mmtd": 0, "cp": 0, "id": notificationId, "itemId": resourceId
    };

    return await updateNotification(obj);
}

export { getResourceNotifications, getNotificationData, updateUnitNameChangeNotification, updateReplacedSensorNotifications};
