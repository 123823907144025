/* 
    ***** Kolossense 3:1-2,12-13,15,17,22-24
    'As julle dan saam met •Christus opgewek is, streef na dit wat daarbo is, waar Christus aan die regterhand van God sit. 
    Bedink die dinge daarbo, nie die dinge op aarde nie. 
    Daarom, omdat julle God se uitverkore •heiliges en geliefdes is, beklee julleself met innige meelewing, goedheid, 
    nederigheid, sagmoedigheid en geduld. 
    Verdra mekaar en, as enigiemand 'n klagte teen iemand anders het, vergewe mekaar. 
    Soos die Here julle vergewe het, so moet julle ook vergewe. Verder, laat die vrede van Christus in julle harte regeer. 
    As lede van een liggaam, is julle juis daartoe geroep. En wees dankbaar. 
    En wat julle ook al doen, in woord of daad, doen alles in die Naam van die Here Jesus, 
    terwyl julle God die Vader deur Hom dank.
    Slawe, julle moet julle aardse eienaars in alle opsigte gehoorsaam – nie met oëdiens asof julle mense wil behaag nie, 
    maar met 'n opregte hart, uit ontsag vir die Here. 
    Wat julle ook al doen, werk met geesdrif, soos vir die Here, en nie soos vir mense nie, 
    in die wete dat julle 'n erfdeel as beloning van die Here sal ontvang; want julle staan in diens van die Here •Christus.'
*/

import { formatZuluTime, formatTime, getPressureLabelColour, getTemperatureLabelColour, getVoltageLabelColour } from "../../../utilities";
import { getSensorHistoryData, getActionLogData, updateActionLog, updateTrailerName, updateTyreInfo } from "../../../data/sensordata";
import { editTrailer } from "../../../data/unitdata";


import { getWheelProperties, updateUnitMetrics } from "../units";
import {
    createSensorValuesDiagramButtonsElement,
    createSensorValuesDiagramOptions,
    createSensorValuesDiagramSpareOptions,
    createSensorValuesDiagramUnpairedOptions,
    createSensorValuesDiagramOptionsElement
} from "./sensorvaluesdiagram";

import { createWheelsDiagramButtonsElement, createAxleInfoElement, openValuesOutOfRangeForm } from "./wheelsdiagram";
import { createReportsAxleElements } from '../../../reports';
import { openSensorReport } from "../../../sensorchartreport";
import { openNotificationsForm  } from "./wheelsdiagram";

import { cloneObject, addLeadingZero, convertUnixTime, formatDate } from "../../../utilities";
import { SPARE_AXLE, UNPAIRED_AXLE } from '../../../global';

//var gRegisterUnitEvent = false;

export function createDiagramView(unitdata, diagramView, container) {

    const diagram = document.getElementById(container);
    while (diagram.hasChildNodes()) {
        diagram.removeChild(diagram.lastChild);
    }
    const diagramFragment = document.createDocumentFragment();

    let diagramDiv = document.createElement('div');
    diagramDiv.id = `${container}diagram`;
    diagramDiv.className = 'unitdiagram';

    diagramFragment.appendChild(diagramDiv);
    diagram.appendChild(diagramFragment);

    if (diagramView === 'SENSORVALUESDIAGRAM') {
        const sensorValuesDiagramButtons = createSensorValuesDiagramButtonsElement(unitdata);
        diagramDiv.appendChild(sensorValuesDiagramButtons);

        const diagramMetricOptionsDiv = createSensorValuesDiagramOptionsElement(unitdata);
        diagramDiv.appendChild(diagramMetricOptionsDiv);
    }
    if (diagramView === 'WHEELSDIAGRAM') {
        const wheelsDiagramButtons = createWheelsDiagramButtonsElement(unitdata);
        diagramDiv.appendChild(wheelsDiagramButtons);
    }
    getDiagramConfiguration(unitdata, diagramView, diagramDiv);
}

async function getDiagramConfiguration(unit, diagramView, diagramDiv) {

    //const resourceNotifications = await getResourceNotifications();

    //unit = getWheelPropertiesTest(unit);
    unit.wheelSensors = getWheelProperties(unit.wheelSensors);
    unit.spareSensors = getWheelProperties(unit.spareSensors);
    unit.unpairedSensors = getWheelProperties(unit.unpairedSensors);    

    //const unitNumbers = [...new Set(Object.keys(unit.wheelSensors).map(item => unit.wheelSensors[item].wheelId.slice(3, 4)))].sort();

    const unitConfig = unit.unitConfig;
    for (let un = 0; un < unitConfig.length; un++) {

        const unitDiv = createUnitElement(diagramView, un, unit);
        diagramDiv.appendChild(unitDiv);

        const axles = unitConfig[un].axles;
        for (let a = 0; a < axles.length; a++) {
            if (diagramView === 'REPORTSDIAGRAM') {
                const wheelConfig = unit.unitConfig[un].axles[a].wheelConfig;
                const reportsAxleDiv = createReportsAxleElements(unit, un, a + +1, wheelConfig);
                unitDiv.appendChild(reportsAxleDiv);
            } else {
                const axleDiv = createAxleElements(diagramView, unit, un, a + +1);
                //createReportsAxleElements(unit, unitNumber, axle)
                unitDiv.appendChild(axleDiv);
            }
        }
    }
    if (unit.spareSensors.length) {
        const unitSpareDiv = createUnitElement(diagramView, SPARE_AXLE, unit);
        diagramDiv.appendChild(unitSpareDiv);

        if (diagramView === 'REPORTSDIAGRAM') {
            const reportsSpareAxleDiv = createReportsAxleElements(unit, 0, 'SPARE', null);
            unitSpareDiv.appendChild(reportsSpareAxleDiv);
        } else {
            const spareAxleDiv = createSpareAxleElements(diagramView, unit);
            unitSpareDiv.appendChild(spareAxleDiv);
        }
    }
    if (unit.unpairedSensors.length && diagramView === 'SENSORVALUESDIAGRAM') {
            const unitUnpairedDiv = createUnitElement(diagramView, UNPAIRED_AXLE, unit);
            diagramDiv.appendChild(unitUnpairedDiv);
        const unpairedAxleDiv = createUnpairedAxleElements(unit);
            unitUnpairedDiv.appendChild(unpairedAxleDiv);
    }
}

function createUnitElement(diagramView, unitNumber, unit) {

    let diagram = "sv";
    if (diagramView === "WHEELSDIAGRAM") diagram = "w";

    const unitDiv = document.createElement('div');
    unitDiv.className = 'unitbox';

    if (unitNumber < unit.unitConfig.length) {
        if (unitNumber !== 0) {
            const unitHeader = createTrailerHeader(diagram, unit.wheelSensors.filter(s => s.wheelId.slice(-1) === unitNumber.toString()), unit.wheelSensors.find(s => s.wheelId.slice(-1) === unitNumber.toString()).repeater.trailerName || unitNumber);
            unitDiv.appendChild(unitHeader);
        }
    } else {
        if (unitNumber === SPARE_AXLE) {
            const unitHeader = createSpareTyresHeader();
            unitDiv.appendChild(unitHeader);
        } else {
            const unitHeader = createUnpairedSensorsHeader();
            unitDiv.appendChild(unitHeader);
        }
    }
    
    return unitDiv;
}
function updateAxleSensors(axleSensors, axleSensorsHistory) {
    axleSensorsHistory.forEach(history => {
        // Find matching axleSensor based on wheelId
        const sensor = axleSensors.find(sensor =>
            sensor.sensorId === history.sensorId
        );

        if (sensor) {
            // Update sensorId, currentPressure, currentTemperature, and currentVoltage from history
            history.sensorId = sensor.sensorId; 
            history.currentPressure = history.pressure;
            history.currentTemperature = history.temperature;
            history.currentVoltage = history.voltage;
            history.timeUpdated = formatTime(history.payloadTS);

            // Only update label colors if min/max properties exist
            history.pressureLabelColour = 'green';
            if (sensor.minPressure !== undefined && sensor.maxPressure !== undefined) {
                history.pressureLabelColour = getPressureLabelColour(
                    parseFloat(history.pressure),
                    parseFloat(sensor.minPressure),
                    parseFloat(sensor.maxPressure)
                );
            }

            history.temperatureLabelColour = 'green';
            if (sensor.minTemperature !== undefined && sensor.maxTemperature !== undefined) {
                history.temperatureLabelColour = getTemperatureLabelColour(
                    parseFloat(history.temperature),
                    parseFloat(sensor.maxTemperature)
                );
            }

            history.voltageLabelColour = 'green';
            if (sensor.minVoltage !== undefined && sensor.maxVoltage !== undefined) {
                history.voltageLabelColour = getVoltageLabelColour(
                    parseFloat(history.voltage),
                    parseFloat(sensor.minVoltage)
                );
            }
        }
    });
}

function createAxleElements(diagramView, unit, unitNumber, axle) {

    const tyreAxleDiv = document.createElement('div');
    tyreAxleDiv.className = 'diagramaxle'; //*wheelaxlediagram

    let axledivclasses = [];
    let axleStatusClass = '';
    const isAxleActive = getAxleActiveStatus(unit.axleRanges, `${addLeadingZero(axle)}${unitNumber}`);
    const axleSensors = unit.wheelSensors.filter(s => parseInt(s.wheelId.slice(1, 3)) === axle && parseInt(s.wheelId.slice(3)) === unitNumber);

    let axleSensorsHistory;
    if (global.sensorValuesDiagramDateSelected) {
        axleSensorsHistory = unit.wheelSensorHistory.filter(s => parseInt(s.wheelId.slice(1, 3)) === axle && parseInt(s.wheelId.slice(3)) === unitNumber);
        
        // Example usage with axleSensors and axleSensorsHistory arrays
        updateAxleSensors(axleSensors, axleSensorsHistory);
        console.log(axleSensorsHistory);
    }

    const axleSensorData = global.sensorValuesDiagramDateSelected ? axleSensorsHistory : axleSensors;

    const wheelConfig = unit.unitConfig[unitNumber].axles[axle - 1].wheelConfig;
    let wheel;

    if (wheelConfig === 0) {
        wheel = `1${addLeadingZero(axle)}${unitNumber}`;

        // Create the tyre element using the chosen data source
        const tyre1Div = createTyreElement(diagramView, unit, axle, 1, wheel, axleSensorData);

        // Append the created tyre element to the axle div
        tyreAxleDiv.appendChild(tyre1Div);

        //axle
        if (diagramView === "WHEELSDIAGRAM") {
            
            isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
            axledivclasses = ['shortaxle', axleStatusClass]; //'axleinfo',
            let axleDiv = createAxleElement(unit, axleSensors, axledivclasses);
            tyreAxleDiv.appendChild(axleDiv);

            if (unit.axleRanges && axleSensors.length) {
                let axleInfoDiv = createAxleInfoElement(unit.axleRanges.find(ar => ar.axleNumber === axleSensors[0].wheelId.slice(1)), axleSensors[0].wheelId.slice(1));
                tyreAxleDiv.appendChild(axleInfoDiv);
            }
        } else {
            let axleDiv = document.createElement('div');
            axleDiv.className = 'sensorvaluesaxle';
            tyreAxleDiv.appendChild(axleDiv);
        }

        wheel = `4${addLeadingZero(axle)}${unitNumber}`;
        let tyre4Div = createTyreElement(diagramView, unit, axle, 4, wheel, axleSensorData);
        tyreAxleDiv.appendChild(tyre4Div);

    } else {

        for (let s = 1; s <= 2; s++) {
            wheel = `${s}${addLeadingZero(axle)}${unitNumber}`;
            const tyreDiv = createTyreElement(diagramView, unit, axle, s, wheel, axleSensorData);
            tyreAxleDiv.appendChild(tyreDiv);
        }

        isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
        //console.log(isAxleActive)
        axledivclasses = ['shortaxle', axleStatusClass]; //'axleinfo', 

        if (diagramView === "WHEELSDIAGRAM") {
            let axleDiv = createAxleElement(unit, axleSensors, axledivclasses);
            tyreAxleDiv.appendChild(axleDiv);

            if (axleSensors.length) {
                let axleInfoDiv = createAxleInfoElement(unit.axleRanges.find(ar => ar.axleNumber === axleSensors[0].wheelId.slice(1)), axleSensors[0].wheelId.slice(1));
                tyreAxleDiv.appendChild(axleInfoDiv);
            }
        } else {
            let axleDiv = document.createElement('div');
            axleDiv.className = 'sensorvaluesaxle';
            tyreAxleDiv.appendChild(axleDiv);
        }

        for (let s = 3; s <= 4; s++) {
            wheel = `${s}${addLeadingZero(axle)}${unitNumber}`;
            let tyreDiv = createTyreElement(diagramView, unit, axle, s, wheel, axleSensorData);
            tyreAxleDiv.appendChild(tyreDiv);
        }
    }

    return tyreAxleDiv;
}

function createSpareAxleElements(diagramView, unit) {

    const tyreSpareAxleDiv = document.createElement('div');
    tyreSpareAxleDiv.className = 'diagramaxle';

    const axleSensors = unit.spareSensors;
    let axleSensorsHistory;

    if (global.sensorValuesDiagramDateSelected) {
        axleSensorsHistory = unit.spareSensorHistory

        // Example usage with axleSensors and axleSensorsHistory arrays
        updateAxleSensors(axleSensors, axleSensorsHistory);
        console.log(axleSensorsHistory);
    }

    const axleSensorData = global.sensorValuesDiagramDateSelected ? axleSensorsHistory : axleSensors;
    //

    for (let s = 0; s < axleSensors.length; s++) {
        const tyreDiv = createSpareTyreElement(diagramView, unit, parseInt(axleSensors[s].wheelId.slice(0, 1)), axleSensorData);
        tyreSpareAxleDiv.appendChild(tyreDiv);
    }

    //Spare Axle
    if (diagramView === 'WHEELSDIAGRAM') {
        
        const isAxleActive = getAxleActiveStatus(unit.axleRanges, '170');
        let axleStatusClass = ``; 
        isAxleActive ? axleStatusClass = `spareaxleactive` : axleStatusClass = `spareaxleinactive`;
        
        const spareAxle = createSpareAxleElement(unit, axleSensors, axleStatusClass);
        tyreSpareAxleDiv.appendChild(spareAxle);

        let axleInfoDiv = createAxleInfoElement(unit.axleRanges.find(ar => ar.axleNumber === axleSensors[0].wheelId.slice(1)), axleSensors[0].wheelId.slice(1));
        tyreSpareAxleDiv.appendChild(axleInfoDiv);
    }

    return tyreSpareAxleDiv;
}

function createUnpairedAxleElements(unit) {

    const unpairedAxleDiv = document.createElement('div');
    unpairedAxleDiv.className = 'diagramaxle';
    const axleSensors = unit.unpairedSensors;
    for (let s = 0; s < axleSensors.length; s++) {
        const tyreDiv = createUnpairedTyreElement(unit, s, axleSensors);
        unpairedAxleDiv.appendChild(tyreDiv);
    }
    
    return unpairedAxleDiv;
}

function createUnpairedTyreElement(unit, tyreIndex, axleSensors) {

    const wheel = document.createElement('div');
    wheel.className = 'sensorvaluesinfo';
    const tyreNameDiv = createUnpairedTyreNameElement(tyreIndex);
    tyreNameDiv.className = 'sensorvaluestyrename';
    let tyreDiv = document.createElement('div');
    //sensorType = 'wheelcorrect';  
    tyreDiv = createSensorValuesDiagramUnpairedOptions(tyreDiv, axleSensors, tyreIndex);
    tyreDiv.className = 'sensorvaluesdefault';
    wheel.appendChild(tyreNameDiv);
    wheel.appendChild(tyreDiv);   

    wheel.onclick = function (e) {
        e.stopImmediatePropagation();
        openTyreContextMenu(unit, axleSensors[tyreIndex]);
    };

    return wheel;
}

function createSpareTyreElement(diagramView, unit, tyre, axleSensors) {

    const wheel = document.createElement('div');

    const tyreNameDiv = createTyreNameElement(SPARE_AXLE, tyre);

    let tyreDiv = document.createElement('div');
    const tyreArrayIndex = getTyreArrayIndexTest(tyre, axleSensors);
    if (axleSensors.length > 0) {

        let tyredivclasses = ['wheeldefault'];
        let sensorType = 'wheelcorrect';

        if (!axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.signal) {
            sensorType = 'wheelblue';
        } else {
            if (axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.hasOwnProperty('sensorType')
                && axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.sensorType !== "") {
                sensorType = axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.sensorType || 'wheelcorrect';
            }
        }

        if (diagramView === "SENSORVALUESDIAGRAM") {

            wheel.className = 'sensorvaluesinfo';

            tyredivclasses = ['sensorvaluesdefault'];

            tyreNameDiv.className = 'sensorvaluestyrename';

            tyreDiv = createSensorValuesDiagramSpareOptions(tyreDiv, axleSensors, tyre);

        } else {
            //tyreDiv.id = 'wheel' + tyre + axleSensors[0].wheelId.slice(1);
            const axleSensor = axleSensors.find(s => s.wheelId === tyre + axleSensors[0].wheelId.slice(1));
            tyreDiv.id = `wheel${axleSensor.sensorId}`;
            tyredivclasses = ['wheeldefault', sensorType];
        }

        tyreDiv.classList.add(...tyredivclasses);
        // Check if the sensor has no signal
        //if (!axleSensors[tyreArrayIndex].signal) {
        //    tyreDiv.style.backgroundColor = '#8b959d';  // Change only the background color
        //} 

        wheel.appendChild(tyreNameDiv);
        wheel.appendChild(tyreDiv);
    }     

        wheel.onclick = function (e) {
            e.stopImmediatePropagation();
            openTyreContextMenu(unit, axleSensors[tyreArrayIndex]);
        };
    

    return wheel;
}

function createAxleElement(unit, axleSensors, axledivclasses) {    

    const axleDiv = document.createElement('div');
    axleDiv.className = 'axle'; 

    const axleSvg = document.createElement('svg');
    axleSvg.id = `axle${axleSensors[0].wheelId.slice(1)}`;
    axleSvg.classList.add(...axledivclasses);
    axleDiv.appendChild(axleSvg);

    const axleSensorsCloned = cloneObject(axleSensors);
    axleDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        openValuesOutOfRangeForm(unit, axleSensorsCloned);
    };

    return axleDiv;
}

function createSpareAxleElement(unit, axleSensors, axleStatusClass) {

    const axleDiv = document.createElement('div');
    axleDiv.id = `axle170`;
    axleDiv.className = `spareaxle ${axleStatusClass}`;
    //axleDiv.classList.add(...axledivclass);

    const axleSensorsCloned = cloneObject(axleSensors);
    axleDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        openValuesOutOfRangeForm(unit, axleSensorsCloned);
    };

    return axleDiv;
}

export function getAxleActiveStatus(axleRanges, axleNoUnitNo) {

    if (axleRanges) {
        const axle = axleRanges.find(axle => axle.axleNumber === axleNoUnitNo);
        if (!axle) {
            return false;
        }

        return (
            axle.recommendedPressure > 0 ||
            axle.pressureDeviationAllowedLow > 0 ||
            axle.pressureDeviationAllowedHigh > 0 ||
            axle.minPressure > 0 ||
            axle.maxPressure > 0 ||
            axle.maxTemperature > 0 ||
            axle.minVoltage > 0
        );
    } else { return false; }
}

function createTyreNameElement(axle, tyre) {
    const tyreNameDiv = document.createElement('div');
    tyreNameDiv.className = 'tyrename';
    tyreNameDiv.innerText = `A${axle}-T${tyre}`;
    if (axle === SPARE_AXLE) tyreNameDiv.innerText = `S-${tyre}`;
    return tyreNameDiv;
}

function createUnpairedTyreNameElement(tyreIndex) {
    const tyre = tyreIndex + 1;
    const tyreNameDiv = document.createElement('div');
    tyreNameDiv.className = 'tyrename';
    tyreNameDiv.innerText = `U-${tyre}`;
    return tyreNameDiv;
}

export function getTyreArrayIndexTest(tyre, axleSensors) {
    let tyreArrayIndex = -1;
    for (let s = 0; s < axleSensors.length; s++) {
        if (parseInt(axleSensors[s].wheelId.slice(0, 1)) === tyre) {
            //tyreArrayIndex = tyre;
            return s;
        }
    }
    return tyreArrayIndex;
}

function createTyreElement(diagramView, unit, axle, tyre, wheel, axleSensors) {

    console.log('unit');
    console.log(unit);

    const wheelDiv = document.createElement('div');

    const tyreNameDiv = createTyreNameElement(axle, tyre);

    let tyreDiv = document.createElement('div');
    let tyreArrayIndex = 0;
    if (axleSensors.length > 0) {
        tyreArrayIndex = getTyreArrayIndexTest(tyre, axleSensors);

        let tyredivclasses = ['wheeldefault'];
        let sensorType = 'wheelcorrect';
        
        if (tyreArrayIndex === -1 || !axleSensors[tyreArrayIndex].signal) {
            sensorType = 'wheelblue';
        } else {
            if (axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0].hasOwnProperty('sensorType')
                && axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0].sensorType !== "") {
                sensorType = axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0].sensorType;
            }
        }

        if (diagramView === "SENSORVALUESDIAGRAM") {
            wheelDiv.className = 'sensorvaluesinfo';
            tyredivclasses = ['sensorvaluesdefault'];
            tyreNameDiv.className = 'sensorvaluestyrename';
            tyreDiv = createSensorValuesDiagramOptions(tyreDiv, tyreArrayIndex, axleSensors, sensorType);
        } else {
            //tyreDiv.id = 'wheel' + tyre + axleSensors[0].wheelId.slice(1);
            const axleSensor = axleSensors.find(s => s.wheelId === tyre + axleSensors[0].wheelId.slice(1));
            if (axleSensor) {
                tyreDiv.id = `wheel${axleSensor.sensorId}` || tyre + axleSensors[0].wheelId.slice(1);
            } else {
                tyreDiv.id = tyre + axleSensors[0].wheelId.slice(1);
            }
            tyredivclasses = ['wheeldefault', sensorType];
        }

        tyreDiv.classList.add(...tyredivclasses);

        wheelDiv.appendChild(tyreNameDiv);
        wheelDiv.appendChild(tyreDiv);
    } else {

        tyreDiv.id = 'wheelnosignal' + tyre;

        let tyredivclasses = ['wheeldefault'];
        const sensorType = 'wheelblue';

        if (diagramView === "SENSORVALUESDIAGRAM") {

            wheelDiv.className = 'sensorvaluesinfo';

            tyredivclasses = ['sensorvaluesdefault'];

            tyreNameDiv.className = 'sensorvaluestyrename';

            let sensorNoSignalDiv = createSensorNoSignalElement();
            tyreDiv.appendChild(sensorNoSignalDiv);

        } else {
            tyredivclasses = ['wheeldefault', sensorType];

        }

        tyreDiv.classList.add(...tyredivclasses);

        wheelDiv.appendChild(tyreNameDiv);
        wheelDiv.appendChild(tyreDiv);

    }

    if (wheelDiv.classList.contains('wheelinactive')) {
        wheelDiv.onclick = function (e) {
            e.stopImmediatePropagation();
            openNewSensorForm(unit, "9" + tyre);
        };
    } else {
        wheelDiv.onclick = function (e) {
            e.stopImmediatePropagation();
            const unitAxleWheel = getWheelData(wheel, axleSensors);
            openTyreContextMenu(unit, unitAxleWheel);
        };
    }

    return wheelDiv;
}

function getWheelData(wheel, axleSensors) {
    // Check if wheel matches any SensorName in the objects array
    const axleSensor = axleSensors.find(s => s.wheelId.toString() === wheel.toString());

    // If a match is found, return the existing array unchanged
    if (axleSensor) {
        return axleSensor;
    }

    // Find an object to copy certain properties from (for this example, we copy from the first object)
    const sourceObject = axleSensors[0];
    const sensor = {};

    // Assign all properties of the sourceObject to the newObject with specified exceptions
    for (let key in sourceObject) {
        if (sourceObject.hasOwnProperty(key)) {
            if (key === 'unitNumber' || key === 'axle' || key === 'recommendedPressure' || key === 'pressureDeviationAllowedLow' || key === 'pressureDeviationAllowedHigh' ||
                key === 'maxTemperature' || key === 'minVoltageValue' || key === 'maxPressure' || key === 'minPressure') {
                sensor[key] = sourceObject[key];
            } else {
                sensor[key] = null;
            }
        }
    }

    sensor.wheelId = wheel;
    sensor.tyreName = `A${parseInt(wheel.slice(1, 3))}-T${wheel.slice(0, 1)}`;
    sensor.sensorType = 'noSignal';
    sensor.wheelType = 'wheelinactive';

    return sensor;
}

function createSpareTyresHeader() {

    const spareTyresHeader = document.createElement('div');
    spareTyresHeader.className = 'unitbox';

    const header = document.createElement('h6');
    //const headerLabel = document.createElement('div');
    //headerLabel.className = 'edittrailername';

    //const headerText = document.createElement('span');
    header.id = "sparetyres";
    header.innerText = "Spare Tyres";
    //headerLabel.appendChild(headerText);
    //header.appendChild(headerLabel);
    spareTyresHeader.appendChild(header);

    return spareTyresHeader;
}

function createUnpairedSensorsHeader() {

    const unpairedSensorsHeader = document.createElement('div');
    unpairedSensorsHeader.className = 'unitbox';

    const header = document.createElement('h6');
    //const headerLabel = document.createElement('div');
    //headerLabel.className = 'edittrailername';

    //const headerText = document.createElement('span');
    header.id = "unpairedtyres";
    header.innerText = "Unpaired Sensors";
    //headerLabel.appendChild(headerText);
    //header.appendChild(headerLabel);
    unpairedSensorsHeader.appendChild(header);

    return unpairedSensorsHeader;
}

export async function displaySensorUpdates(unit, data) {

    unit = updateUnitMetrics(unit, data);
    if (document.getElementById(`wheel${data.wheelSensorUID}`)) {
        document.getElementById(`wheel${data.wheelSensorUID}`).className = `wheeldefault ${(unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
            unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID)).sensorType}`;
    }
    if (document.getElementById(`sv${data.wheelSensorUID}timeupdated`)) {
        document.getElementById(`sv${data.wheelSensorUID}timeupdated`).innerText = formatTime(data.payloadTS);
    }
    if (document.getElementById(`sv${data.wheelSensorUID}sensorid`)) {
        document.getElementById(`sv${data.wheelSensorUID}sensorid`).innerText = data.wheelSensorUID;
    }
    const pressureElement = document.getElementById(`sv${data.wheelSensorUID}pressure`);
    if (pressureElement) {
        pressureElement.innerHTML = '';
        const pressure = parseFloat(data.currentPressure).toFixed(2);
        pressureElement.innerText = `${pressure} bar`;
        pressureElement.className = `sensorvalues ${(unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
            unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID)).pressureLabelColour}`;
    }
    if (document.getElementById(`sv${data.wheelSensorUID}temperature`)) {
        document.getElementById(`sv${data.wheelSensorUID}temperature`).innerText = `${data.currentTemperature} °C`;//`${data.currentTemperature} ${String.fromCharCode(176)}C`
        document.getElementById(`sv${data.wheelSensorUID}temperature`).className = `sensorvalues ${(unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
            unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID)).temperatureLabelColour}`;
    }
    if (document.getElementById(`sv${data.wheelSensorUID}voltage`)) {
        document.getElementById(`sv${data.wheelSensorUID}voltage`).innerText = `${data.currentVoltage} V`;
        document.getElementById(`sv${data.wheelSensorUID}voltage`).className = `sensorvalues ${(unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
            unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID)).voltageLabelColour}`;
    }

    let sensorSignalStrengthElement = document.getElementById(`sv${data.wheelSensorUID}signalstrength`);
    if (sensorSignalStrengthElement) {
        const sensor = unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
            unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID);
        if (sensor) {
            sensorSignalStrengthElement.innerHTML = '';
            sensorSignalStrengthElement.className = 'signalstrengthbar';
            const signalStrength = sensor.repeater.reliabilityDiff;
            sensorSignalStrengthElement = createSignalStrengthBar(sensorSignalStrengthElement, signalStrength);
        }
    }

    const rotationElement = document.getElementById(`sv${data.wheelSensorUID}rotation`)
    if (rotationElement) {
        rotationElement.className = 'sensorvalues';
        rotationElement.innerText = `${data.currentRotation}`;
    }
}

export function createSignalStrengthBar(sensorSignalStrengthDiv, signalStrength) {

    for (let b = 1; b <= 10; b++) {
        const block = document.createElement('div');
        block.className = 'signalblock';
        block.id = 'signalblock' + b;

        if (b <= 3 && b < signalStrength) {
            block.classList.add('signalred');
        }
        if (b > 3 && b <= 6 && b < signalStrength) {
            block.classList.add('signalyellow');
        }
        if (b > 6 && b <= signalStrength) {
            block.classList.add('signalgreen');
        }
        sensorSignalStrengthDiv.appendChild(block);
    }
    return sensorSignalStrengthDiv;
}

export function createTrailerHeader(diagram, unitSensors, trailerName) {

    const trailerHeader = document.createElement('div');    

    const header = document.createElement('h6');
    const headerLabel = document.createElement('div');    

    const linkIcon = document.createElement('svg');
    linkIcon.className = 'linkicon';    
    const headerText = document.createElement('span');

    headerLabel.appendChild(linkIcon);
    headerLabel.appendChild(headerText);

    if (diagram === 'sv' || diagram === 'r') {
        trailerHeader.className = 'unitbox';
        headerText.id = `${diagram}trailer${trailerName}`;
        headerText.innerText = `Trailer ${trailerName}`;
    } else {
        trailerHeader.className = 'unitboxedit';
        headerText.id = `${diagram}trailer${trailerName}`;
        headerText.innerText = `Trailer ${trailerName}`;

        const tooltipText = document.createElement('span');
        tooltipText.className = 'tooltiptext';
        tooltipText.innerText = "EDIT TRAILER NAME";
        headerLabel.appendChild(tooltipText);
        headerLabel.className = 'edittrailername';
        headerLabel.onclick = function (e) {
            e.stopImmediatePropagation();
            openEditTrailerForm(unitSensors, trailerName);
        };
    }

    header.appendChild(headerLabel);
    trailerHeader.appendChild(header);

    return trailerHeader;
}

function openEditTrailerForm(unitSensors, trailerName) {

    const editTrailerModal = document.getElementById('editunitmodal');

    //const editTrailer = document.getElementById('editunitform');
    document.getElementById('editunitheader').innerText = "EDIT TRAILER";
    document.getElementById('editunitlabel').innerText = "Trailer Name";

    document.getElementById('eufUnitId').value = "";
    document.getElementById('eufUnitName').value = trailerName;
   
    const closeSpan = document.getElementById("closeeditunitmodal");
    closeSpan.onclick = function () {
        editTrailerModal.style.display = "none";
    };

    window.onclick = function (e) {
        if (e.target == editunitmodal) {
            editTrailerModal.style.display = "none";
        }
    };

    const btnSubmitEditTrailer = document.getElementById('btneditunitname');   
    btnSubmitEditTrailer.onclick = (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        const newTrailerName = document.getElementById('eufUnitName').value;
        submitEditTrailerForm(unitSensors, trailerName, newTrailerName);        
    };

    editTrailerModal.style.display = "block";
}

async function submitEditTrailerForm(unitSensors, trailerName, newTrailerName) {

    document.body.style.cursor = 'wait';

    // Create an array of promises for the sensor updates
    const updatePromises = unitSensors.map(sensor => updateTrailerName(sensor, newTrailerName));
    const results = await Promise.all(updatePromises);
    const trailersUpdatedSuccessfully = results.every(result => result === true);

    if (trailersUpdatedSuccessfully) {
        unitSensors.forEach(sensor => {
            sensor.repeater.trailerName = newTrailerName;
        });

        const sensorValuesDiagramTrailerName = document.getElementById(`svtrailer${trailerName}`);
        sensorValuesDiagramTrailerName.innerText = `Trailer ${newTrailerName}`;
        sensorValuesDiagramTrailerName.id = `svtrailer${newTrailerName}`;

        const wheelsDiagramTrailerName = document.getElementById(`wtrailer${trailerName}`);

        wheelsDiagramTrailerName.innerText = `Trailer ${newTrailerName}`;
        wheelsDiagramTrailerName.id = `wtrailer${newTrailerName}`;

        document.getElementById("editunitmodal").style.display = "none";
        document.body.style.cursor = 'default';
    } else {
        console.error(`Trailer names for all repeaters failed to update`);
        //document.getElementById("errorBox").style.display = "block";
    }
}

function openTyreContextMenu(unit, wheel) {

    const menu = document.getElementById('tyrecontextmenu');
    const closeTyreMenu = document.getElementById('closetyremenu');
    const contextMenuList = document.getElementById('tyrecontextmenu');
    menu.classList.add('show');

    closeTyreMenu.style.display = "block";

    closeTyreMenu.addEventListener('click', () => {
        menu.classList.remove('show');
        closeTyreMenu.style.display = "none";
    });

    contextMenuList.onclick = async function clickHandler(ev) {
        //ev.currentTarget.removeEventListener(ev.type, clickHandler);
        if (ev.target.tagName === 'LI') {
            ev.stopImmediatePropagation();
            menu.classList.remove('show');
            closeTyreMenu.style.display = "none";
            
            switch (ev.target.innerText) {
                case 'Edit Tyre Info':
                        openEditTyreIdForm(unit, wheel);
                    break;
                case 'Sensor Report':
                    document.body.style.cursor = 'none';
                    document.getElementById("loadingBoxText").innerText = "Collecting Report Data... ";
                    $("#loadingBox").modal({
                        backdrop: "static", 
                        keyboard: false,
                        show: true 
                    });

                    let date = new Date();
                    date.setDate(date.getDate() - 7); //week ago - subtract 7 days from the current date                   

                    if (typeof wheel === 'object' && wheel !== null && 'wheelId' in wheel) {
                        const axle = wheel.wheelId.slice(1, 3);
                        if (axle === SPARE_AXLE.toString()) {
                            const s = unit.spareSensors.findIndex(sensor => sensor.wheelId === wheel.wheelId);
                            wheel.recommendedPressure = unit.spareSensors[s]?.recommendedPressure || 3.5;
                            wheel.pressureDeviationAllowedLow = unit.spareSensors[s]?.pressureDeviationAllowedLow || 10;
                            wheel.pressureDeviationAllowedHigh = unit.spareSensors[s]?.pressureDeviationAllowedHigh || 10;
                            wheel.minPressure = unit.spareSensors[s]?.minPressure;
                            wheel.maxPressure = unit.spareSensors[s]?.maxPressure;
                            wheel.maxTemperature = unit.spareSensors[s]?.maxTemperature;
                            wheel.minVoltageValue = unit.spareSensors[s]?.minVoltageValue;
                        }
                    } else if (typeof wheel === 'string') {
                        const axle = wheel.slice(1, 3);
                        if (axle === SPARE_AXLE.toString()) {
                            const s = unit.spareSensors.findIndex(sensor => sensor.wheelId === wheel.wheelId);
                            wheel = {
                                recommendedPressure: unit.spareSensors[s]?.recommendedPressure || 3.5,
                                pressureDeviationAllowedLow: unit.spareSensors[s]?.pressureDeviationAllowedLow || 10,
                                pressureDeviationAllowedHigh: unit.spareSensors[s]?.pressureDeviationAllowedHigh || 10,
                                minPressure: unit.spareSensors[s].minPressure,
                                maxPressure: unit.spareSensors[s].maxPressure,
                                maxTemperature: unit.spareSensors[s].maxTemperature,
                                minVoltageValue: unit.spareSensors[s].minVoltageValue
                            };
                        }
                    }

                    const sensorData = await getSensorHistoryData(unit, wheel, formatDate(date));
                    
                    $("#loadingBox").modal("hide");
                    openSensorReport(unit, sensorData);

                    break;
                case 'Action Log':
                        gRegisterUnitEvent = false;
                        openActionLogForm(unit, wheel);
                    break;
            }            
        }        
    };
}

function openEditTyreIdForm(unit, wheel) {

    document.body.style.cursor = 'wait';
    $('#container').overlayMask();

    const editTyreInfo = document.getElementById('edittyreinfomodal');    

    let closeModal = document.getElementById("closeedittyreidmodal");
    closeModal.onclick = function () {
        editTyreInfo.style.display = "none";
    };

    //window.onclick = function (event) {
    //    if (event.target == editTyreInfo) {
    //        editTyreInfo.style.display = "none";
    //    }
    //};

    const edittyreidform = document.getElementById('edittyreidform');
    edittyreidform.reset();

    //document.getElementById('etfSelectedUnitId').value = unit.imei;

    //document.getElementById('etfUnitId').value = unit.imei;

    document.getElementById('etfSensorName').value = wheel.wheelId;
    const tyreInfo = wheel.wheelId.slice(1, 3) === SPARE_AXLE.toString() ? unit.spareSensors.find(s => s.wheelId === wheel.wheelId) : unit.wheelSensors.find(s => s.wheelId === wheel.wheelId);
    
    document.getElementById('etfTyreId').value = tyreInfo?.externalTyreId|| "";
    
    const treadDepthValue = tyreInfo.tyreTreadDepth ? parseFloat(tyreInfo.tyreTreadDepth) : 0;
    document.getElementById('etfTyreTreadDepth').value = treadDepthValue > 0 ? treadDepthValue : 0;
    

    edittyreidform.onsubmit = (e) => {
        e.stopImmediatePropagation();
        e.preventDefault();
        submitEditTyreIdForm(unit, wheel);
    };

    $('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    document.getElementById("edittyreinfomodal").style.display = "block";   
}

function submitEditTyreIdForm(unit, wheel) {

    document.getElementById("loadingBoxText").innerText = "Updating tyre data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });    

    wheel.externalTyreId = document.getElementById('etfTyreId').value;
    wheel.tyreTreadDepth = document.getElementById('etfTyreTreadDepth').value;
    if (wheel.wheelId.slice(1, 3) === SPARE_AXLE.toString()) {
        const updatedSpareWheel = unit.spareSensors.find(s => s.wheelId === wheel.wheelId);
        if (updatedSpareWheel) {
            updatedSpareWheel.externalTyreId = wheel.externalTyreId;
            updatedSpareWheel.tyreTreadDepth = wheel.tyreTreadDepth;
        }
    } else {
        const updatedWheel = unit.wheelSensors.find(s => s.wheelId === wheel.wheelId);
        if (updatedWheel) {
            updatedWheel.externalTyreId = wheel.externalTyreId;
            updatedWheel.tyreTreadDepth = wheel.tyreTreadDepth;
        }
    }

    //const sensor = unit.sensors.find(s => s.wheelId === wheel.wheelId);
    //if (sensor) {
    //    sensor.externalTyreId = wheel.externalTyreId;
    //    sensor.tyreTreadDepth = wheel.tyreTreadDepth;
    //}
    
    updateTyreInfo(wheel);
    createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');

    $("#loadingBox").modal("hide");  
    document.getElementById("edittyreinfomodal").style.display = "none";
    return false;
}


function createStandardSensorFromCurrent(replacedSensor) {  //Create Sensor

    let name = replacedSensor.currentSensorId;
    let type = "custom";
    let param = "tmp_1";

    let obj = { n: name, d: "", f: 0, c: "", vt: 1, vs: 0, tbl: [], m: "", p: param, t: type };
    global.session.getItem(replacedSensor.id).createSensor(obj,
        function (code, data) { // create sensor callback
            if (code) msg(wialon.core.Errors.getErrorText(code));
            else {
                console.log(data.n + " sensor created successfully");
            }
        }
    );
}

async function openActionLogForm(unit, wheel) {

    const actionLogData = await getActionLogData(unit.imei, wheel.wheelId)

    document.body.style.cursor = 'wait';

    let actionlogmodal = document.getElementById('actionlogmodal');

    let span = document.getElementById("closeactionlogmodal");
    span.onclick = function (e) {
        actionlogmodal.style.display = "none";
        e.stopImmediatePropagation();
        return false;
    };

    //window.onclick = function (event) {
    //if (event.target == actionlogmodal) {
    //    actionlogmodal.style.display = "none";
    //    event.stopImmediatePropagation();
    //    //return false;
    //}
    //return false;
    //};

    let actionLogSubmitButton = document.getElementById('alfsubmitbtn'); 
    actionLogSubmitButton.onclick = (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        submitActionLogForm(unit.imei, wheel.wheelId);
        return false;
    };
    
    const axleNo = parseInt(wheel.wheelId.slice(1,3));
    const tyreNo = wheel.wheelId.slice(0,1);
    const wheelId = wheel.wheelId;
    
    let arrHeader = new Array();	// array for header.    
    arrHeader = ['', 'Date', 'Action'];

    let actionLog = document.getElementById('actionlog');
    actionLog.innerText = "";

    let tbody = actionLog.createTBody();
    let itemNo = 0;
    for (let i = actionLogData.actions.length - 1; i >= 0; i--) {

        itemNo++;
        let date = actionLogData.actions[i].timestamp;
        let actionTaken = actionLogData.actions[i].action; 

        let tr = tbody.insertRow();

        for (let c = 0; c < arrHeader.length; c++) {
            let cell = tr.insertCell();
            switch (c) {
                case 0:
                    cell.textContent = itemNo;
                    break;
                case 1:
                    cell.textContent = formatZuluTime(date);
                    break;
                case 2:
                    cell.textContent = actionTaken;
                    break;
            }
        }
    }

    let theader = actionLog.createTHead();

    let headerRow = theader.insertRow();
    for (let key of arrHeader) {
        let th = document.createElement('th'); // create table headers
        let text = document.createTextNode(key);
        th.appendChild(text);
        headerRow.appendChild(th);
    }

    document.getElementById('alfUnitId').value = unit.imei;
    document.getElementById('alfWheelId').value = wheel.wheelId;
    document.getElementById("alfUnitName").value = unit.name;
            
    let trailerName = "";
    if (parseInt(wheel.unitNumber) > 0) trailerName = `Trailer ${wheel.unitNumber}`
    document.getElementById("alfTrailerName").value = trailerName;
    document.getElementById("alfTrailerName").removeAttribute('type');

    if (axleNo !== SPARE_AXLE) {
        document.getElementById("alfAxleTyreDescription").value = "A" + axleNo + "-T" + tyreNo;
    } else {

        document.getElementById("alfAxleTyreDescription").value = "Spare " + tyreNo;
    }

    document.getElementById("actionlogmodal").style.display = "block";
    document.body.style.cursor = 'default';

}

async function submitActionLogForm(imei, wheelId) {

    document.body.style.cursor = 'wait';
    const action = document.getElementById('alfAction').value;

    const updatedActionLog = await updateActionLog(imei, wheelId, action);

    document.getElementById("actionlogmodal").style.display = "none";

    document.getElementById("actionlogform").reset();
    document.body.style.cursor = 'default';

    return false;
}

function registerUnitEvent(prms) {

    gRegisterUnitEvent = true;
    let remote = wialon.core.Remote.getInstance();

    return new Promise((resolve, reject) => {
        remote.remoteCall('unit/registry_custom_event', prms,
            (error, result) => {
                if (error) {
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return false;
                }
                resolve(true);
            },
        );
    });
}

//export { openTyreContextMenu, createUnitElement, createUnitElementOld, createTrailerHeader, createSensorNoSignalElement, createTyreNameElement, createTyreIdElement, createTreadDepthElement, createSensorIdElement, createSensorPressureElement, createSensorTemperatureElement, createSensorVoltageElement };
export { openTyreContextMenu, createUnitElement };