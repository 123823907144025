
import { addLeadingZero } from "../../../utilities";
import { createDiagramView } from "./unitdiagrams";
import { openNotificationsForm } from "./wheelsdiagram";
import { createTrailerHeader } from "./unitdiagrams";

import { SPARE_AXLE } from "../../../global"

async function createRepeaterDiagram(unit) {
    displayDiagram(unit);
}

function displayDiagram(unit) {

    const diagram = document.getElementById('rightpane');
    while (diagram.hasChildNodes()) {
        diagram.removeChild(diagram.lastChild);
    }
    const diagramFragment = document.createDocumentFragment();

    let diagramDiv = document.createElement('div');
    diagramDiv.id = `repeaterdiagram`;
    diagramDiv.className = 'unitdiagram';

    diagramFragment.appendChild(diagramDiv);
    diagram.appendChild(diagramFragment);

    const repeaterDiagramButtons = createRepeaterDiagramButtons(unit)
    diagramDiv.appendChild(repeaterDiagramButtons);

    const repeaterDiagramUnitFrame = createDiagramUnitFrame(unit);
    diagramDiv.appendChild(repeaterDiagramUnitFrame);
}

function createDiagramUnitFrame(unit) {    

    const repeaterDiagramUnitFrame = document.createElement('div');   

    let unitBoxes = [...new Set(Object.keys(unit.wheelSensors).map(item => unit.wheelSensors[item].wheelId.slice(-1)))].sort();
    for (let ub = 0; ub < unitBoxes.length; ub++) {
        repeaterDiagramUnitFrame.appendChild(createDiagramUnitBox(unit, ub));
    }

    //repeaterDiagramUnitFrame.appendChild(createGatewayElements(unit));

    return repeaterDiagramUnitFrame
}

function createGatewayElements(unit) {

    const gatewayElements = document.createElement('div');
    gatewayElements.className = 'diagramaxle';

    gatewayElements.appendChild(createGatewayElement(unit));
    gatewayElements.appendChild(createSyncElement());

    return gatewayElements;
}

function createGatewayElement(unit) {

    const gateway = document.createElement('div');
    gateway.className = 'gateway';
    gateway.appendChild(createGatewayTopElement());
    gateway.appendChild(createGatewayInfoElement(unit));
    gateway.appendChild(createGatewayBottomElement());

    return gateway;
}

function createSyncElement() {
    
    const sync = document.createElement('div');
    sync.className = 'sync';

    //sync.appendChild(createSensorTopElement());
    sync.appendChild(createSyncSensorValues());
    //sync.appendChild(createSensorBottomElement());

    return sync;
}

function createSyncSensorValues() {

    const sensorValues = document.createElement('div');
    sensorValues.className = 'repeatersensorvalues';

    sensorValues.appendChild(createSyncSignalStrengthElement());
    sensorValues.appendChild(createSyncVoltageElement());

    return sensorValues;
}

function createSyncSignalStrengthElement() {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    //if (axleSensor.signalStrengthLabelColour === "blue")
    //    sensorValuesClasses = ['repeatersensorvaluesdefault', 'blue'];

    signalStrength.innerText = "undefined";
    signalStrength.classList.add(...sensorValuesClasses);
    //signalStrength.innerText = Math.round(axleSensor.signalStrength * 1000) / 1000 + " dBi";

    return signalStrength;
}

function createSyncVoltageElement() {

    const sensorVoltage = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    //if (axleSensor.voltageLabelColour === "yellow")
    //    sensorValuesClasses = ['repeatersensorvaluesdefault', 'yellow'];
    //const voltage = Math.round(axleSensor.currentVoltage * 1000) / 1000;

    sensorVoltage.classList.add(...sensorValuesClasses);
    sensorVoltage.innerText = "undefined";

    return sensorVoltage;
}

function createGatewayTopElement() {

    const gatewayTop = document.createElement('div');
    gatewayTop.className = 'gatewaytop';
    const gatewayTopLeft = document.createElement('div');
    gatewayTopLeft.className = 'gatewaytopleft';
    const gatewayTopRight = document.createElement('div');
    gatewayTopRight.className = 'gatewaytopright';
    gatewayTop.appendChild(gatewayTopLeft);
    gatewayTop.appendChild(gatewayTopRight);
    
    return gatewayTop;
}

function createGatewayBottomElement() {

    const gatewayBottom = document.createElement('div');
    gatewayBottom.className = 'gatewaybottom';

    return gatewayBottom;
}

function createGatewayInfoElement(unit) {

    const gatewayInfo = document.createElement('div');
    gatewayInfo.className = 'gatewayinfo';

    //let sensorType = 'wheelcorrect';
    //if (axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0].hasOwnProperty('sensorType')
    //    && axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0].sensorType !== "") {
    //    sensorType = axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0].sensorType;
    //}

    //const deviceData = await getUnitData("", unit.imei);

    //gatewayInfo.appendChild(createGatewayInfo(deviceData.deviceType));
    //gatewayInfo.appendChild(createGatewayInfo(deviceData.imei));
    //gatewayInfo.appendChild(createGatewayInfo(deviceData.phoneNumber));
    //gatewayInfo.appendChild(createGatewaySignalStrengthElement("undefined"));
    //gatewayInfo.appendChild(createBatteryVoltageElement("undefined"));

    gatewayInfo.appendChild(createGatewayInfo("undefined"));
    gatewayInfo.appendChild(createGatewayInfo("undefined"));
    gatewayInfo.appendChild(createGatewayInfo("undefined"));
    gatewayInfo.appendChild(createGatewaySignalStrengthElement("undefined"));
    gatewayInfo.appendChild(createBatteryVoltageElement("undefined"));

    return gatewayInfo;
}

function createGatewayInfo(info) {

    const deviceInfo = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];

    deviceInfo.classList.add(...sensorValuesClasses);
    deviceInfo.innerText = info;

    return deviceInfo;
}

function createGatewaySignalStrengthElement(signalStrengthValue) {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];    

    signalStrength.classList.add(...sensorValuesClasses);
    signalStrength.innerText = signalStrengthValue;

    return signalStrength;
}

function createBatteryVoltageElement(voltage) {

    const batteryVoltage = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];

    //const voltage = Math.round(axleSensor.currentVoltage * 1000) / 1000;

    batteryVoltage.classList.add(...sensorValuesClasses);
    batteryVoltage.innerText = voltage;

    return batteryVoltage;
}


function createDiagramUnitBox(unit, unitBox) {

    const unitSensors = unit.wheelSensors.filter(s => s.wheelId.slice(-1) === unitBox.toString());

    const repeaterDiagramUnitBox = document.createElement('div');

    if (unitBox !== 0)
        repeaterDiagramUnitBox.appendChild(createTrailerHeader('r', unitSensors, unitSensors[0].repeater.trailerName || unitBox));

    let unitBoxAxle = {};
    let axles = [];

    for (let ws = 0; ws < unit.wheelSensors.length; ws++) {
        if (parseInt(unit.wheelSensors[ws].wheelId.slice(-1)) === unitBox) {
            axles.push(unit.wheelSensors[ws].wheelId.slice(1, 3));
        }
    }
    
    axles = [...new Set(axles)].sort();
    for (let a = 0; a < axles.length; a++) {        
        unitBoxAxle = createUnitBoxAxle(unitBox, unitSensors, a + +1);
        repeaterDiagramUnitBox.appendChild(unitBoxAxle);
    }

    return repeaterDiagramUnitBox;
}

function createUnitBoxAxle(unitBox, sensors, axle) {

    const unitBoxAxle = document.createElement('div');
    unitBoxAxle.className = 'diagramaxle';
    const sensorSuffix = `${addLeadingZero(axle)}${unitBox}`;
    const axleSensorLeft = sensors.filter(s =>
        s.wheelId.endsWith(sensorSuffix) && ["1", "2"].includes(s.wheelId.charAt(0))
    )[0];
    

    let sensorLeft = createSensorElement(axleSensorLeft.repeater);
    unitBoxAxle.appendChild(sensorLeft);

    const axleSensorRight = sensors.filter(s =>
        s.wheelId.endsWith(sensorSuffix) && ["3", "4"].includes(s.wheelId.charAt(0))
    )[0];
    let sensorRight = createSensorElement(axleSensorRight.repeater);
    unitBoxAxle.appendChild(sensorRight);

    return unitBoxAxle;
}


function createSensorElement(axleSensor) {

    const sensor = document.createElement('div');
    sensor.id = 'sensor' + axleSensor.repeaterId;
    sensor.className = 'repeater';     
    sensor.appendChild(createSensorTopElement());    
    sensor.appendChild(createSensorValuesElement(axleSensor));
    sensor.appendChild(createSensorBottomElement());
    return sensor;
}

function createSensorTopElement() {

    const sensorTop = document.createElement('svg');
    sensorTop.className = 'repeaterimages repeatertop';
    return sensorTop;
}

function createSensorBottomElement() {

    const sensorBottom = document.createElement('svg');
    sensorBottom.className = 'repeaterimages repeaterbottom';
    return sensorBottom;
}

function createSensorValuesElement(axleSensor) {   

    const sensorValues = document.createElement('div');
    sensorValues.className = 'repeatersensorvalues';

    if (axleSensor) {
        sensorValues.appendChild(createRepeaterIdElement(axleSensor.repeaterId));
        sensorValues.appendChild(createWheelConfigElement(axleSensor.wheelConfiguration));
        sensorValues.appendChild(createSensorVoltageElement(axleSensor.locationSensorBatteryVoltage));
    } else {
        sensorValues.appendChild(createNoSignalElement());
    }


    return sensorValues;   
}

function createNoSignalElement() {

    const noSignal = document.createElement('div');
    noSignal.className = 'repeatersensorvaluesdefault wheelnosignal';
    noSignal.innerText = "No Signal";

    return noSignal;
}

function createRepeaterIdElement(repeaterId) {

    const repeaterIdDiv = document.createElement('div');
    repeaterIdDiv.className = 'repeatersensorvaluesdefault sensorid';
    repeaterIdDiv.innerText = repeaterId;

    return repeaterIdDiv;
}

function createWheelConfigElement(wheelConfig) {

    const wheelConfigDiv = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];

    wheelConfigDiv.classList.add(...sensorValuesClasses);
    wheelConfigDiv.innerText = wheelConfig;
    return wheelConfigDiv;
}

function createSignalStrengthElement(axleSensor) {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    if (axleSensor.signalStrengthLabelColour === "blue")
        sensorValuesClasses = ['repeatersensorvaluesdefault', 'blue'];

    signalStrength.classList.add(...sensorValuesClasses);
    signalStrength.innerText = Math.round(axleSensor.signalStrength * 1000) / 1000 + " dBi";

    return signalStrength;
}

function createSensorVoltageElement(voltage) {

    const voltageDiv = document.createElement('div');

    let sensorValuesClasses = ['repeatersensorvaluesdefault'];

    voltage = Math.round((voltage / 1000) * 100) / 100;
    if (voltage < 3.2)
        sensorValuesClasses = ['repeatersensorvaluesdefault', 'yellow'];

    voltageDiv.classList.add(...sensorValuesClasses);
    voltageDiv.innerText = voltage + " V";

    return voltageDiv;
}

async function getDiagramConfiguration(unit, diagramDiv) {

    for (let un = 0; un < unitNumbers.length; un++) {
        let unitDiv = createUnitElement(unitNumbers[un]);
        diagramDiv.appendChild(unitDiv);

        const unitAxles = [];
        for (let ls = 0; ls < unit.locationSensors.count; ls++) {
            if (unit.locationSensors[ls].wheelId.slice(-1) === unitNumbers[un].toString() && unit.locationSensors[ls].wheelId.slice(1, 3) !== "16")
                unitAxles.push(unit.locationSensors[ls].wheelId.slice(1, 3));
        }

        unitAxles = [...new Set(unitAxles)];
        for (let a = 0; a < unitAxles.length; a++) {

            let axleSensors = unit.locationSensors.filter(s => parseInt(s.wheelId.slice(1, 3)) === (a + +1));
            let axleDiv = createAxleElements(unit, a + +1, axleSensors);

            unitDiv.appendChild(axleDiv);
        }

        let unitAxleSpareSensors = unit.sensors.filter(s => parseInt(s.wheelId.slice(1, 3)) === 16);

    }
}

function createRepeaterDiagramButtons(unit) {

    let schematicDiagramButtonDivClasses = ['row', 'pane2buttons'];
    let schematicDiagramButtonDiv = document.createElement('div');
    schematicDiagramButtonDiv.classList.add(...schematicDiagramButtonDivClasses);

    let schematicDiagramButtonClasses = ['btn', 'btn-primary', 'panebutton', 'flex-child'];

    let notificationsButton = document.createElement('button');
    notificationsButton.classList.add(...schematicDiagramButtonClasses);
    notificationsButton.type = 'button';
    notificationsButton.title = 'SET NOTIFICATIONS';

    const notificationsIcon = document.createElement('svg');
    notificationsIcon.className = 'buttonicon notificationbluesvg';

    const notificationsButtonText = document.createElement('span');
    //wheelsDiagramButtonText.className = 'buttontext'; //class="buttontext buttontext-disabled"
    notificationsButtonText.innerText = 'Notifications'; //'\xa0Unit'
    notificationsButton.appendChild(notificationsIcon);
    notificationsButton.appendChild(notificationsButtonText);

    notificationsButton.onclick = function (e) {
        openNotificationsForm(unit);
        e.stopImmediatePropagation();
    };

    schematicDiagramButtonDiv.appendChild(notificationsButton);

    let repeaterDiagramButton = document.createElement('button');
    repeaterDiagramButton.classList.add(...schematicDiagramButtonClasses);
    repeaterDiagramButton.type = 'button';
    repeaterDiagramButton.title = 'VIEW WHEELS DIAGRAM';

    const tyreIcon = document.createElement('svg');
    tyreIcon.className = 'buttonicon tyrebluesvg';

    const repeaterDiagramButtonText = document.createElement('span');
    repeaterDiagramButtonText.innerText = 'Wheels Diagram';

    repeaterDiagramButton.appendChild(tyreIcon);
    repeaterDiagramButton.appendChild(repeaterDiagramButtonText);

    repeaterDiagramButton.onclick = function (e) {        
        e.stopImmediatePropagation();
        createDiagramView(unit, 'WHEELSDIAGRAM', 'rightpane');
    };

    schematicDiagramButtonDiv.appendChild(repeaterDiagramButton);

    return schematicDiagramButtonDiv;
}

export { createRepeaterDiagram };