/// <reference path="../../data/unitdata.js" />
import { getAddressFromCoordinates, formatCoordinate, formatZuluTime } from "../../utilities";
import { updateDriver } from "../../data/unitdata";
import { updateUnitName } from "../../notifications";

let map, marker; // global variables for maps (leaflet)
// Add event listener to "Add Axle" button
let axleCounter = 0;
let repeaterCounter = 0;
export function openUnitConfigForm(imei) {

    resetUnitConfigForm();

    const unitConfigModalLabel = document.getElementById('unitconfigmodallabel');
    const sinkIdLabel = document.getElementById('sinkidlabel');
    const sinkId = document.getElementById('sinkid');
    //const trailerOrderLabel = document.getElementById('trailerorderlabel');
    //const trailerOrder = document.getElementById('trailerorder');
    if (imei) {        
        unitConfigModalLabel.textContent = `Configure Truck for IMEI ${imei}`
        sinkIdLabel.style.display = 'block';
        sinkId.style.display = 'block';        
        //trailerOrderLabel.style.display = 'none';        
        //trailerOrder.style.display = 'none';
        toggleTrailerOrder(false);
    } else {
        unitConfigModalLabel.textContent = `Configure Trailer`
        //trailerOrderLabel.style.display = 'block';
        //trailerOrder.style.display = 'block';
        toggleTrailerOrder(true);
        sinkIdLabel.style.display = 'none';        
        sinkId.style.display = 'none';
    }

    const unitConfigModal = new bootstrap.Modal(document.getElementById('unitconfigmodal'), {});
    unitConfigModal.show();

    //let unitData = { name: '', numberOfSpares: '', repeaters: [], axles: [] };

    // Move to Add Repeaters
    const addRepeatersButton = document.getElementById('addrepeatersbtn');
    addRepeatersButton.removeEventListener('click', handleAddRepeatersClick);
    addRepeatersButton.addEventListener('click', handleAddRepeatersClick); 

    // Move to Add Axles
    const addAxlesButton = document.getElementById('addaxlesbtn');
    addAxlesButton.removeEventListener('click', handleAddAxlesClick);
    addAxlesButton.addEventListener('click', handleAddAxlesClick);    

    document.getElementById('unitconfigform').addEventListener('submit', function (event) {
        event.preventDefault(); // Prevent form submission reload

        // Use the Bootstrap 4 method to hide the modal -  a lot easier in Bootstrap 5
        const unitConfigModal = document.getElementById('unitconfigmodal');
        unitConfigModal.classList.remove('show');
        unitConfigModal.setAttribute('aria-hidden', 'true');
        unitConfigModal.style.display = 'none';

        // Remove the modal's backdrop as well
        document.body.classList.remove('modal-open');
        document.querySelector('.modal-backdrop').remove();

    });
}

const toggleTrailerOrder = (shouldShow) => {
    const trailerOrderLabel = document.getElementById('trailerorderlabel');
    const trailerOrderInput = document.getElementById('trailerorder');

    if (shouldShow) {
        trailerOrderLabel.style.display = 'block';
        trailerOrderInput.style.display = 'block'; // Show the input
        trailerOrderInput.setAttribute('required', 'required'); // Make it required
    } else {
        trailerOrderLabel.style.display = 'none';
        trailerOrderInput.style.display = 'none'; // Hide the input
        trailerOrderInput.removeAttribute('required'); // Remove the required attribute
    }
}

const submitConfigForm = () => {

    // Close the modal
    const closeConfigModal = document.getElementById('unitconfigmodal');
    const unitConfigModal = new bootstrap.Modal(closeConfigModal);
    unitConfigModal.hide();
    closeConfigModal.style.display = 'none';
}

function handleAddRepeatersClick() {
    let unitData = { name: '', axles: [] };
    const unitNameInput = document.getElementById('unitname');
    const unitName = unitNameInput.value.trim();
    if (unitName) {
        unitData.name = unitName;
        const addRepeatersButton = document.getElementById('addrepeatersbtn');
        addRepeatersButton.style.display = 'none';
        addRepeatersStep();
    } else {
        alert('Please enter a name for the unit.');
    }
}

function handleAddAxlesClick() {
    let unitData = { name: '', axles: [] };
    const unitNameInput = document.getElementById('unitname');
    const unitName = unitNameInput.value.trim();
    if (unitName) {
        
        unitData.name = unitName;
        const addAxlesButton = document.getElementById('addaxlesbtn');
        addAxlesButton.style.display = 'none';
        addAxlesStep();
    } else {
        alert('Please enter a name for the unit.');
    }
}

function addRepeatersStep() {
    document.getElementById('addrepeatersstep').classList.remove('d-none');
    addRepeaterRow();
}

function addAxlesStep() {
    document.getElementById('addaxlesstep').classList.remove('d-none');
    addAxleRow();
}

const resetUnitConfigForm = () => {
    axleCounter = 0;
        // Reset input fields
        document.getElementById('unitname').value = '';
    document.getElementById('numberofsparewheels').value = '';

        // Clear the dynamically generated axles
    document.getElementById('repeaterscontainer').innerHTML = '';
    const addRepeatersButton = document.getElementById('addrepeatersbtn');
    addRepeatersButton.style.display = 'block';
    document.getElementById('axlescontainer').innerHTML = '';
    const addAxlesButton = document.getElementById('addaxlesbtn');
    addAxlesButton.style.display = 'block';
    // Reset any step visibility if you have steps
    //document.getElementById('unitcommoninfo').classList.remove('d-none');
    document.getElementById('addrepeatersstep').classList.add('d-none');
    document.getElementById('addaxlesstep').classList.add('d-none');

    const repeatersContainer = document.getElementById('repeaterscontainer');    
    if (repeatersContainer && repeatersContainer.hasChildNodes()) {
        while (repeatersContainer.hasChildNodes()) {
            repeatersContainer.removeChild(repeatersContainer.lastChild);
        }
    } 
    const axlesContainer = document.getElementById('axlescontainer');
    if (axlesContainer && axlesContainer.hasChildNodes()) {
        while (axlesContainer.hasChildNodes()) {
            axlesContainer.removeChild(axlesContainer.lastChild);
        }
    }    
}

function addRepeaterRow() {

    // Increment axle counter for the next row
    repeaterCounter++;
    const repeaterRow = document.createElement('div');
    repeaterRow.classList.add('row', 'repeater-row', 'mt-2');
    repeaterRow.id = `repeater-row-${repeaterCounter}`; // Assign a unique ID to the repeater row

    // Repeater Input
    const repeaterDiv = document.createElement('div');
    repeaterDiv.classList.add('col-md-3'); 
    const repeaterInput = document.createElement('input');
    repeaterInput.type = 'text';
    repeaterInput.classList.add('form-control'); 
    repeaterInput.placeholder = 'Add extra repeater';
    repeaterDiv.appendChild(repeaterInput);

    const buttonDiv = document.createElement('div');
    buttonDiv.classList.add('col-md-1', 'd-flex', 'justify-content-center', 'align-items-center'); //,  'align-items-center'
    
    const addButton = document.createElement('button');
    addButton.type = 'button';
    addButton.classList.add('btn', 'btn-success', 'btn-block', 'w-100', 'add-repeater');
    addButton.id = `add-repeater-btn-${repeaterCounter}`; // Unique ID for the add button
    addButton.textContent = 'Add';
    buttonDiv.appendChild(addButton);

    // Repeater Input
    const placeHolderDiv = document.createElement('div');
    placeHolderDiv.classList.add('col-md-8');

    // Append all elements to the axleRow
    repeaterRow.append(repeaterDiv, buttonDiv, placeHolderDiv);

    // Append axleRow to the container
    document.getElementById('repeaterscontainer').appendChild(repeaterRow);

    addButton.addEventListener('click', handleAddRepeaterClick);
}

function handleAddRepeaterClick() {
    console.log('Add Button clicked');
    // Change the previous row's add button to a remove button
    const lastRow = document.querySelector(`#repeater-row-${repeaterCounter}`); // The row before this one
    if (lastRow) {

        const lastButton = lastRow.querySelector('.add-repeater');
        if (lastButton) {
            // Convert to "Remove" button
            lastButton.classList.remove('btn-success', 'add-repeater');
            lastButton.classList.add('btn-danger', 'remove-repeater');
            lastButton.textContent = 'Remove';

            lastButton.removeEventListener('click', handleAddRepeaterClick);
            lastButton.addEventListener('click', () => removeRepeaterRow(lastButton));
        }
    }

    // Add the new repeater row
    addRepeaterRow();
}

function removeRepeaterRow(buttonElement) {
    const rowId = buttonElement.id.split('-').pop();
    const rowToRemove = document.getElementById('repeater-row-' + rowId);
    if (rowToRemove) {
        rowToRemove.parentNode.removeChild(rowToRemove);
    } else {
        console.error("Row to remove not found");
    }
}

function addAxleRow() {

    // Increment axle counter for the next row
    axleCounter++;
    const axleRow = document.createElement('div');
    axleRow.classList.add('row', 'axle-row', 'mt-2');
    axleRow.id = `axle-row-${axleCounter}`; // Assign a unique ID to the axle row

    // First column: Left Repeater Input (larger column)
    const leftLocationSensorDiv = document.createElement('div');
    leftLocationSensorDiv.classList.add('col-md-3');
    const leftLocationSensorInput = document.createElement('input');
    leftLocationSensorInput.type = 'text';
    leftLocationSensorInput.classList.add('form-control', 'left-repeater');
    leftLocationSensorInput.placeholder = `Axle ${axleCounter} - Left Location Id`;
    leftLocationSensorDiv.appendChild(leftLocationSensorInput);

    // Second column: Left Wheel Type Select (smaller column)
        const leftWheelDiv = document.createElement('div');
        leftWheelDiv.classList.add('col-md-2');
        const leftWheelSelect = document.createElement('select');
        leftWheelSelect.classList.add('form-control', 'left-wheeltype');
        const leftSingleOption = document.createElement('option');
        leftSingleOption.value = 'single';
        leftSingleOption.textContent = 'Single';
        const leftDoubleOption = document.createElement('option');
        leftDoubleOption.value = 'double';
        leftDoubleOption.textContent = 'Double';
        leftWheelSelect.append(leftSingleOption, leftDoubleOption);
        leftWheelDiv.appendChild(leftWheelSelect);

    // Third column: Axle SVG Placeholder (takes remaining space)
    const axleSvgDiv = document.createElement('div');
    axleSvgDiv.classList.add('col-md-auto');
    axleSvgDiv.textContent = ' ';

    // Fourth column: Right Repeater Input (larger column)
    const rightLocationSensorDiv = document.createElement('div');
    rightLocationSensorDiv.classList.add('col-md-3');
    const rightLocationSensorInput = document.createElement('input');
    rightLocationSensorInput.type = 'text';
    rightLocationSensorInput.classList.add('form-control', 'right-repeater');
    rightLocationSensorInput.placeholder = `Axle ${axleCounter} - Right Location Id`;
    rightLocationSensorDiv.appendChild(rightLocationSensorInput);

    // Fifth column: Right Wheel Type Select (smaller column)
        const rightWheelDiv = document.createElement('div');
        rightWheelDiv.classList.add('col-md-2');
        const rightWheelSelect = document.createElement('select');
        rightWheelSelect.classList.add('form-control', 'right-wheeltype');
        const rightSingleOption = document.createElement('option');
        rightSingleOption.value = 'single';
        rightSingleOption.textContent = 'Single';
        const rightDoubleOption = document.createElement('option');
        rightDoubleOption.value = 'double';
        rightDoubleOption.textContent = 'Double';
        rightWheelSelect.append(rightSingleOption, rightDoubleOption);
        rightWheelDiv.appendChild(rightWheelSelect);

    // Sixth column: Button container for add/remove buttons
    const buttonDiv = document.createElement('div');
    buttonDiv.classList.add('col-md-1');  //, 'd-flex', 'justify-content-center', 'align-items-center'
    

    // Add the "Add Axle" button to the new row
    const addButton = document.createElement('button');
    addButton.type = 'button';
    addButton.classList.add('btn', 'btn-success', 'btn-block', 'w-100', 'add-axle');
    addButton.id = `add-axle-btn-${axleCounter}`; // Unique ID for the add button
    addButton.textContent = 'Add';
    buttonDiv.appendChild(addButton);

    // Append all elements to the axleRow
    axleRow.append(leftLocationSensorDiv, leftWheelDiv, axleSvgDiv, rightLocationSensorDiv, rightWheelDiv, buttonDiv);

    // Append axleRow to the container
    document.getElementById('axlescontainer').appendChild(axleRow);
    
    addButton.addEventListener('click', handleAddAxleRowClick);
}

function handleAddAxleRowClick() {
    console.log('Add Button clicked');
    // Change the previous row's add button to a remove button
    const lastRow = document.querySelector(`#axle-row-${axleCounter}`); // The row before this one
    if (lastRow) {
        
        const lastButton = lastRow.querySelector('.add-axle');
        if (lastButton) {
            // Convert to "Remove" button
            lastButton.classList.remove('btn-success', 'add-axle');
            lastButton.classList.add('btn-danger', 'remove-axle');
            lastButton.textContent = 'Remove';
            lastButton.removeEventListener('click', handleAddAxleRowClick);
            lastButton.addEventListener('click', () => removeAxleRow(lastButton));
        }
    }

    // Add the new axle row
    addAxleRow();
}

function removeAxleRow(buttonElement) {
    const rowId = buttonElement.id.split('-').pop();
    const rowToRemove = document.getElementById('axle-row-' + rowId);
    if (rowToRemove) {
        rowToRemove.parentNode.removeChild(rowToRemove); 
    } else {
        console.error("Row to remove not found");
    }
}

function displayUnitInfo(selectedUnit) {

    let unitInfo = document.getElementById(`unitinfo${selectedUnit.imei}`);
    if (unitInfo) {
        unitInfo.parentNode.removeChild(unitInfo);
        //while (unitInfo.hasChildNodes()) {
        //    unitInfo.removeChild(unitInfo.lastChild);
        //}
    }
    unitInfo = document.createElement('div');
    unitInfo.id = `unitinfo${selectedUnit.imei}`;
    unitInfo.className = 'unitinfopane';
    unitInfo.canClose = true;
    unitInfo.onclick = function (e) {
        unitInfo.canClose = false;
        e.stopImmediatePropagation();
    }
    
    const unitInfoFragment = document.createDocumentFragment();

    const unitInfoDiv = createUnitInfoElement(selectedUnit);
    const unitDriverInfoDiv = createUnitDriverInfoElement(selectedUnit);
    const unitMapDiv = createUnitMapElement(); 

    unitInfoFragment.appendChild(unitInfoDiv);    
    unitInfoFragment.appendChild(unitDriverInfoDiv);  
    unitInfoFragment.appendChild(unitMapDiv);

    unitInfo.appendChild(unitInfoFragment);

    let displayUnit = document.getElementById(selectedUnit.imei);
    displayUnit.appendChild(unitInfo);

    initialiseMap('unitmap');
    const location = {
        longitude: formatCoordinate(selectedUnit.location.longitude),
        latitude: formatCoordinate(selectedUnit.location.latitude)
    };
    showUnitOnMap(location);

    displayUnit.scrollIntoView();
}

//function initialiseMap(mapDiv) {

//    if (!map) {
//        // create a map in the "map" div, set the view to a given place and zoom
//        map = L.map(mapDiv, {
//            fullscreenControl: true,
//            fullscreenControlOptions: {
//                position: 'topleft'
//            }
//        }).setView([-33.97823, 22.45808], 10);

//        // add an OpenStreetMap tile layer
//        //L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
//        //    attribution: '&copy; <a href="http://gurtam.com">Gurtam</a>'
//        //}).addTo(map);

//        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
//            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//        }).addTo(map);
//    }
//}

function initialiseMap(mapDiv) {

    if (map) {
        map.remove(); // This removes the existing map and cleans up event listeners
        map = null; // Reset the map variable so it can be re-initialized
    }

    // create a new map instance
    map = L.map(mapDiv, {
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: 'topleft'
        }
    }).setView([-33.97823, 22.45808], 10);

    // add an OpenStreetMap tile layer
    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    setTimeout(() => {
        map.invalidateSize();
    }, 100);
}

function showUnitOnMap(pos) { 

    if (map) {
        const customIcon = L.divIcon({
            className: 'map-icon'
        });
        if (marker != null) {
            map.removeLayer(marker);
        }
        marker = L.marker({ lat: pos.latitude, lng: pos.longitude }, { icon: customIcon }).addTo(map);
        marker.setLatLng({ lat: pos.latitude, lng: pos.longitude });
        marker.setIcon(customIcon);
        map.invalidateSize();
        map.setView({ lat: pos.latitude, lng: pos.longitude });
        map.invalidateSize();
    }
}

function createAssignDriverButtonElement(unit) {

    let assignDriverButton = document.createElement('button');
    assignDriverButton.id = 'assigndriverbutton';
    assignDriverButton.type = 'button';
    let assignDriverButtonClasses = ['btn', 'btn-primary', 'btn-block'];
    assignDriverButton.classList.add(...assignDriverButtonClasses);
    if (!unit.driverName) assignDriverButton.innerText = "New Driver"; //"Assign Driver"
    else assignDriverButton.innerText = "Edit Driver";
    assignDriverButton.onclick = function (e) {
        e.stopImmediatePropagation();
        openEditDriverForm(unit);
        //let modal = document.getElementById("assigndrivermodal");
        //modal.style.display = "block";
    };

    return assignDriverButton;
}

//function createUnassignDriverButtonElement(unit) {

//    const unassignDriverButton = document.createElement('button');
//    unassignDriverButton.id = 'unassigndriverbutton';
//    unassignDriverButton.type = 'button';
//    const unassignDriverButtonClasses = ['btn', 'btn-primary', 'btn-block'];
//    unassignDriverButton.classList.add(...unassignDriverButtonClasses);
//    unassignDriverButton.innerText = "Unassign Driver";

//    unassignDriverButton.onclick = async function (e) {
//        e.stopImmediatePropagation();
//        const assignedDriver = await unassignDriverFromUnit(driver);
//        if (!assignedDriver) {
//            driver.unitDriverAssignedTo = 0;

//            const unitDriverInfoContainerDiv = document.getElementById('driverinfocontainer');
//            while (unitDriverInfoContainerDiv.hasChildNodes()) {
//                unitDriverInfoContainerDiv.removeChild(unitDriverInfoContainerDiv.lastChild);
//            }
//            const assignDriverButtonElement = createAssignDriverButtonElement();
//            unitDriverInfoContainerDiv.appendChild(assignDriverButtonElement);
                        
//            getAllDriversNotAssigned(unitDrivers, imei);
//        }
//    };

//    return unassignDriverButton;
//}

function createUnitInfoElement(selectedUnit) {

    let unitInfoDiv = document.createElement('div');
    unitInfoDiv.id = `unitinfo${selectedUnit.imei}`;
    unitInfoDiv.className = 'paneframe';

    let unitInfoContainerDiv = createUnitInfoContainerElements(selectedUnit);
    unitInfoDiv.appendChild(unitInfoContainerDiv);

    return unitInfoDiv;
}

function createUnitInfoContainerElements(selectedUnit) {
    let unitInfoContainerDiv = document.createElement('div');
    unitInfoContainerDiv.className = 'unitpanecontainer';

    let unitInfoHeaderDiv = createUnitInfoHeaderDiv(selectedUnit);
    unitInfoContainerDiv.appendChild(unitInfoHeaderDiv);

    const unitInfoDiv = createUnitInfoDiv(selectedUnit);
    unitInfoContainerDiv.appendChild(unitInfoDiv);

    // Start fetching the address asynchronously without blocking UI rendering
    updateAddressAsync(selectedUnit, unitInfoDiv, selectedUnit.location.latitude, selectedUnit.location.longitude);

    return unitInfoContainerDiv;
}

function createUnitInfoHeaderDiv(selectedUnit) {
    let unitInfoHeaderDiv = document.createElement('div');
    //let unitInfoHeaderDivClasses = ['unitinfoheader', 'editunitinfo'];
    const unitInfoHeaderDivClasses = ['unitinfoheader'];
    unitInfoHeaderDiv.classList.add(...unitInfoHeaderDivClasses);

    unitInfoHeaderDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        openEditUnitForm(selectedUnit);
    };

    let unitIcon = document.createElement('svg');
    unitIcon.className = 'trucktrailer-icon';

    let unitName = document.createElement('h6');
    unitName.innerText = selectedUnit.name;

    unitInfoHeaderDiv.appendChild(unitIcon);
    unitInfoHeaderDiv.appendChild(unitName);

    return unitInfoHeaderDiv;
}

function createUnitInfoDiv(selectedUnit) {
    const unitInfoDiv = document.createElement('div');
    unitInfoDiv.className = 'unitinfo';

    // Speed Info
    const unitSpeedDiv = document.createElement('div');
    unitSpeedDiv.id = `unitinfospeed${selectedUnit.imei}`;
    const unitSpeedLabel = document.createElement('b');
    unitSpeedLabel.innerText = "Speed: ";
    const unitSpeed = document.createTextNode(`${selectedUnit.location.speed} km/h`);
    unitSpeedDiv.appendChild(unitSpeedLabel);
    unitSpeedDiv.appendChild(unitSpeed);
    unitInfoDiv.appendChild(unitSpeedDiv);

    // Location Info (with placeholder)
    const unitLocationDiv = document.createElement('div');
    unitLocationDiv.id = `unitinfolocation${selectedUnit.imei}`;
    const unitLocationLabel = document.createElement('b');
    unitLocationLabel.innerText = "Location: ";
    const unitLocationText = document.createTextNode("Loading location...");
    unitLocationDiv.appendChild(unitLocationLabel);
    unitLocationDiv.appendChild(unitLocationText);
    unitInfoDiv.appendChild(unitLocationDiv);

    // Last Message Info
    const unitLastMessageDiv = document.createElement('div');
    unitLastMessageDiv.id = `unitinfotimeupdated${selectedUnit.imei}`;
    const unitLastMessageLabel = document.createElement('b');
    unitLastMessageLabel.innerText = "Time Updated: ";
    const unitLastMessageText = document.createTextNode(formatZuluTime(selectedUnit.location.recordTimestamp));
    unitLastMessageText.id = 'unitinfodate';
    unitLastMessageDiv.appendChild(unitLastMessageLabel);
    unitLastMessageDiv.appendChild(unitLastMessageText);
    unitInfoDiv.appendChild(unitLastMessageDiv);

    return unitInfoDiv;
}

async function updateAddressAsync(selectedUnit, unitInfoDiv, latitude, longitude) {
    const unitLocationDiv = unitInfoDiv.querySelector(`#unitinfolocation${selectedUnit.imei}`);
    try {
        const address = await getAddressFromCoordinates(latitude, longitude);
        unitLocationDiv.childNodes[1].nodeValue = address;
    } catch (error) {
        unitLocationDiv.childNodes[1].nodeValue = "Address not available";
        console.error('Error updating address:', error);
    }
}



function createUnitDriverInfoElement(unit) {
    
    const unitDriverInfoDiv = document.createElement('div');
    unitDriverInfoDiv.id = 'driverinfo';
    unitDriverInfoDiv.className = 'paneframe';

    const unitDriverInfoContainerDiv = createUnitDriverInfoContainerElements(unit);
    unitDriverInfoDiv.appendChild(unitDriverInfoContainerDiv);

    return unitDriverInfoDiv;
}

function createUnitDriverInfoContainerElements(unit) {

    let unitDriverInfoContainerDiv = document.createElement('div');
    unitDriverInfoContainerDiv.id = 'driverinfocontainer';
    unitDriverInfoContainerDiv.className = 'panecontainer';
    unitDriverInfoContainerDiv.onclick = function (e) {
        e.stopImmediatePropagation();
    };

    if (unit.driverName == null) {

        const assignDriverButtonElement = createAssignDriverButtonElement(unit);
        unitDriverInfoContainerDiv.appendChild(assignDriverButtonElement);

    } else {
        
        let driverInfoDiv = document.createElement('div');
        //driverInfoDiv.className = 'editdriverinfo';
        //driverInfoDiv.onclick = function () {
        //    openEditDriverForm(unit);
        //}

        const driverNameHeader = document.createElement('div');
        driverNameHeader.id = `drivername${unit.imei}`;
        driverNameHeader.className = 'drivername';
        driverNameHeader.innerText = unit.driverName || "";

        const driverMobileNoDiv = document.createElement('div');
        driverMobileNoDiv.id = `drivercontactno${unit.imei}`;
        driverMobileNoDiv.className = 'drivermobile';
        driverMobileNoDiv.innerText = unit.driverContactNumber || "";

        driverInfoDiv.appendChild(driverNameHeader);
        driverInfoDiv.appendChild(driverMobileNoDiv);
        unitDriverInfoContainerDiv.appendChild(driverInfoDiv);

        const assignDriverButtonElement = createAssignDriverButtonElement(unit);
        unitDriverInfoContainerDiv.appendChild(assignDriverButtonElement);
    }

    return unitDriverInfoContainerDiv;
}

function createUnitMapElement(unit) {

    let unitInfoMapDiv = document.createElement('div');
    unitInfoMapDiv.id = 'unitinfomap';
    unitInfoMapDiv.className = 'paneframe';

    let unitMapContainerDiv = createUnitMapContainerElement('unitmapcontainer', 'unitmap', 'unitmap', unit);
    unitInfoMapDiv.appendChild(unitMapContainerDiv);

    return unitInfoMapDiv;
}

function createUnitMapContainerElement(mapcontainer, mapDiv, mapDivClass) {

    let unitMapContainerDiv = document.createElement('div');
    unitMapContainerDiv.id = mapcontainer;
    unitMapContainerDiv.className = 'panecontainer';
    unitMapContainerDiv.onclick = function (e) {
        e.stopImmediatePropagation();
    };

    let unitMapDiv = document.createElement('div');
    unitMapDiv.id = mapDiv;
    unitMapDiv.className = mapDivClass;

    unitMapContainerDiv.appendChild(unitMapDiv);

    return unitMapContainerDiv;
}

async function reorderTrailerLinks(imei, trailerLinkOrder) {

    let resource = global.resource;

    let reorderedTrailers = false;
    let allTrailers = resource.getTrailers();
    for (let trl in allTrailers) {
        let trailer = allTrailers[trl];
        if (imei === trailer.bu) {
            if (parseInt(trailer.c) > trailerLinkOrder) {
                reorderedTrailers = false;
                trailer.c = parseInt(trailer.c) - +1;
                reorderedTrailers = await updateTrailer(trailer);
            } else {
                reorderedTrailers = true;
            }
        }
    }

    return reorderedTrailers;
}

async function updateTrailer(trailer) {

    return await new Promise((resolve, reject) => {
        global.resource.updateTrailer(trailer,
            (error, data) => {
                if (error) {
                    console.log("pressuretrackJS: updateTrailer - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                    reject({ type: 'API_ERROR', error: error });
                    return false;
                } else {
                    console.log("pressuretrackJS: updateTrailer - SUCCESS (" + data.n + " trailer updated)");
                    resolve(true);
                }
            }
        );
    });
}

function openNewDriverForm(unit) {

    document.body.style.cursor = 'wait';
    $('#container').overlayMask();

    let newdrivermodal = document.getElementById('newdrivermodal');

    let span = document.getElementById("closenewdrivermodal");
    span.onclick = function () {
        newdrivermodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == newdrivermodal) {
            newdrivermodal.style.display = "none";
        }
    };

    $('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    let newdriverform = document.getElementById('newdriverform');
    newdriverform.onsubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        submitNewDriverForm();
    }

    document.getElementById("newdriverform").reset();
    document.getElementById('imei').value = unit.imei;
    document.getElementById("newdrivermodal").style.display = "block";
}

function openEditDriverForm(unit) {

    document.body.style.cursor = 'wait';

    let editdrivermodal = document.getElementById('editdrivermodal');

    let span = document.getElementById("closeeditdrivermodal");
    span.onclick = function () {
        editdrivermodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == editdrivermodal) {
            editdrivermodal.style.display = "none";
        }
    };

    let editdriverform = document.getElementById('editdriverform');
    editdriverform.onsubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        submitEditDriverForm(unit);
    }

    document.body.style.cursor = 'default';

    document.getElementById('edfDriverName').value = unit?.driverName || "";
    document.getElementById('edfDriverMobileNo').value = unit?.driverContactNumber || "";

    document.getElementById("editdrivermodal").style.display = "block";
}

function getAllDriversNotAssigned(allDrivers, unitDriverToAssign) {

    $("#driverinfocontent").empty().append(
        '<span id=closeDriversNotAssigned class="close">&times;</span>'
    );
    $('#driverinfocontent').append('<h5>Assign a driver: </h5>');
    $('#driverinfocontent').append('<ul id="driversNotAssigned"></ul >');

    if (allDrivers.length > 0) {
        for (let i = 0; i < allDrivers.length; i++) {
            let d = allDrivers[i];
            if (d.unitDriverAssignedTo === 0) {

                let li = document.createElement("li");
                li.id = d.driverId;

                //let icon = d.driverIcon; // get unit Icon url
                //let img = document.createElement('img');
                //img.src = icon;
                //li.appendChild(img);

                let text = " " + d.driverName;
                let t = document.createTextNode(text);
                li.appendChild(t);

                document.getElementById("driversNotAssigned").appendChild(li);
            }
        }
    }

    let modal = document.getElementById("assigndrivermodal");
    //let btn = document.getElementById("assigndriverbutton");
    let span = document.getElementById("closeDriversNotAssigned");

    //btn.onclick = function () {
    //    modal.style.display = "block";
    //}

    span.onclick = function () {
        modal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    };

    let list = document.getElementById("driversNotAssigned");
    list.addEventListener('click', function (ev) {
        if (ev.target.tagName === 'LI') {
            //$("#driverinfocontent").click(linkTrailerToUnit(parseInt(ev.target.id), unitDriverToAssign, resourceId));
            $("#driverinfocontent").click(assignDriverToUnit(parseInt(ev.target.id), unitDriverToAssign, allDrivers[0].resource));
            document.getElementById("loadingBoxText").innerHTML = "Assigning driver to unit... ";
            $("#loadingBox").modal({
                backdrop: "static", //remove ability to close modal with click
                keyboard: false, //remove option to close with keyboard
                show: true //Display loader!
            });
            modal.style.display = "none";
        }
    }, false);

    $('#driverinfocontent').append('<div class="panebutton"><button id="newDriverButton" class="btn btn-primary btn-block" type="button">New Driver</button></div>');
    let newbtn = document.getElementById("newDriverButton");
    newbtn.onclick = function () {
        modal.style.display = "none";
        openNewDriverForm(unitDriverToAssign);
    };
}

async function submitNewDriverForm() {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();
    document.getElementById("loadingBoxText").innerHTML = "Updating driver data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    let driver = {};
    driver.imeiAssignedTo = document.getElementById('imei').value;
    driver.driverName = document.getElementById('driverName').value;
    driver.driverContactNumber = document.getElementById('driverMobileNo').value;
    //driver.icon = document.getElementById('driverIcon').get(0);
    //driver.icon = icon;
    const updatedDriver = updateDriver(driver);

    document.getElementById("newdrivermodal").style.display = "none";

    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");

    return false;
}

async function submitEditDriverForm(unit) {

    document.getElementById("loadingBoxText").innerHTML = "Updating driver data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    unit.driverName = document.getElementById('edfDriverName').value;
    unit.driverContactNumber = document.getElementById('edfDriverMobileNo').value;

    const updatedUnitDriver = await updateDriver(unit);
    if (updatedUnitDriver) {
        document.getElementById(`drivername${unit.imei}`).innerText = unit.driverName;
        document.getElementById(`drivercontactno${unit.imei}`).innerText = unit.driverContactNumber;
    }

    document.getElementById("editdrivermodal").style.display = "none";
    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");

    return false;
}
function submitEditUnitForm() {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();

    document.getElementById("loadingBoxText").innerHTML = "Updating unit data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    let unit = {};
    unit.imei = document.getElementById('eufUnitId').value;
    unit.name = document.getElementById('eufUnitName').value;

    document.getElementById("editunitmodal").style.display = "none";

    $('#container').overlayMask('hide');
    $("#loadingBox").modal("hide");
    document.body.style.cursor = 'default';

    return false;
}

async function editUnit(unit) {

    let updatedUnitName = await updateUnitName(unit);
    let updatedTrailerName = false;
    if (unit.trailerId > 0) {
        updatedTrailerName = await updateTrailerName(unit);
    } else {
        updatedTrailerName = true;
    }

    if (updatedUnitName && updatedTrailerName)
        updateUnitNameChangeNotification(unit);
    else
        console.log("pressuretrackJS: editUnit - UNIT NAME NOT UPDATED");
}


function openEditUnitForm(selectedUnit) {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();

    const editunitmodal = document.getElementById('editunitmodal');
    document.getElementById('editunitheader').innerText = "EDIT UNIT";
    document.getElementById('editunitlabel').innerText = "Unit Name";

    const closeEditUnit = document.getElementById("closeeditunitmodal");
    closeEditUnit.onclick = function () {
        editunitmodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == editunitmodal) {
            editunitmodal.style.display = "none";
        }
    };

    let editunitform = document.getElementById('editunitform');
    editunitform.addEventListener('submit', async function (e) {
        e.preventDefault();
        e.stopPropagation();
        submitEditUnitForm();
    });

    $('#container').overlayMask('hide');
    $("#loadingBox").modal("hide");
    document.body.style.cursor = 'default';

    document.getElementById('eufUnitId').value = selectedUnit.imei;
    document.getElementById('eufUnitName').value = selectedUnit.name;
    if (selectedUnit.isTrailer)
        document.getElementById('eufTrailerId ').value = selectedUnit.trailerId;
    else
        document.getElementById('eufTrailerId').value = 0;

    document.getElementById("editunitmodal").style.display = "block";
}



export { displayUnitInfo, createUnitMapContainerElement, initialiseMap, showUnitOnMap };
                                                                              